import { valueMapping } from './constants';
import { assertUnionType } from 'graphql';
import { isJobTrackEnable } from '/lib/helpers';

import nookies from 'nookies';
import qs from 'qs';
import { getFilterLanguage } from '/imports/job-tracking/api/helper.js';
import env from '/env';

//boards api start here
export const getBoards = async (regionCode, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board?regionCode=${regionCode}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const changeColumn = async (obj, boardId, columnId, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}/${columnId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateBoard = async (boardName, boardId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const createNewBoard = async (boardName, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteBoard = async (id, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

//jobs api start here
export const addJobTrackingData = async (fields, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  [('createdAt', '_id', 'tag')].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteJobTrackingData = async (id, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${id}?type=jobId`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateJobTrackPosition = async (token, jobId, boardId, position, controller = {}) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ position }),
    ...controller,
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJobTrackingData = async (updatedFields, jobId, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  const fields = { ...updatedFields };
  [
    'updatedAt',
    'createdAt',
    'userId',
    'jobId',
    'notes',
    'boardId',
    'comments',
    'tasks',
    'timelines',
    'contacts',
    'zipCode',
    'contractCategories',
    'createdBy',
    'hasPendingTask',
  ].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getJobAdditionalInfo = async (jobId, boardId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// search and feeds elastic search
export const esQuery = async (query, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  };
  const response = await fetch(`${env.BASE_API}/es/query`, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

function parseSearchJobResponse(res, jobProvider, feedName = '') {
  let jobs = Array.isArray(res.jobs) ? res.jobs : res;
  if (jobs?.companies) {
    jobs = jobs.companies?.reduce((company, current) => {
      let currentJobs = current.jobs?.map((job) => {
        return {
          ...job,
          company: current?.name,
        };
      });
      return company.concat(currentJobs);
    }, []);
  }
  if (!Array.isArray(jobs)) return [];
  const baseUrl = 'https://fr.indeed.com/';
  return jobs.map((jobItem) => {
    // here we map the results to avoid mismatch keys on FE
    const { salary, ...job } = jobItem;
    return {
      ...job,
      id: job?.id || job?.job_id || job?.jobId,
      title: job?.title || job?.job_title || job?.jobTitle,
      location: job?.location || `${job?.job_city} ${job?.job_state} ${job?.job_country}`,
      description: job?.description || job?.job_description || job?.jobDescription,
      company: job?.company || job?.company_name || job?.employer_name,
      employmentType: job?.employmentType || job?.employement_type || job?.job_type,
      datePosted: job?.datePosted || job?.formatted_relative_time || new Date(job?.date_creation).toDateString(),
      salaryRange: job?.salaryRange || `${job?.salary?.max}-${job?.salary?.min} ${job?.salary?.type}`,
      portal:
        job?.portal ||
        (jobProvider === 'search-v3' && 'indeed') ||
        job?.apply_options?.[0]?.publisher ||
        job?.job_board,
      apiProvider: jobProvider || job?.apiProvider,
      url:
        job?.url ||
        job?.apply_options?.[0]?.apply_link ||
        job?.job_board_url ||
        (job?.jobUrl ? `${baseUrl}${job?.jobUrl}` : undefined),
      searchName: feedName || job?.searchName,
      isZenrowProvider: job?.isZenrowProvider,
      reDirectionUrl: job?.reDirectionUrl,
      externalLink: job?.externalLink,
      isEasyApply: job?.isEasyApply,
    };
  });
}

const searchJobApiVersionConfig = [
  { key: 'title', params: 'title', api: ['search-v2', 'search-v3', 'search-v4','search-v6'] },
  { key: 'location', params: 'where', api: ['search-v2', 'search-v3','search-v6'] },
  { key: 'datePosted', params: 'postedDate', api: ['search-v2', 'search-v3','search-v6'] },
  { key: 'remoteOnly', params: 'remoteOnly', api: ['search-v2','search-v6'] },
  { key: 'index', api: ['search-v2', 'search-v3'], defaultValue: 0 },
  { key: 'employmentTypes', params: 'jobType', api: ['search-v2','search-v6'] },
  { key: 'jobType', params: 'jobType', api: ['search-v3'] },
  { key: 'locality', params: 'locality', api: ['search-v3'], defaultValue: 'fr' },
  { key: 'language', params: 'language', api: ['search-v2','search-v6'] },
  { key: 'distance', params: 'distance', api: ['search-v2','search-v6'] },
  { key: 'isEasyApply', params: 'isEasyApply', api: ['search-v6'] },
  { key: 'radius', params: 'distance', api: ['search-v3', 'search-v4'] },
  { key: 'job_title', params: 'title', api: ['search-v5'] },
  { key: 'allowedJobProviders', params: 'allowedJobProviders', api: ['search-v2', 'search-v5','search-v6'] },
  { key: 'page', api: ['search-v4'], defaultValue: 1 },
  { key: 'num_pages', api: ['search-v4'], defaultValue: 10 },
  { key: 'date_posted', params: 'postedDate', api: ['search-v4'], defaultValue: 'all' },
  { key: 'markup_job_description', api: ['search-v4'], defaultValue: true },
  { key: 'employment_types', params: 'jobType', api: ['search-v4'], defaultValue: '' },
  { key: 'remote_jobs_only', params: 'remoteOnly', api: ['search-v4'] },
  { key: 'job_requirements', api: ['search-v4'] },
  { key: 'actively_hiring', api: ['search-v4'], defaultValue: true },
  { key: 'nextPage', params: 'nextPage', api: ['search-v2','search-v6'], defaultValue: null },
  { key: 'job_age_in_days', params: 'job_age_in_days', api: ['search-v5'], defaultValue: 30 },
  { key: 'job_location_ids', params: 'job_location_ids', api: ['search-v5'], defaultValue: 2988507 },
  { key: 'job_title_include_all', params: 'job_title_include_all', api: ['search-v5'], defaultValue: false },
  { key: 'job_title_excluded', params: 'job_title_excluded', api: ['search-v5'], defaultValue: '' },
  { key: 'job_description', params: 'job_description', api: ['search-v5'], defaultValue: '' },
  { key: 'job_description_include_all', params: 'job_description_include_all', api: ['search-v5'], defaultValue: true },
  { key: 'job_description_excluded', params: 'job_description_excluded', api: ['search-v5'], defaultValue: '' },
  { key: 'job_board', params: 'job_board', api: ['search-v5'], defaultValue: 'indeed' },
  // { key: 'nb_min_job_posted', params: 'nb_min_job_posted', api: ['search-v5'], defaultValue: 2 },
  // { key: 'nb_max_job_posted', params: 'nb_max_job_posted', api: ['search-v5'], defaultValue: 100 },
  { key: 'min_company_size', params: 'min_company_size', api: ['search-v5'], defaultValue: 5 },
  // { key: 'max_company_size', params: 'max_company_size', api: ['search-v5'], defaultValue: 1000 },
  { key: 'company_industry', params: 'industries', api: ['search-v5'], defaultValue: '' },
  {
    key: 'company_industry_excluded',
    params: 'company_industry_excluded',
    api: ['search-v5'],
    defaultValue: 'education / training / recruitment',
  },
  { key: 'company_funding', params: 'company_funding', api: ['search-v5'], defaultValue: false },
  { key: 'limit', params: 'limit', api: ['search-v5'], defaultValue: 10 },
  {
    key: 'fields',
    api: ['search-v4'],
    defaultValue:
      'job_city,job_country,job_state,employer_name,job_publisher,job_title,job_country,job_description,employer_website,job_posted_at_datetime_utc,job_employment_type,job_is_remote,apply_options',
  },
];

function filterParamsByApi(apiVersion, isGuest, apiProvider) {
  if (isGuest || !apiProvider.includes(apiVersion)) {
    apiVersion = 'search-v6';
  }
  return searchJobApiVersionConfig.filter((item) => item.api.includes(apiVersion));
}

export const getMantiksLocation = async (city, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      location: city,
    }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/location-id`, fetchOptions);
  if (response.status !== 200) {
    return [];
  }
  return response.json();
};

export const getIndeedJobDetails = async ({ jobId, company }, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ jobId }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/job-details`, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMantiksDBJobs = async (feedId) => {
  const { token } = nookies.get({});

  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ feedId }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/mantiks-db-jobs`, fetchOptions);

  return response;
};

export const getPostDataGenerated = async (data, isGuest, activeApiProviderEndpoint, index) => {
  return filterParamsByApi(data.apiProvider, isGuest, activeApiProviderEndpoint).reduce((acc, param) => {
    let value;
    if (param.key === 'title' && data.apiProvider === 'search-v5') {
      value = `${data.title} ${data.where}`;
    } else if (param.key === 'title' && data.apiProvider === 'search-v4') {
      value = `${data.title} ${data.where} ${getFilterLanguage(data.language) || 'EN'}`;
    } else if (param.key === 'language' && data?.language) {
      value = getFilterLanguage(data.language);
    } else if (['jobType', 'employmentTypes', 'employment_types'].includes(param.key) && data?.jobType?.length) {
      value = data.jobType[0];
    } else if (param.key === 'allowedJobProviders' && data?.allowedJobProviders?.length > 0) {
      value = data.allowedJobProviders;
    } else if (param.key === 'remoteOnly') {
      value = data?.remote ? data.remote !== 'On Site' : false;
    }else if (param.key === 'isEasyApply') {
      value = data?.isEasyApply ? data.isEasyApply !== 'No' : false;
    } else if (param.key === 'job_age_in_days') {
      switch (data.postedDate) {
        case '3days':
          value = 3;
          break;
        case 'today':
          value = 1;
          break;
        case 'week':
          value = 7;
          break;
        case 'month':
          value = 30;
          break;
      }
    } else {
      value = data?.[param.params];
    }

    if (param?.defaultValue && !value) {
      value = param.defaultValue;
    }

    if (['page', 'index', 'num_pages'].includes(param.key)) {
      value = index || param.defaultValue;
    }

    if (value !== undefined) {
      acc[param.key] = value !== undefined ? value : '';
    }
    return acc;
  }, {});
};

export const getSearchJob = async (data, token, index, feedName, retry = 1) => {
  const activeApiProviderEndpoint = ['search-v2', 'search-v3', 'search-v4', 'search-v5','search-v6'];
  const { agent_client_id } = nookies.get({});
  const isGuest = !token && isJobTrackEnable();
  const postData = await getPostDataGenerated(data, isGuest, activeApiProviderEndpoint,index);
  const guestId = localStorage.getItem('guestId');
  const endpoint = activeApiProviderEndpoint.includes(data?.apiProvider) ? data.apiProvider : 'search-v6';
  if (data?.where && endpoint === 'search-v5') {
    const locationIds = await getMantiksLocation(data?.where, token);
    if (locationIds?.results) {
      postData.job_location_ids = `${locationIds?.results?.[0]?.id}`;
    }
  }
  if (!postData.allowedJobProviders) {
    delete data.allowedJobProviders;
  }
  if (postData.allowedJobProviders && endpoint === 'search-v5') {
    postData.job_board = data.allowedJobProviders.join(',');
    delete postData.allowedJobProviders;
  }

  if (postData.nextPage === null) {
    delete postData.nextPage;
  }

  const API = isGuest ? `/job/guest-search?guestId=${guestId}` : `/job/${endpoint}?${qs.stringify(postData)}`;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (endpoint === 'search-v2') {
    // if legacy api endpoint we send post body
    fetchOptions.body = JSON.stringify(postData);
  }
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  let response = [];
  let isMantiksStoredInDB = true;
  let jsonResponse = [];

  if (endpoint === 'search-v6' && !data.feedId) {
    await saveFeed(data, token);
  }

  if ((endpoint === 'search-v5' || endpoint === 'search-v6') && data.feedId) {
    // if mantiks provider and feedId is passed we checked in our DB if data already stored otherwise we fecth for  normal source
    response = await getMantiksDBJobs(data.feedId);
    try {
      jsonResponse = await response.json();
    } catch (e) {}
    /*if (response.status === 404 || !jsonResponse?.jobs || jsonResponse?.jobs?.length == 0) {
      // means feedId not found is our DB so we refetch data using source api
      isMantiksStoredInDB = false;
      response = await fetch(`${env.JOB_TRACK_API}${API}`, fetchOptions);
      if (response.status !== 200) {
        const text = await response.text();
        throw new Error(text);
      }
      jsonResponse = await response.json();
    }*/
  } else {
    response = await fetch(`${env.JOB_TRACK_API}${API}`, fetchOptions);
    if (response.status !== 200) {
      const text = await response.text();
      throw new Error(text);
    }
    jsonResponse = await response.json();
  }

  // sometimes the rapid api behave return empty so we need to retry the request recursively
  if (jsonResponse?.jobs?.length === 0 && endpoint === 'search-v6' && retry < 3) {
    retry = retry + 1;
    jsonResponse = await getSearchJob(data, token, index, feedName, retry);
  }

  return {
    jobs: parseSearchJobResponse(jsonResponse, endpoint || 'search-v2', feedName),
    nextPage: jsonResponse?.nextPage,
    isMantiksStoredInDB: isMantiksStoredInDB,
    currentFeedId: data.feedId,
  };
};

export const listAgentUser = async (queryData) => {
  const queryParams = qs.stringify(queryData);
  let url = `${env.JOB_TRACK_API}/agent-user/list?${queryParams}`;
  const res = await fetch(url);
  return res.json();
};

export const getSearchData = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/filters`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// task api start here
export const getTasks = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getTaskByCursor = async (taskId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (taskId === 0) URL = `${env.JOB_TRACK_API}/task/?limit=5`;
  else URL = `${env.JOB_TRACK_API}/task/?limit=5&cursor=${taskId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const taskOperations = async (data, id, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tasks: data }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/tasks/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const multipleDeleteTask = async (taskIds, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(taskIds),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};
// contacts api start here
export const getContacts = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const addContact = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteContact = async (contactId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${contactId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateContact = async (data, id, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// comments api

export const addComment = async (comment, jobId, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referenceId: jobId,
      type: 'JOB',
      text: comment,
    }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getComments = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/comment/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/comment/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateComment = async (comment, commentId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: comment }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteComment = async (commentId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// dashboard api

export const getDashboardTimeline = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/timeline/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/timeline/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// jobtrack active api

export const activeJobTrack = async (token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/podio/activate-job-tracking`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// Calendar Api

export const getTaskByRange = async (fromDate, toDate, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/calendar?fromDate=${fromDate}&toDate=${toDate}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.json();
    throw new Error(text);
  }
  return response.json();
};

// Meeting Api

export const createMeeting = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetings = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetingById = async (meetingId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateMeeting = async (data, meetingId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteMeeting = async (meetingId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// Reminder api

export const createReminder = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/reminder`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const searchAutoComplete = async (title, type, languageCode, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return [];
};

export const cityDefaultSuggestion = async (lat, lon, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  // return [];
  const response = await fetch(`${env.JOB_TRACK_API}/job/default-suggestion?lat=${lat}&lon=${lon}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getBoardList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getActiveBoard = async (boardId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const userActivity = async (token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/user/track-activity`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getOnecenterTitleList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/title-list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJob = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/update`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
// feeds api start here
export const getFeeds = async (token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedsById = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const saveMantiksFeed = async (data) => {
  const { agent_client_id, token } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/mantiks-db`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const saveFeed = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteFeed = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updatFeedApi = async (data, id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const resetNewJobCount = async (feedId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${feedId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedList = async (country, token) => {
  const { agent_client_id } = nookies.get({});
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed?regionCode=${country}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const extractJobID = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get('jk');
};

export const savedJobsOnPodio = async (data, token) => {
  const sanitizeData = (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === undefined) {
        data[key] = 'N/A';
      }
    });
    return data;
  };
  data = sanitizeData(data);
  data.jobId = extractJobID(data.jobLink);
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const listPodioSavedJobs = async (token, jobSearchName) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(
    `${env.JOB_TRACK_API}/agent-user/list-job?searchName=${jobSearchName ? jobSearchName : ''}`,
    fetchOptions,
  );
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updatePodioJob = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job-status`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const defaultCandidateSuggesiton = async (token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/candidate-search`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// SA APIS

export const markResumeFinish = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/sa-mark-finished`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return true;
};

export const generateJobSearchTemplate = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/sa-questionnaire/generate-job-search-template`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const markResumeAsChecked = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/review-job-resumes`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const markResumeAsV1 = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return;
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.BASE_API}/resume/mark-resume-version`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const fetchAgentPolishResume = async () => {
  let fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/pending-resumes`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const fetchMarkAsPolished = async (builderUserId) => {
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(builderUserId),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/auto-onboarding/mark-as-polished`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
