import { ApplyinLoader } from './hook/useAiOptimisedResume';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';
import Flex from '/imports/core/ui/atoms/Flex';
import CrossIcon from '/imports/generator/ui/atoms/CrossIcon';
import MiniAddIcon from '/imports/generator/ui/assets/MiniAddIcon';
import MiniCloseIcon from '/imports/generator/ui/assets/MiniCloseIcon';
import Modal from '/imports/core/ui/atoms/Modal';
import useTracking from '/imports/core/hooks/useTracking';
import useWindowSize from '/imports/core/api/useWindowSize';
import { useGeneralDesign } from 'imports/core/api/generalContext';
import Skeleton from 'react-loading-skeleton';
import { generateAIResume } from 'imports/generator/api/api';
import { cookieParser } from 'lib/helpers';
import { Push } from 'components/Link';
import intlHook from 'imports/core/api/useIntl';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useAccount } from 'imports/core/api/accounts/accountContext';

const MissingSkillsModal = ({ open, onClose, source }) => {
  const { width } = useWindowSize();
  const { token } = cookieParser();
  const { locale } = intlHook();
  const { trackEvent } = useTracking();
  const { currentUser } = useAccount();
  const {
    optimiseResume: { createOptimisedResume, aiParseResumeLoading, bucketStore },
  } = useGeneralDesign();

  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.8)',
    },
    modalBody: {
      width: width <= 655 ? '90%' : '610px',
      minHeight: '180px',
      maxHeight: '90%',
      height: 'auto',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '12px',
      boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
      flex: 'unset',
      overflow: 'visible',
    },
  };

  const [hardSkills, setHardSkills] = useState(null);
  const [softSkills, setSoftSkills] = useState(null);
  const [jobDescription, setJobDescription] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [generating, setGenerating] = useState(false);

  const handleClose = () => {
    if (generating) return;
    onClose();
    trackEvent('ai_skill_popup_closed');
  };
  const formatSelectableSkills = (data) => {
    if (!data?.length > 0) return null;
    return data.map((skill) => ({ name: skill, selected: false })) || null;
  };

  useEffect(() => {
    setHardSkills(formatSelectableSkills(bucketStore?.skills?.hardSkills || []));
    setSoftSkills(formatSelectableSkills(bucketStore?.skills?.softSkills || []));
    setJobDescription(bucketStore?.jobDescription || null);
    setJobTitle(bucketStore?.jobTitle || null);
    setCompanyName(bucketStore?.companyName || null);
  }, [bucketStore]);

  useEffect(() => {
    if (open) {
      trackEvent('pre_ai_missing_skill_popup');
    }
  }, [open]);

  useEffect(() => {
    const areAllSkillsUnselected =
      !hardSkills?.some((item) => item.selected) && !softSkills?.some((item) => item.selected);
    setIsDisable(areAllSkillsUnselected);
  }, [hardSkills, softSkills]);

  const selectHardSkill = (index) => {
    if (generating) return;
    const arrayTemp = [...hardSkills];
    arrayTemp[index].selected = !hardSkills[index].selected;
    setHardSkills(arrayTemp);
  };
  const selectSoftSkill = (index) => {
    if (generating) return;
    const arrayTemp = [...softSkills];
    arrayTemp[index].selected = !softSkills[index].selected;
    setSoftSkills(arrayTemp);
  };
  const getSelectedItems = (list) => {
    if (!list) return [];
    return list.reduce((acc, current) => {
      if (current.selected) acc.push(current.name);
      return acc;
    }, []);
  };

  const generateAIUpgradedResume = async () => {
    if (generating) return;
    setGenerating(true);
    onClose();
    const excludedSkills = [...(hardSkills || []), ...(softSkills || [])].reduce((acc, current) => {
      if (!current.selected) acc.push(current.name);
      return acc;
    }, []);
    const data = {
      skills: {
        softSkills: getSelectedItems(softSkills),
        hardSkills: getSelectedItems(hardSkills),
      },
      excludedSkills,
    };
    try {
      await createOptimisedResume(source, data, currentUser);
      setGenerating(false);
    } catch (e) {
      setGenerating(false);
    }
  };

  return (
    <>
      <Modal
        onClose={handleClose}
        fullScreen
        styles={modalStyles}
        animation="empty"
        openStateBase
        open={open}
        timeout={0}
        disableOutsideClick={true}
      >
        <Wrapper direction="column">
          <ModalBody direction="column">
            <CrossIconWrapper onClick={handleClose}>
              <CrossIcon />
            </CrossIconWrapper>
            <Header direction="column">
              <HeadText>Select The missing Skills</HeadText>
              <HeadSubText>
                Select the skills you are confident you can add in your resume, our AI will do the rest of the job
              </HeadSubText>
            </Header>
            <ScrollWrapper direction="column">
              {hardSkills && (
                <SkillTypeSection direction="column">
                  <SkillTypeText>Hard Skill</SkillTypeText>
                  <SkillsWrapper>
                    {(aiParseResumeLoading &&
                      marvelEmitter.getActiveVariant('exp_scoring_plugin_v2') !== 'control_plugin' && (
                        <SkillSkeleton />
                      )) ||
                      hardSkills.map((skill, i) => (
                        <SkillItem key={i} onClick={() => selectHardSkill(i)} selected={skill?.selected}>
                          {skill?.name}
                          {skill?.selected ? <MiniCloseIcon /> : <MiniAddIcon />}
                        </SkillItem>
                      ))}
                  </SkillsWrapper>
                </SkillTypeSection>
              )}
              {softSkills && (
                <SkillTypeSection direction="column">
                  <SkillTypeText>Soft Skill</SkillTypeText>
                  <SkillsWrapper>
                    {(aiParseResumeLoading &&
                      marvelEmitter.getActiveVariant('exp_scoring_plugin_v2') !== 'control_plugin' && (
                        <SkillSkeleton />
                      )) ||
                      softSkills.map((skill, i) => (
                        <SkillItem key={i} onClick={() => selectSoftSkill(i)} selected={skill?.selected}>
                          {skill?.name}
                          {skill?.selected ? <MiniCloseIcon /> : <MiniAddIcon />}
                        </SkillItem>
                      ))}
                  </SkillsWrapper>
                </SkillTypeSection>
              )}
            </ScrollWrapper>
          </ModalBody>
        </Wrapper>
        <ActionWrapper fullWidth justifyContent="flex-end">
          <ButtonAction $cancel={true} onClick={handleClose}>
            Cancel
          </ButtonAction>
          <ButtonAction
            onClick={generateAIUpgradedResume}
            $isDisabled={
              isDisable ||
              (aiParseResumeLoading && marvelEmitter.getActiveVariant('exp_scoring_plugin_v2') !== 'control_plugin')
            }
          >
            {generating ? 'Generating Your resume...' : 'Generate Your Resume'}
          </ButtonAction>
        </ActionWrapper>
      </Modal>
      {generating && <ApplyinLoader />}
    </>
  );
};

export default MissingSkillsModal;

const SkillSkeleton = React.memo(() => {
  return <Skeleton count={6} style={{ width: 129, margin: '12px 12px 0px 0px', height: 22 }} inline={true} />;
});

const ScrollWrapper = styled(Flex)`
  gap: 24px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;
const CrossIconWrapper = styled(Flex)`
  position: absolute;
  top: -16px;
  right: -16px;
  flex-shrink: 0;
  cursor: pointer;
`;
const Header = styled(Flex)`
  gap: 12px;
`;
const HeadText = styled.span`
  color: #14141f;
  font-family: Gilroy SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
const HeadSubText = styled.span`
  color: #595964;
  font-family: Gilroy Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;
const SkillTypeSection = styled(Flex)`
  gap: 16px;
`;
const SkillTypeText = styled.span`
  color: #595964;
  font-family: Gilroy SemiBold;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const SkillsWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 12px;
`;
const SkillItem = styled(Flex)`
  flex-shrink: 0;
  gap: 8px;
  padding: 2px 8px;
  align-items: center;
  height: 24px;

  border-radius: 4px;
  background: #f6f6f6;
  cursor: pointer;

  color: #14141f;
  font-family: Gilroy Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${({ selected }) =>
    selected &&
    css`
      background: #edf4ff;
      color: #0062c7;
    `}
`;
const ActionWrapper = styled(Flex)`
  padding: 20px;
  border-top: 1px solid #ecf1f7;
  gap: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
`;
const ButtonAction = styled.button`
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: var(--light-values-white);
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #1688fe;
  &:hover {
    background-color: #0d68b4;
  }
  &:active {
    background-color: #0e5eab;
  }
  ${({ $cancel }) =>
    $cancel &&
    css`
      color: #4c4c55;
      font-size: 15px;
      line-height: 22px;
      background-color: white;
      &:hover {
        color: var(--dark-values-black);
        background-color: var(--grayscale-n100);
      }
      &:active {
        background-color: var(--grayscale-n200);
      }
    `}
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #4c4c55;
      pointer-events: none;
      background-color: #f6f6f6;
    `}
`;
const ModalBody = styled(Flex)`
  gap: 24px;
  position: relative;
  padding-bottom: 24px;
`;
const Wrapper = styled(Flex)`
  padding: 32px;
`;
