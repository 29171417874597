import styled from 'styled-components';

const WizardIntro = styled.div`
  padding-top: 32px;
  margin-bottom: 80px;
  ${({ theme }) =>
    theme.max('xs')`
    margin-bottom: 25px;
    padding-top: 15px;
  `}
`;

WizardIntro.displayName = 'WizardIntro';

export default WizardIntro;
