const MiniCloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99941 6.58519L5.87808 4.46387L4.46387 5.87808L6.58519 7.99941L4.46387 10.1207L5.87809 11.5349L7.99941 9.41362L10.1207 11.5349L11.5349 10.1207L9.41362 7.99941L11.5349 5.87808L10.1207 4.46387L7.99941 6.58519Z"
        fill="#0062C7"
      />
    </svg>
  );
};

export default MiniCloseIcon;
