export const getExperienceLimit = (index, experienceCount) => {
  // Predefined limits for up to 4 experiences
  const baseLimits = [
    [8], // For experienceCount: 1
    [7, 6], // For experienceCount: 2
    [6, 5, 4], // For experienceCount: 3
    [5, 4, 3, 2], // For experienceCount: 4
  ];

  // if experienceCount <= 4, use predefined limits
  if (experienceCount <= 4) {
    return baseLimits?.[experienceCount - 1]?.[index] || 2; // Fallback to 2 for out-of-range indices
  }

  // if number of experience is greater or equal to 5
  if (index === 0) return 5; //latest/most recent exp
  if (index === 1) return 4; // Next
  if (index === 2) return 3;
  if (index === 3) return 2;
  return 2; // From 5th experience onwards
};

export const getExperienceAlerts = (experience, index, experienceList) => {
  const experienceCount = experienceList?.length || 0;
  const bpCount = experience.experienceContent?.length || 0;
  const limit = getExperienceLimit(index, experienceCount);
  const minimumBP = limit > 2 ? limit - 2 : 1;

  // Determine alerts based on BP count
  if (bpCount < limit) {
    if (bpCount <= minimumBP) {
      return { alert: 'alert 2', BP_COUNT: minimumBP + 1, target: limit }; // Error => The number of BP is less or equal to MINIMUM, suggesting to add more BP, Finale Submission is blocked
    }
    return { alert: 'alert 0', BP_COUNT: limit - bpCount, target: limit }; // Warning => The number of BP is between ]MINIMUM,TARGET[, suggesting to add more BP
  } else if (bpCount === limit) {
    return null; // the TARGET number of BP is reached, all good.
  } else if (bpCount > limit && bpCount <= limit + 2) {
    return { alert: 'alert 1', BP_COUNT: bpCount - limit, target: limit }; // Warning => The number of BP  is between [TARGET + 1,TARGET + 2], suggesting to remove some BP
  } else if (bpCount > limit + 2) {
    return { alert: 'alert 3', BP_COUNT: bpCount - limit, target: limit }; // Error => The number of BP is above TARGET + 2, suggesting to remove some BP,Finale Submission is blocked
  }
};

export const getExperienceFieldsError = (experienceItem, step, checkTargetNatureQuestion) => {
  const missingFields = [];
  if (!experienceItem?.experienceTitle && step == 2) missingFields.push('experienceTitle');
  if (!experienceItem?.companyName && step == 2) missingFields.push('companyName');
  if (!experienceItem?.companyDescription && step == 4) missingFields.push('companyDescription');
  if (!experienceItem?.location && step == 2) missingFields.push('location');
  if (!experienceItem?.expStartDate && step == 2) missingFields.push('expStartDate');
  if (!experienceItem?.expEndDate && step == 2) missingFields.push('expEndDate');
  if (checkTargetNatureQuestion && experienceItem?.targetNatureQuestion === null && step == 4)
    missingFields.push('targetNatureQuestion');
  if (!experienceItem?.experienceContent || experienceItem?.experienceContent?.length === 0)
    missingFields.push('experienceContent');
  return missingFields;
};
