import React, { useState, useRef, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar-SA';
import az from 'date-fns/locale/az';
import bn from 'date-fns/locale/bn';
import ca from 'date-fns/locale/ca';
import cs from 'date-fns/locale/cs';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import ErrorMessage from './InputErrorMessage';
import es from 'date-fns/locale/es';
import fi from 'date-fns/locale/fi';
import fr from 'date-fns/locale/fr';
import he from 'date-fns/locale/he';
import hi from 'date-fns/locale/hi';
import hu from 'date-fns/locale/hu';
import id from 'date-fns/locale/id';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ka from 'date-fns/locale/ka';
import kk from 'date-fns/locale/kk';
import ko from 'date-fns/locale/ko';
import lt from 'date-fns/locale/lt';
import lv from 'date-fns/locale/lv';
import moment from 'moment';
import ms from 'date-fns/locale/ms';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import ptbr from 'date-fns/locale/pt-BR';
import ro from 'date-fns/locale/ro';
import ru from 'date-fns/locale/ru';
import sl from 'date-fns/locale/sl';
import sr from 'date-fns/locale/sr';
import sv from 'date-fns/locale/sv';
import th from 'date-fns/locale/th';
import tr from 'date-fns/locale/tr';
import uz from 'date-fns/locale/uz';
import vi from 'date-fns/locale/vi';
import zhcn from 'date-fns/locale/zh-CN';
import bg from 'date-fns/locale/bg';
import uk from 'date-fns/locale/uk';
import fa from 'date-fns/locale/fa-IR';
import DatePickerAtom, { registerLocale } from 'react-datepicker';
import styled, { css } from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';

import { adjustTimezoneOffset, getInputName } from '/imports/generator/api/helpers';
import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { inputStyle } from '/imports/core/ui/mixins';
import { isJobTrackEnable, staticFile } from '/lib/helpers';
import Label from '/imports/core/ui/atoms/Label';
import { RTLLanguages } from '/imports/generator/api/constants';
import intlHook from '/imports/core/api/useIntl';

registerLocale('es', es);
registerLocale('tr', tr);
registerLocale('pt', pt);
registerLocale('id', id);
registerLocale('lt', lt);
registerLocale('fr', fr);
registerLocale('hu', hu);
registerLocale('de', de);
registerLocale('vi', vi);
registerLocale('pl', pl);
registerLocale('da', da);
registerLocale('fi', fi);
registerLocale('ro', ro);
registerLocale('he', he);
registerLocale('th', th);
registerLocale('nl', nl);
registerLocale('ar', ar);
registerLocale('el', el);
registerLocale('sv', sv);
registerLocale('ka', ka);
registerLocale('lv', lv);
registerLocale('cs', cs);
registerLocale('it', it);
registerLocale('pt-br', ptbr);
registerLocale('ms', ms);
registerLocale('ru', ru);
registerLocale('sr', sr);
registerLocale('sl', sl);
registerLocale('ja', ja);
registerLocale('ko', ko);
registerLocale('zh-cn', zhcn);
registerLocale('az', az);
registerLocale('ca', ca);
registerLocale('kk', kk);
registerLocale('bn', bn);
registerLocale('hi', hi);
registerLocale('uz', uz);
registerLocale('bg', bg);
registerLocale('uk', uk);
registerLocale('fa', fa);

const DatePicker = (props) => {
  const { t, locale } = intlHook();
  const { breakpoint } = useResponsive();
  const [date, setDate] = useState(() => {
    return props.value;
  });
  const [focus, setFocus] = useState(false);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(false);
  const [closeCalendar, setCloseCalendar] = useState(false);
  const [isHiddenOnleft, setIsHiddenOnLeft] = useState(false);
  const [isHiddenOnRight, setIsHiddenOnRight] = useState(false);
  const datePickerRef = useRef();
  const wrappRef = useRef();
  const {
    disabled,
    current,
    placeholderText,
    right,
    hideDates,
    hideStartDate,
    showDay,
    endDate,
    hideMonth,
    labelSlug,
    language,
    variables,
    name,
    isCoverLetter,
    jobTrackIcon,
    isExperienceReview = false,
  } = props;

  useEffect(() => {
    if (isExperienceReview && (props.value === null || props.value?.toString() === 'Invalid Date')) {
      setDate(props.value);
    }
  }, [props.value]);

  //check if the container is partially hidden
  useEffect(() => {
    const datepickerMonthContainer = document.querySelector('.react-datepicker__month-container');
    if (datepickerMonthContainer) {
      const viewportOffset = datepickerMonthContainer.getBoundingClientRect();
      const left = viewportOffset.left;
      const right = viewportOffset.right;
      if (left < 0) {
        setIsHiddenOnLeft(true);
      }
      if (right > window.innerWidth) {
        setIsHiddenOnRight(true);
      }
    }
  }, []);

  const handleChange = (rawDate) => {
    const utcOffsetMinute = moment().utcOffset();
    /**
      to change date users tend to delete the current value and select another one
      rather than going for the latter right away. In doing so, rawDate comes
      through as null and Apollo doesn't like that one bit.
      This fix does the job for now, but the goal is to change the datepicker to
      https://github.com/airbnb/react-dates
    */
    const date = rawDate === null ? '' : new Date(moment(rawDate).add(utcOffsetMinute, 'minutes'));
    setDate(date);
    // A little trick to make that work right
    const { min, onChange } = props;
    if (min && moment(date).isBefore(min, 'month')) {
      setError(true);
    } else {
      setError(false);
    }
    if (props.onChangeDate) {
      props.onChangeDate(date);
    }
    onChange({ target: { value: date } });
  };

  const onBlur = () => {
    setFocus(false);
    setOffset(0);
  };

  const onFocus = (right) => (e) => {
    e.target.readOnly = true;
    const width = wrappRef.current.offsetWidth;
    let offset = width - 100;
    if ('md' === breakpoint && right) {
      offset = width - 375;
    } else {
      offset = width - 256;
    }
    offset = offset < 0 ? offset : 0;
    if (RTLLanguages.includes(locale)) offset = right ? 0 : offset;
    else offset = right ? offset : 0;
    setFocus(true);
    setOffset(offset);
  };

  const filterDate = (date) => {
    const { min, max, noFutureDates } = props;
    const now = new Date();
    const valid = moment(date).isSameOrAfter(min, 'month');
    if (min) {
      return valid;
    } else if (max) {
      return moment(date).isSameOrBefore(max, 'month');
    } else {
      return noFutureDates ? date <= now : true;
    }
  };

  /**
   * Handles the raw change event of the date picker.
   * if the year picker shown we need to swicth to month after
   * @param {Event} e - The raw change event.
   */
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const resetFocus = () => {
    setFocus(false);
  };

  const isFromSpecificLanguage = (locale) => {
    const languages = ['el', 'ru', 'pl', 'fi', 'ka', 'ro', 'et', 'hu', 'lv', 'lt', 'fr', 'uk'];
    return languages.includes(locale);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Delete') {
      setDate(null);
      setOffset(0);
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(false);
      }
    }
  };

  // Catch the corrupted `Invalid date` dates here.
  const selectedDate = date && date !== 'Invalid date' ? adjustTimezoneOffset(new Date(date)) : null;
  let dateFormat = showDay ? 'dd MMM, yyyy' : 'MMM yyyy';
  if (hideMonth === true) {
    dateFormat = 'yyyy';
  }
  const label = endDate ? 'generator.form.end_date' : 'generator.form.start_date';
  const longTextLanguage = isFromSpecificLanguage(locale);

  /*
      store all languages that are not available on date-fns/locale.
      their value are the intermediate language code that we choose for replacement if there is any.
      if they are not available but not specified it , en is by default used as the locale by date picker.
    */
  const unavailableLocales = {
    tj: 'ru',
  };
  const intermediateLocale = unavailableLocales[locale] ?? null;
  //show full month in the picker for these loacales
  const localeFullMonth = ['ar'];
  const showFullMonthYearPicker = localeFullMonth.includes(locale);
  if (showFullMonthYearPicker) {
    dateFormat = showDay ? 'dd MMMM, yyyy' : 'MMMM yyyy';
  }

  const handleWrapClick = (e) => {
    setTimeout(() => {
      if (wrappRef.current) {
        const rect = wrappRef.current.getBoundingClientRect();
        const width = rect.width;
        const x = 254 - width;
        wrappRef.current.style.setProperty('--wrap-x', `${x}px`);
      }
    }, 10);
  };

  return (
    <Wrap
      ref={wrappRef}
      offset={offset}
      $isHiddenOnleft={isHiddenOnleft}
      $isHiddenOnRight={isHiddenOnRight}
      endDate={endDate ? true : false}
      onClick={handleWrapClick}
    >
      {
        <StyledLabel language={language} form={1} longTextLanguage={longTextLanguage} $noPadding={longTextLanguage}>
          <span>{labelSlug ? t(labelSlug) : t(label)}</span>
          <OverlayBackground />
        </StyledLabel>
      }
      <DatePickerComponent
        name={getInputName(variables?.field || name, variables)}
        className={props.className}
        selected={current || hideDates || hideStartDate ? null : selectedDate}
        utcOffset={180}
        onBlur={onBlur}
        onFocus={onFocus(right)}
        onCalendarClose={resetFocus}
        onChange={handleChange}
        dateFormat={dateFormat}
        showMonthYearPicker={!showDay}
        showFullMonthYearPicker={showFullMonthYearPicker}
        onChangeRaw={handleDateChangeRaw}
        disabled={disabled}
        placeholderText={placeholderText || t('generator.form.select_date')}
        filterDate={filterDate}
        focus={focus}
        locale={intermediateLocale || locale}
        valid={!error && selectedDate}
        onKeyDown={handleKeyDown}
        maxDate={!isCoverLetter && moment().toDate()}
        ref={datePickerRef}
      />
      <StyledIcon
        error={error || (isExperienceReview && !date)}
        longTextLanguage={longTextLanguage}
        empty={isExperienceReview ? !date : !selectedDate}
        inputValid={(!error && selectedDate) || isExperienceReview}
        hide={focus}
        isJobTrack={jobTrackIcon || isJobTrackEnable()}
      />
      {error && <ErrorMessage>{t('generator.form_date_picker_error')}</ErrorMessage>}
    </Wrap>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  noFutureDates: PropTypes.bool,
  current: PropTypes.bool,
  placeholderText: PropTypes.string,
  right: PropTypes.bool,
  hideDates: PropTypes.bool,
  hideStartDate: PropTypes.bool,
  showDay: PropTypes.bool,
  endDate: PropTypes.bool,
  labelSlug: PropTypes.string,
  language: PropTypes.string,
  className: PropTypes.string,
  hideMonth: PropTypes.bool,
  jobTrackIcon: PropTypes.bool,
  isExperienceReview: PropTypes.bool,
};

const StyledIcon = styled(Icon)`
  z-index: 201;
  max-height: 50px;
  ${(p) =>
    p.longTextLanguage &&
    css`
      @media (min-width: 1025px) and (max-width: 1455px) {
        display: none;
      }
    `}
`;

const DatePickerStyle = css`
  .react-datepicker-wrapper {
    display: block !important;

    .react-datepicker__input-container {
      position: static;
      display: block !important;

      input {
        width: 100%;
        background-color: transparent;
        ${inputStyle}
        box-shadow: none;
        border-radius: 4px;
        border: 2px solid #e6e6ff;
        padding: 13px 16px 9px;
        ${({ theme }) =>
          theme.designV2 &&
          css`
            height: 48px;
            align-self: stretch;
            flex-grow: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 8px;
            gap: 12px;
            padding: 13px 16px 13px;
            border: solid 1px #e3e3e4;
            background-color: #ffffff;
            flex-direction: row;
          `}
        &::-webkit-input-placeholder {
          color: ${({ theme }) => theme.colors.gray.light};
          text-transform: capitalize;
        }
        &:disabled {
          color: ${(p) => p.theme.colors.gray.light};
          -webkit-text-fill-color: ${(p) => p.theme.colors.gray.light};
          opacity: 1;
          border: solid 1px #e3e3e4 !important;
          background: white !important;
          cursor: not-allowed;
        }
      }
    }
  }

  .react-datepicker__month--selected {
    color: #fff !important;
  }

  .react-datepicker__day-name {
    margin: 1rem 0.1666rem 0;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    display: none;
  }
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #1688fe;

    &:hover {
      background-color: #0b78e8 !important;
    }
  }

  .react-datepicker__year-text--selected {
    background-color: #bad9f1;
  }

  .react-datepicker__year-text--disabled,
  .react-datepicker__month-text--disabled {
    color: #ccc !important;
    background-color: white !important;
    pointer-events: none;
  }

  .react-datepicker__month-text--today:not(.react-datepicker__month-text--selected) {
    background-color: white;
    color: #ccc;
  }

  .react-datepicker-popper {
    z-index: 999;
    left: ${({ offset }) => offset}px !important;
    position: absolute !important;
    inset: 20px auto auto 0px !important;
    transform: ${({ theme: { isRTL }, endDate }) =>
      endDate ? `translate(calc(${isRTL ? '1' : '-1'} * var(--wrap-x)), 48px)` : 'translate(0px, 48px)'} !important;
    z-index: 10;
    .react-datepicker__triangle {
      display: none !important;
    }
    > div {
      border: none;
    }

    .react-datepicker {
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation--previous {
        position: absolute;
        top: 4px;
        left: 15px;
        z-index: 255;
        background: url(${staticFile('img/ui/arrow_left.svg')}) no-repeat !important;
        width: 24px;
        height: 24px;
        border: none;
        transform: rotate(180deg);
        color: rgb(166, 133, 113);
      }
      .react-datepicker__navigation--previous:hover {
        opacity: 1;
      }
      .react-datepicker__navigation--next {
        z-index: 255;
        position: absolute;
        top: 4px;
        left: 215px;
        background: url(${staticFile('img/ui/arrow_right.svg')}) no-repeat !important;
        width: 24px;
        height: 24px;
        border: none;
        color: #7171a6;
        ${({ theme: { isRTL } }) =>
          isRTL &&
          css`
            left: auto;
            right: 15px;
          `}
      }
      .react-datepicker__navigation--next:hover {
        opacity: 1;
      }
      .react-datepicker__month-container,
      .react-datepicker__year--container {
        font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
        position: absolute;
        top: -17px;
        border-radius: 3px;
        border: solid 2px #e6e6ff;
        background-color: var(--light-values-white);
        z-index: 205;
        transform-origin: 50% 0px;
        width: 254px;
        border-radius: 3px;
        padding-bottom: 4px;

        .react-datepicker__header {
          font-family: ${({ theme }) => theme.font.family.websiteBold};
          color: #33334f;
          border-bottom: none;
          background: transparent;
          font-size: 16px;
          padding: 20px 10px 0;
        }

        .react-datepicker__year-wrapper {
          max-width: none !important;
        }
        .react-datepicker__day-name {
          user-select: none;
          cursor: pointer;
          font-size: 16px;
          color: #7171a6;
        }

        .react-datepicker__month-wrapper,
        .react-datepicker__year-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          .react-datepicker__month-text,
          .react-datepicker__year-text,
          .react-datepicker__day-name {
            position: relative;
            user-select: none;
            cursor: pointer;
            font-size: 16px;
            color: #7171a6;
            width: 58px;
            line-height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .react-datepicker__month-text:hover,
          .react-datepicker__year-text:hover {
            color: #1688fe;
            background: transparent;

            &::before {
              content: '';
              width: 58px;
              height: 38px;
              background-color: #e7f3fe;
              display: block;
              position: absolute;
              left: 0px;
              top: 0px;
              z-index: -1;
              border-radius: 3px;
            }
          }
        }
      }
      ${({ theme: { isRTL } }) =>
        isRTL &&
        css`
          width: 254px;
        `}
    }
  }
  ${({ theme: { isRTL }, $isHiddenOnRight }) =>
    !isRTL &&
    $isHiddenOnRight &&
    css`
      .react-datepicker-popper {
        width: 100%;
        .react-datepicker {
          width: 100%;

          .react-datepicker__month-container {
            right: 0;
          }
          .react-datepicker__navigation--previous {
            left: -62px;
          }
          .react-datepicker__navigation--next {
            left: auto;
            right: 15px;
          }
        }
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      .react-datepicker__month {
        direction: rtl;
      }
      .react-datepicker-popper {
        width: 100%;
        ${({ $isHiddenOnleft }) =>
          $isHiddenOnleft &&
          css`
            width: auto;
          `}
      }
    `}
`;

const Wrap = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.general.borderRadius};
  .react-datepicker__input-container {
    input {
      background-color: green;
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${DatePickerStyle}
`;

const DatePickerComponent = styled(DatePickerAtom)`
  position: relative;
  z-index: 100;
  background-color: var(--light-values-white) !important;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: 0.2px !important;
  color: #50575d !important;
  border: solid 2px #e6e6ff;
  padding: 13px 16px 9px !important;

  ${(p) =>
    p.disabled &&
    css`
      font-weight: normal;
    `}
  .react-datepicker__input-container input {
    ${inputStyle}
  }

  ${({ focus }) =>
    focus &&
    css`
      border-color: #1688fe !important;
    `}

  ${({ valid, focus }) =>
    valid &&
    !focus &&
    css`
      border-color: ${({ theme }) => (theme.designV2 ? '#e3e3e4' : '#19cca3')};
    `}
    ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const StyledLabel = styled(Label)`
  text-transform: ${({ theme }) => (theme.designV2 ? 'capitalize' : 'uppercase')};
  position: absolute;
  left: ${({ theme: { isRTL } }) => (!isRTL ? '8px' : 'auto')};

  top: -8px;
  padding: ${({ theme }) => (theme.designV2 ? '0 4px' : '0 5px')};
  background-color: #f7f7fc;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 11px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #46466b;
  @media (min-width: 1025px) and (max-width: 1455px) {
    ${(p) =>
      p.longTextLanguage &&
      css`
        font-size: 8px !important;
        left: 5px;
      `}
    ${(p) =>
      p.$noPadding &&
      css`
        padding: 0 !important;
      `}
  }

  span {
    position: relative;
    text-transform: uppercase;
    z-index: 150;
    background-color: #f7f7fc;
    padding: 0px 4.6px;
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 14px;
    `}
`;

const OverlayBackground = styled.div`
  background: white;
  width: 100%;
  position: absolute;
  top: 10px;
  height: 10px;
  left: 0;
`;

export default DatePicker;
