import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import CheckBox from '/imports/job-tracking/ui/atoms/CheckBox';
import intlHook from '/imports/core/api/useIntl';
import { Option, OptionWrapper, Question } from '/imports/carrer/atmos/CarreeModalAtmos';
import useTracking from '/imports/core/hooks/useTracking';

const Tab2 = ({ handleChange, field, handleClick }) => {
  const { t } = intlHook();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('profession_help_services_view');
  }, []);

  return (
    <Fragment>
      <OptionWrapper fullWidth direction="column" smallGap>
        <StyledFlex alignItems="center">
          <StyledCheckBox
            isStep
            onChange={handleClick}
            onClick={handleClick('professionHelpServices', 'Yes, to proofread and optimize my CV.')}
            checked={field.professionHelpServices.includes('Yes, to proofread and optimize my CV.')}
          />
          <Option
            checkedBox
            onClick={handleClick('professionHelpServices', 'Yes, to proofread and optimize my CV.')}
            checked={field.professionHelpServices.includes('Yes, to proofread and optimize my CV.')}
          >
            {t('career_option_8_1')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <StyledCheckBox
            isStep
            onClick={handleClick('professionHelpServices', 'Yes, to take stock of my career.')}
            value="Yes, to take stock of my career."
            checked={field.professionHelpServices.includes('Yes, to take stock of my career.')}
            onChange={handleChange}
          />{' '}
          <Option
            checkedBox
            onClick={handleClick('professionHelpServices', 'Yes, to take stock of my career.')}
            checked={field.professionHelpServices.includes('Yes, to take stock of my career.')}
          >
            {t('career_option_8_2')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <StyledCheckBox
            isStep
            onClick={handleClick('professionHelpServices', 'Yes, to assist me in my job search.')}
            name="currentCareerStatus"
            value="Yes, to assist me in my job search."
            checked={field.professionHelpServices.includes('Yes, to assist me in my job search.')}
            onChange={handleChange}
          />{' '}
          <Option
            checkedBox
            onClick={handleClick('professionHelpServices', 'Yes, to assist me in my job search.')}
            checked={field.professionHelpServices.includes('Yes, to assist me in my job search.')}
          >
            {t('career_option_8_3')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <StyledCheckBox
            isStep
            onClick={handleClick('professionHelpServices', 'Yes, to prepare for job interviews.')}
            name="currentCareerStatus"
            value="Yes, to prepare for job interviews."
            onChange={handleChange}
            checked={field.professionHelpServices.includes('Yes, to prepare for job interviews.')}
          />
          <Option
            checkedBox
            onClick={handleClick('professionHelpServices', 'Yes, to prepare for job interviews.')}
            checked={field.professionHelpServices.includes('Yes, to prepare for job interviews.')}
          >
            {t('career_option_8_4')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <StyledCheckBox
            isStep
            onClick={handleClick('currentCareerStatus', 'None')}
            name="currentCareerStatus"
            value="None"
            onChange={handleChange}
            checked={field.professionHelpServices.includes('None')}
          />
          <Option
            onClick={handleClick('currentCareerStatus', 'None')}
            checked={field.professionHelpServices.includes('None')}
            checkedBox
          >
            {t('career_option_8_5')}
          </Option>
        </StyledFlex>
      </OptionWrapper>
    </Fragment>
  );
};

const StyledFlex = styled(Flex)`
  width: 100%;
  position: relative;
`;

const StyledCheckBox = styled(CheckBox)`
  position: absolute;
  right: 16px;
  z-index: 1;
`;

const RadioInput = styled.input`
  position: absolute;
  right: 16px;
  z-index: 1;
`;

Tab2.propTypes = {
  handleChange: PropTypes.func,
  field: PropTypes.object,
  handleClick: PropTypes.func,
};

export default Tab2;
