import useTracking from 'imports/core/hooks/useTracking';
import React, { useEffect } from 'react';

const ChatBot = () => {
  const { trackEvent } = useTracking();
  useEffect(() => {
    // if (window.chatWidgetScriptLoaded) return;

    const pageURL = window.location.href;

    window.ChatWidgetConfig = {
      projectId: '676a93333c8c0c245687c9f2',
      customVariable: {
        currentURL: pageURL,
      },
    };

    setTimeout(() => {
      const messagesDiv = document.getElementById('proactive-messages');
      if (!messagesDiv) {
        console.error('Proactive messages div not found.');
        return;
      }

      const messages = ['We have 3 tips on how you can improve your resume ✍️', 'Interested? 🤔'];
      messages.forEach((message, index) => {
        setTimeout(() => {
          const msgElement = document.createElement('div');
          msgElement.className = 'proactive-message';
          msgElement.textContent = message;

          msgElement.onclick = () => {
            if (window.ChatWidget && window.ChatWidget.open) {
              trackEvent('bot_text_clicked');
              window.ChatWidget.open();
            }
            messagesDiv.innerHTML = ''; // Remove messages after clicking
          };

          messagesDiv.appendChild(msgElement);
        }, index * 500);
      });
    }, 2000);

    const chatWidgetScript = document.createElement('script');
    chatWidgetScript.src = 'https://storage.googleapis.com/cdwidget/dist/assets/js/main.js';
    chatWidgetScript.async = true;
    document.body.appendChild(chatWidgetScript);

    // window.chatWidgetScriptLoaded = true;
  }, []);

  return (
    <>
      <div id="cd-widget"></div>
      <div className="proactive-bubbles" id="proactive-messages"></div>
      <style>{`
       /* Styles for proactive messages */
      .proactive-bubbles {
        position: fixed;
        bottom: 100px;
        right: 20px;
        z-index: 9998;
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 280px;
        
        align-items: flex-end; /* This aligns children to the right */
      }
      .proactive-message {
        background-color: #333;
        color: white;
        padding: 12px 16px;
        border-radius: 18px; /* Back to original rounded corners */
        font-size: 14px;
        font-family: Arial, sans-serif;
        cursor: pointer;
        animation: fadeIn 0.5s ease-in;
        max-width: 85%;
        word-wrap: break-word;
        line-height: 1.4;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
      }
        .bg-primary{
           background-color: #333 !impotemt;
        }
      /* Style for the second (bottom) message to be shorter */
      .proactive-message:nth-child(2) {
        max-width: 60%; /* Make bottom message shorter */
      }
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(10px); }
        to { opacity: 1; transform: translateY(0); }
      }
      `}</style>
    </>
  );
};

export default ChatBot;
