import DatepickerAutosave from './DatepickerAutosave';
import Flex from './Flex';
import { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CheckboxAutosave from '/imports/core/ui/atoms/CheckboxAutosave';
import intlHook from '/imports/core/api/useIntl';

const DateRangeAutosave = (props) => {
  const {
    showCheckbox,
    hideDatesCheckbox,
    value,
    variables,
    hideStartDateCheckbox,
    showHideMonth,
    optimisticResponse,
    language,
  } = props;
  const { t } = intlHook();
  const current = value[2];
  const hideDates = value[3];
  const hideStartDate = value[4];
  const hideMonth = value[5] ?? false;

  const startDateProps = {
    ...props,
    value: hideStartDate ? null : value[0],
    max: !current ? new Date(value[1]) : null,
    noFutureDates: true,
    placeholderText: hideDates || hideStartDate ? 'N/A' : null,
    hideDates,
    hideStartDate,
    variables: {
      ...props.variables,
      field: props.variables.field[0],
    },
    onChangeDate: (date) => optimisticResponse({ field: props.variables.field[0], value: moment(date).toISOString() }),
  };

  const endDateProps = {
    ...props,
    value: value[1],
    current,
    hideDates,
    min: hideStartDate ? null : new Date(value[0]),
    placeholderText: hideDates ? 'N/A' : current ? t('present') : null,
    variables: {
      ...props.variables,
      field: props.variables.field[1],
    },
    error: !current && !hideDates && !hideStartDate && moment(value[0]).isAfter(moment(value[1]), 'month'),
    right: true,
    onChangeDate: (date) => optimisticResponse({ field: props.variables.field[1], value: moment(date).toISOString() }),
  };

  const currentProps = {
    ...props,
    value: value[2] || '',
    variables: {
      ...props.variables,
      field: props.variables.field[2],
    },
    optimisticResponse: () => optimisticResponse({ value: !(!hideDates && current), field: 'current' }),
  };

  const hideDatesProps = {
    ...props,
    value: hideDates || '',
    variables: {
      ...variables,
      field: variables.field[3],
    },
    hideDates,
    optimisticResponse: () => optimisticResponse({ value: !hideDates, field: 'hideDates' }),
  };

  const hideStartDateProps = {
    ...props,
    value: hideStartDate || '',
    variables: {
      ...variables,
      field: props.variables.field[4],
    },
    optimisticResponse: () => optimisticResponse({ value: !(!hideDates && hideStartDate), field: 'hideStartDate' }),
  };

  const hideMonthProps = {
    ...props,
    value: hideMonth || false,
    variables: {
      ...variables,
      field: props.variables.field[5],
    },
    optimisticResponse: () => optimisticResponse({ value: !hideMonth, field: 'hideMonth' }),
  };

  const renderCheckbox = () => {
    return (
      <Flex style={{ columnGap: 8, flexWrap: 'wrap', flexDirection: showHideMonth ? 'row' : 'column' }}>
        {showCheckbox && (
          <CheckboxWrap>
            <CheckboxAutosave
              {...currentProps}
              id="currentlyWorkHere"
              checked={!hideDates && current}
              width="16px"
              height="16px"
              borderRadius="3px"
              wizard
              isDisabled={hideDates}
            />
            <LabelWrap language={language} isDisabled={hideDates}>
              <label htmlFor="currentlyWorkHere">{t('generator.form.current_work_here')}</label>
            </LabelWrap>
          </CheckboxWrap>
        )}
        {showHideMonth && (
          <CheckboxWrap>
            <CheckboxAutosave
              {...hideMonthProps}
              id="showHideMonth"
              checked={hideMonth}
              width="16px"
              height="16px"
              borderRadius="3px"
              wizard
              isDisabled={hideDates}
            />
            <LabelWrap language={language} isDisabled={hideDates}>
              <label htmlFor="showHideMonth">
                {current ? t('generator.form.hide_month') : t('generator.form.hide_months')}
              </label>
            </LabelWrap>
          </CheckboxWrap>
        )}
        {hideStartDateCheckbox && (
          <CheckboxWrap>
            <CheckboxAutosave
              {...hideStartDateProps}
              id="hideStartDate"
              checked={!hideDates && hideStartDate}
              width="16px"
              height="16px"
              borderRadius="3px"
              wizard
              isDisabled={hideDates}
            />
            <LabelWrap language={language} isDisabled={hideDates}>
              <label htmlFor="hideStartDate">{t('generator.form.show_only_end_date_here')}</label>
            </LabelWrap>
          </CheckboxWrap>
        )}
        {hideDatesCheckbox && (
          <CheckboxWrap>
            <CheckboxAutosave
              {...hideDatesProps}
              id="hideDates"
              checked={hideDates}
              width="16px"
              height="16px"
              borderRadius="3px"
              wizard
            />
            <LabelWrap language={language}>
              <label htmlFor="hideDates">{t('generator.form.do_not_show_dates_here')}</label>
            </LabelWrap>
          </CheckboxWrap>
        )}
      </Flex>
    );
  };

  return (
    <Fragment>
      <DatepickerWrap>
        <div>
          <DatepickerAutosave
            language={language}
            {...startDateProps}
            hideMonth={hideMonth}
            showMonthYearPicker
            disabled={hideDates || hideStartDate}
          />
        </div>
        <div>
          <DatepickerAutosave
            language={language}
            {...endDateProps}
            endDate
            showMonthYearPicker
            hideMonth={hideMonth}
            disabled={current || hideDates}
          />
        </div>
      </DatepickerWrap>
      {renderCheckbox()}
    </Fragment>
  );
};

DateRangeAutosave.propTypes = {
  showCheckbox: PropTypes.bool,
  hideDatesCheckbox: PropTypes.bool,
  value: PropTypes.string,
  variables: PropTypes.object,
  hideStartDateCheckbox: PropTypes.bool,
  optimisticResponse: PropTypes.func,
  language: PropTypes.string,
};

const DatepickerWrap = styled.div`
  position: relative;
  display: flex;
  z-index: 150;
  min-height: 50px;
  > div {
    width: 48.5%;
    &:first-of-type {
      margin-right: 3%;
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      > div {
        width: 48.5%;
        &:first-of-type {
          margin-left: 3%;
          margin-right: 0;
        }
      }
    `}
`;

const CheckboxWrap = styled.div`
  padding-top: 18px;
  display: flex;
  align-items: center;
`;

const LabelWrap = styled.span`
  padding-left: 8px;
  label {
    letter-spacing: 0.1px;
    font-family: ${({ theme }) => theme.font.family.websiteMedium};
    color: #7171a6;
    font-size: 14px;
    line-height: normal;
    cursor: pointer;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        padding-right: 8px;
        padding-left: 0;
      `}
  }
  ${(p) =>
    p.isDisabled &&
    css`
      cursor: not-allowed !important;
      > label {
        pointer-events: none !important;
      }
    `}
`;

export default DateRangeAutosave;
