import { prepareLanguageLabelBeforeTranslate } from 'imports/core/ui/components/LocaleSelect';
import { extractBlockInfo, replacedBlocks } from 'imports/generator/api/helpers';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useSelectStore } from 'zustand/SelectStore';
import { extractTemplateVersionByID, formattedDefaultSelected } from '../helpers';
import { ChevronContainer, DropdownButton, DropdownContent, DropdownMenu, Selected } from './Dropdown';
import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import CheckIcon from '/imports/core/ui/assets/new/CheckIcon';
import { UPDATE_BLOCK_POSITION } from '/imports/generator/api/apollo/client/mutations';
import { Chevron } from '/imports/generator/ui/assets';

const DropdownForAutosave = (props) => {
  const [updateBlockPosition] = useMutation(UPDATE_BLOCK_POSITION);
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  const lastTargetButton = useRef(null);
  const { expanded, setExpanded } = useSelectStore();
  const [hovered, setHovered] = useState(() => {
    return props.hovered || '';
  });
  const dropdownComponent = useRef();
  const dropdown = useRef();
  const {
    name,
    dark,
    options,
    defaultSelected,
    preview,
    isControlled,
    selected: customSelected,
    borderActive,
    borderError,
    isFontSelector,
    isExpanded,
  } = props;

  const chevron = isExpanded ? <Chevron.Up /> : <Chevron.Down />;
  const selectedWrapperName = `interactive-element-clickable-${name}`;
  const sortedOptions = useMemo(() => {
    if (name === 'language') {
      const translatedOptions = options.map((option) => {
        const translatedTitle = t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`);
        return {
          ...option,
          translatedTitle,
        };
      });

      translatedOptions.sort((a, b) => {
        return a.translatedTitle.localeCompare(b.translatedTitle);
      });
      return translatedOptions;
    } else {
      return options;
    }
  }, [options, t]);

  const [selected, setSelected] = useState(() => {
    return (
      props.options.find(
        (o) => o.value.toString() === (props.isControlled ? props.selected : formattedDefaultSelected(defaultSelected)),
      ) || {}
    );
  });

  const title = useMemo(() => {
    const { dark, placeholder, placeholderSlug, expandTitle } = props;
    if (dark && isExpanded) {
      return expandTitle;
    }
    const languageTitle = prepareLanguageLabelBeforeTranslate(selected.title);
    let title = selected.titleSlug && t(selected.titleSlug) ? t(selected.titleSlug) : selected.title;
    if (title && selected.appendText) title = `${title} ${selected.appendText}`;
    return (
      t(`language.${languageTitle}`) ||
      title || <span>{placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}</span>
    );
  }, [selected]);

  const scrollOption = () => {
    const { scrollOption } = props;

    if (scrollOption) {
      const currentYear = new Date().getFullYear();
      const elem = document.getElementById(`value-${formattedDefaultSelected(defaultSelected) || currentYear}`);
      if (elem) {
        const offSet = elem.offsetTop;
        dropdown.current.scrollTop = offSet;
      }
    }
  };

  const outsideClick = (event) => {
    if (!dropdown.current.contains(event.target) && isExpanded) {
      setExpanded(null);
    }
  };

  const handleDropdownScroll = (event) => {
    const { name } = props;
    const letter = event.key;

    if (isExpanded && /^[A-Za-z]$/.test(letter)) {
      const buttons = Array.from(document.querySelectorAll(`#dropdown-menu-${name} > button[id^="value-"]`));

      const targetButton = buttons.find((button) => {
        const buttonText = button.textContent.trim();
        const isMatch = buttonText.startsWith(letter) || buttonText.startsWith(letter.toUpperCase());
        return isMatch && lastTargetButton.current !== button;
      });

      if (targetButton) {
        lastTargetButton.current = targetButton;

        const yPosition =
          targetButton.getBoundingClientRect().top -
          dropdown.current.getBoundingClientRect().top +
          dropdown.current.scrollTop -
          5;
        dropdown.current.scrollTo({ top: yPosition });
      }
    }
  };

  useEffect(() => {
    const addOutsideClickListeners = (parentElement) => {
      if (!parentElement) return;
      const children = Array.from(parentElement.childNodes);
      children.forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(dropdownComponent)) {
          childNode.addEventListener('click', outsideClick);
        }
      });
    };

    const removeOutsideClickListeners = (parentElement) => {
      if (!parentElement) return;
      const children = Array.from(parentElement.childNodes);
      children.forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(dropdownComponent)) {
          childNode.removeEventListener('click', outsideClick);
        }
      });
    };

    const onComponentMount = () => {
      scrollOption();

      const parentElement = dropdownComponent.parentNode?.parentNode?.parentNode;
      addOutsideClickListeners(parentElement);

      if (props.scrollOnKeyPress) {
        document.addEventListener('keydown', handleDropdownScroll);
      }
    };

    const onComponentUnmount = () => {
      const parentElement = dropdownComponent.parentNode?.parentNode?.parentNode;
      removeOutsideClickListeners(parentElement);

      if (props.scrollOnKeyPress) {
        document.removeEventListener('keydown', handleDropdownScroll);
      }
    };

    onComponentMount();

    return onComponentUnmount;
  }, [isExpanded]);

  const windowClick = () => {
    setExpanded(null);
    document.removeEventListener('click', windowClick);
  };

  const toggleExpanded = (e, name) => {
    e.stopPropagation();
    if (isExpanded === false) {
      document.addEventListener('click', windowClick);
    } else {
      document.removeEventListener('click', windowClick);
      lastTargetButton.current = null;
    }
    setExpanded(expanded == name ? null : name);
  };

  const moveLeftBlocksToRight = () => {
    const { currentResume: resume, setCurrentResume: updateImmue } = props;
    const { id, blocks } = resume;
    let highestY = Math.max(...blocks.filter((block) => block.position[0] === 1).map((block) => block.position[1]), -1);

    const newBlocks = blocks.map((block) => {
      if (block.position[0] === 0) {
        highestY += 1;
        return {
          ...block,
          position: [1, highestY],
        };
      }
      return block;
    });
    const newResume = replacedBlocks(resume, newBlocks);
    updateImmue(newResume);
    updateBlockPosition({
      variables: {
        resumeId: id,
        blocks: extractBlockInfo(newBlocks),
      },
    });
  };
  const handleSelect = (option) => {
    const { onChange } = props;
    if (window) {
      switch (name) {
        case 'language':
          trackEvent('resume_language_selected', { resume_language: option.value });
          break;
        case 'headingFont':
          trackEvent('resume_headingFont_selected', { resume_headingFont: option.value });
          break;
        case 'contentFont':
          trackEvent('resume_contentFont_selected', { resume_contentFont: option.value });
          break;
        default:
          break;
      }
    }
    if (name === 'mainColumnSize' && option.value === 'none') {
      moveLeftBlocksToRight();
    }

    if (name == 'template' && ['budapest-v2', 'budapest-v3'].includes(option.value)) {
      onChange({ target: { name: name, value: 'budapest', version: extractTemplateVersionByID(option.value) } });
    } else {
      onChange({ target: { name: name, value: option.value } });
    }

    setSelected(option);
    setExpanded(null);
  };

  const handleHover = (hovered) => {
    setHovered(hovered);
  };
  return (
    <DropdownContent dark={dark} huge={preview} ref={dropdownComponent}>
      <Selected
        onClick={(e) => {
          toggleExpanded(e, name);
        }}
        dark={dark}
        preview={preview}
        expanded={isExpanded}
        borderActive={borderActive}
        borderError={borderError}
        font={isControlled ? customSelected : selected.value}
        isFontSelector={isFontSelector}
        name={selectedWrapperName}
      >
        {title}
        <ChevronContainer preview={preview}>{chevron}</ChevronContainer>
      </Selected>
      <DropdownMenu
        dark={dark}
        expanded={isExpanded}
        onClick={(e) => e.stopPropagation()}
        ref={dropdown}
        huge={preview}
        preview={preview}
        className="dropdown-menu"
        id={`dropdown-menu-${name}`}
      >
        {(sortedOptions.length &&
          sortedOptions.map((option) => {
            return (
              <DropdownButton
                type="button"
                key={option.value}
                dark={dark}
                onMouseEnter={() => handleHover(option.value)}
                onMouseLeave={handleHover}
                onClick={() => handleSelect(option)}
                selected={isControlled ? option.value === customSelected : option.value === selected.value}
                hovered={hovered === option.value}
                preview={preview}
                id={`value-${option.value}`}
                font={option.value}
                isFontSelector={isFontSelector}
              >
                <CheckIcon />
                {name === 'language' ? (
                  <> {t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`)}</>
                ) : option.titleSlug && t(option.titleSlug) ? (
                  t(option.titleSlug)
                ) : (
                  option.title
                )}
              </DropdownButton>
            );
          })) ||
          props.children()}
      </DropdownMenu>
    </DropdownContent>
  );
};

DropdownForAutosave.propTypes = {
  selected: PropTypes.string,
  defaultSelected: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  dark: PropTypes.bool,
  stripe: PropTypes.bool,
  scrollOption: PropTypes.bool,
  expandTitle: PropTypes.string,
  isControlled: PropTypes.bool,
  borderActive: PropTypes.bool,
  borderError: PropTypes.bool,
  isFontSelector: PropTypes.bool,
  scrollOnKeyPress: PropTypes.bool,
  name: PropTypes.string,
  locale: PropTypes.string,
  placeholderSlug: PropTypes.string,
  preview: PropTypes.any,
  children: PropTypes.node,
};

export default DropdownForAutosave;
