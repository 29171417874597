import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const EmailIcon = ({ references }) => (
  <SvgIcon
    references={references ? 1 : 0}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M8.38863 12.1979L5.56533 9.49475L1.21024 14H15.577L11.2119 9.49475L8.38863 12.1979Z"
      fill="#A7905D"
    />
    <StyledPath d="M15.7773 12.7085V5.27979L11.9027 8.74383L15.7773 12.7085Z" fill="#A7905D" />
    <StyledPath d="M1 5.27979V12.7085L4.87452 8.74383L1 5.27979Z" fill="#A7905D" />
    <StyledPath d="M1 2.39648V3.79812L8.38863 10.7062L15.7773 3.79812V2.39648H1Z" fill="#A7905D" />
  </SvgIcon>
);

EmailIcon.propTypes = {
  references: PropTypes.bool,
};

const StyledPath = styled.path`
  fill: ${({ theme: { rigaColor } }) => rigaColor('#929292')};
`;

const SvgIcon = styled.svg`
  stroke: none;
  ${({ theme: { convertPx, isRTL }, references }) => css`
    width: ${convertPx(24)};
    height: ${convertPx(24)};
    ${references &&
    css`
      width: ${convertPx(14)};
      height: ${convertPx(11)};
      margin-right: ${convertPx(isRTL ? 0 : 8)};
      margin-left: ${convertPx(isRTL ? 8 : 0)};
      path {
        fill: #333e50;
      }
    `}
  `}
`;

export default EmailIcon;
