import React from 'react';

const BlockCloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.7071 2.70711C15.0976 2.31658 15.0976 1.68342 14.7071 1.29289C14.3166 0.902369 13.6834 0.902369 13.2929 1.29289L8 6.58579L2.70711 1.29291C2.31658 0.902391 1.68342 0.902391 1.29289 1.29292C0.902368 1.68344 0.902369 2.31661 1.29289 2.70713L6.58579 8.00001L1.2929 13.2929C0.902374 13.6834 0.902374 14.3166 1.2929 14.7071C1.68342 15.0977 2.31659 15.0977 2.70711 14.7071L8 9.41422L13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L9.41421 8L14.7071 2.70711Z"
        fill="#14141F"
      />
    </svg>
  );
};

export default BlockCloseIcon;
