import React from 'react';
import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';

const WizardListItem = ({ children }) => {
  return (
    <Container>
      <div>
        <Icon />
      </div>
      <ListItem>{children}</ListItem>
    </Container>
  );
};

WizardListItem.displayName = 'WizardListItem';

const ListItem = styled.p`
  font-family: ${(p) => p.theme.font.accent};
  font-size: 16px;
  margin: 0;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 17px;
  color: #3d4047;
  ${({ theme }) =>
    theme.max('xs')`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `}
`;

const Icon = styled((p) => <SvgIcon.Check {...p} />)`
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  fill: #19cca3;
  ${({ theme }) =>
    theme.max('xs')`
  margin-top: 1px;
`}
`;

const Container = styled(Flex)`
  margin-bottom: 15px;
  ${({ theme }) =>
    theme.max('xs')`
    padding: 0 5px;
    margin-bottom: 20px;
  `}
`;

export default WizardListItem;
