import React, { useEffect, useRef, useState } from 'react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import BlockCloseIcon from '/imports/generator/ui/assets/BlockCloseIcon';
import Flex from '/imports/core/ui/atoms/Flex';
import Modal from '/imports/core/ui/atoms/Modal';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { withBlock } from '/imports/generator/context/blockresposition.context';

const modalStyles = {
  modalContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    backgroundColor: 'rgba(52, 60, 73, 0.30)',
  },
  modalBackdrop: {
    backgroundColor: 'rgba(52, 60, 73, 0.30)',
    zIndex: '9999',
  },
  modalBody: {
    maxWidth: '571px',
    background: 'var(--light-values-white)',
    margin: 'auto',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    flexGrow: '0',
    borderRadius: '8px',
    boxShadow: '0px 12px 48px 0px rgba(20, 20, 31, 0.24)',
  },
};

const BlockRepositionModal = ({ isOpen, onClose }) => {
  const {
    state: { toggle, prevtoggleState },
    dispatch,
  } = withBlock();
  const { trackEvent } = useTracking();
  const { t } = useIntl();
  const prevStateRef = useRef((toggle?.active === true && 'active') || 'deActive');

  useEffect(() => {
    trackEvent('block_reposition_modal_trigger');
  }, []);

  const handleClose = () => {
    trackEvent('block_reposition_cancel');
    const obj = {
      active: prevStateRef.current === 'active' ? true : false,
      deActive: prevStateRef.current === 'deActive' ? true : false,
    };
    dispatch({ type: 'UPDATE_STATE', name: 'toggle', payload: obj });
    onClose({ cancel: true, stateName: prevStateRef.current });
  };

  const handleSave = () => {
    const name = toggle.active ? 'active' : 'deactive';
    trackEvent('block_reposition_toggle_triger', { selected: name });
    onClose({ cancel: false });
  };

  const handleToggle = (name) => () => {
    const obj = {
      active: name === 'active' ? true : false,
      deActive: name === 'deActive' ? true : false,
    };
    dispatch({ type: 'UPDATE_STATE', name: 'prevtoggleState', payload: toggle.active ? 'active' : 'deactive' });
    dispatch({ type: 'UPDATE_STATE', name: 'toggle', payload: obj });
  };

  return (
    <Modal onClose={onClose} fullScreen styles={modalStyles} animation="empty" openStateBase open={isOpen} timeout={0}>
      <Main direction="column" alignItems="center" justifyContent="center">
        <Header direction="column" alignItems="center" justifyContent="center">
          <Title>{t('resposition_modal_title')}</Title>
          <ToggleWrapper>
            <Switch onClick={handleToggle('deActive')} active={toggle.deActive}>
              {t('deactive')}
            </Switch>
            <Switch onClick={handleToggle('active')} active={toggle.active}>
              {t('active')}
            </Switch>
          </ToggleWrapper>
        </Header>
        <ButtonWrap fullWidth justifyContent="center" alignItems="center">
          <Button justifyContent="center" alignItems="center" onClick={handleClose}>
            {t('jobtrack_modal_cancel_button_text')}
          </Button>
          <Button justifyContent="center" alignItems="center" save onClick={handleSave}>
            {t('jobtrack_modal_save_button_text')}
          </Button>
        </ButtonWrap>
        <CloseWrap alignItems="center" justifyContent="center" onClick={handleClose}>
          <BlockCloseIcon />
        </CloseWrap>
      </Main>
    </Modal>
  );
};

const CloseWrap = styled(Flex)`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px 4px;
  flex-shrink: 0;
  top: 10px;
  right: 10px;
  &:hover {
    svg > path {
      fill: ${darken(0.1, '#14141F')};
    }
  }
`;

const Button = styled(Flex)`
  cursor: pointer;
  width: 150px;
  height: 40px;
  padding: 16px;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #1688fe;
  background-color: var(--light-values-white);
  color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 22px;
  &:hover {
    background-color: ${darken(0.1, '#fff')};
  }
  ${({ save }) =>
    save &&
    css`
      background-color: #1688fe;
      color: var(--light-values-white);
      &:hover {
        background-color: ${darken(0.1, '#1688fe')};
      }
    `}
`;

const ButtonWrap = styled(Flex)`
  gap: 16px;
`;

const Switch = styled(Flex)`
  cursor: pointer;
  padding: 8px 16px;
  color: var(--black);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  ${({ active }) =>
    active &&
    css`
      border-radius: 100px;
      background-color: #1688fe;
      color: var(--light-values-white);
    `}
`;

const ToggleWrapper = styled(Flex)`
  padding: 4px;
  border-radius: 100px;
  border: 1px solid var(--mid-values-gray-light);
  background: var(--light-values-white);
`;

const Title = styled.div`
  text-align: center;
  color: var(--dark-values-dark-medium);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 34px;
  line-height: 48px;
`;

const Header = styled(Flex)`
  gap: 16px;
`;

const Main = styled(Flex)`
  padding: 40px 28.5px;
  position: relative;
  gap: 32px;
`;

export default BlockRepositionModal;
