import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from '/imports/core/ui/atoms/ValidatedInputIcon';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { isJobTrackEnable, isEmptyOrUndefinedOrNull } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';
import React, { useEffect, useState } from 'react';

const InputCont = (props) => {
  const {
    type,
    placeholder,
    label,
    name,
    value,
    defaultvalue,
    className,
    error,
    customInputStripeCss,
    id,
    hideIcon: hideTextBoxIcon,
    disabled,
    view,
    placeholderSlug,
    disableShadow = false,
    customIcon = null,
    iconProps = {},
    isCoverLetter,
    fullWidth,
    autoFocus,
    onKeyDown,
    errorType,
    isErrorthere,
    hasIcon,
    showLinkIcon,
  } = props;

  const [hideIcon, setHideIcon] = useState(false);
  const [inputValid, setInputValid] = useState(!!(value || defaultvalue));
  const { t } = useIntl();
  useEffect(() => {
    if (inputValid && error) {
      setInputValid(false);
    }
  }, [inputValid, error]);

  useEffect(() => {
    if (props?.value && !inputValid && !['isEmailDuplicate', 'pattern'].includes(errorType)) setInputValid(true);
    if (!props?.value && inputValid) setInputValid(false);
  }, [props.field]);

  const onChange = (e) => {
    const { onChange } = props;

    if (e.target.value && !inputValid) {
      setInputValid(true);
    } else if (!e.target?.value && inputValid) {
      setInputValid(false);
    }

    if (onChange) onChange(e);
  };

  const handleFocus = (e) => {
    setHideIcon(true);
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setHideIcon(false);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const placeholderText = placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder;
  const isEmpty = [props.field?.value, props.field?.defaultValue, value, defaultvalue].every((_value) =>
    isEmptyOrUndefinedOrNull(_value),
  );
  const extraZIndex = isCoverLetter && name === 'firstName';
  return (
    <Wrap error={error} disableShadow={disableShadow} fullWidth={fullWidth}>
      <Input
        className={className}
        label={label}
        type={type}
        name={name}
        id={id}
        disabled={disabled || false}
        placeholder={placeholderText}
        defaultValue={value || defaultvalue}
        onFocus={handleFocus}
        view={view}
        error={error}
        inputValid={inputValid}
        customInputStripeCss={customInputStripeCss}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        {...props?.field}
        onChange={(e) => {
          e.preventDefault();
          props?.field?.onChange(e);
          onChange(e);
        }}
        onBlur={(e) => {
          handleBlur(e);
          props?.field?.onBlur(e);
        }}
        hasIcon={hasIcon}
        showLinkIcon={showLinkIcon}
      />

      {!hideTextBoxIcon && (
        <Icon
          error={error}
          empty={isEmpty}
          hide={hideIcon}
          view={view}
          inputValid={inputValid}
          customIcon={customIcon}
          isErrorthere={isErrorthere}
          {...iconProps}
          isJobTrack={isJobTrackEnable()}
          isCoverLetter={isCoverLetter}
          extraZIndex={extraZIndex}
        />
      )}
    </Wrap>
  );
};

InputCont.propTypes = {
  value: PropTypes.string,
  defaultvalue: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  customInputStripeCss: PropTypes.string,
  id: PropTypes.string,
  hideIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  isErrorthere: PropTypes.bool,
  view: PropTypes.string,
  placeholderSlug: PropTypes.string,
  disableShadow: PropTypes.bool,
  t: PropTypes.func,
  customIcon: PropTypes.any,
  iconProps: PropTypes.object,
  isCoverLetter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  field: PropTypes.object,
};

const Wrap = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.general.borderRadius};
  overflow: hidden;
  ${({ disableShadow }) =>
    disableShadow &&
    css`
      box-shadow: none;
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const InputStyle = css`
  ${inputStyle}
  ${overrideInputStyleMixin}
  color: #484870;
  box-shadow: none;
  border-radius: 3px;
  padding: 13px 35px 9px 16px;
  border: 2px solid #e6e6ff;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: ${({ hasIcon, showLinkIcon }) =>
        hasIcon ? (showLinkIcon ? '13px 16px 13px' : '13px 16px 13px 52px') : '13px 16px 13px'};
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      border-radius: 8px;
      flex-direction: row;
      &::placeholder {
        color: ${(p) => p.theme.colors.gray.regular};
      }
    `}
  &:focus {
    border-color: ${({ theme }) => (theme.designV2 ? '#428eff' : '#1688fe')};
  }

  &:focus {
    & ~ div {
      visibility: visible;
      transform: rotateY(0);
    }
  }

  && {
    ${({ error, theme }) =>
      error &&
      css`
        border-color: ${theme.colors.red};
      `};
  }

  :disabled {
    border-color: #e6e6ff;
    cursor: not-allowed;
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      padding: ${({ hasIcon, showLinkIcon }) =>
        hasIcon ? (showLinkIcon ? '13px 16px 13px' : '13px 52px 13px 16px') : '13px 16px 13px'};
    `}
  ${({ error, customInputStripeCss }) => error && customInputStripeCss}
`;

const Input = styled.input`
  ${InputStyle}
`;

export default InputCont;
