import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import IconListModal from '/imports/generator/ui/components/IconListModal';
import { ICON_LOGOS_MAP } from 'imports/checkout/ui/assets/IconData';
import Autosave from '/imports/core/ui/components/Autosave';

const IconPickerButton = (props) => {
  const { value } = props;
  const getIconByTitle = (title) => ICON_LOGOS_MAP.find((icon) => icon.title === title);
  const defaultlogo = ICON_LOGOS_MAP.find((icon) => icon.default);
  const specificIcon = getIconByTitle(value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const buttonRef = useRef(null);
  const iconRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedIcon, setSelectedIcon] = useState(specificIcon);
  const onChangeRef = useRef(null);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setOffsetTop(rect.bottom + window.scrollY + 5);
      isModalOpen === false ? iconRef.current.focus() : iconRef.current.blur();
    }
    setIsModalOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSelectIcon = (icon, onChange) => {
    setSelectedIcon(icon);
    onChange({ target: { value: icon.title } });
    setIsModalOpen(false);
    iconRef.current.blur();
  };

  useEffect(() => {
    if (!value && onChangeRef.current) {
      setSelectedIcon(defaultlogo);
      onChangeRef.current({ target: { value: defaultlogo.title } });
    }
  }, [value]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  return (
    <>
      <Autosave {...props}>
        {({ onChange }) => {
          onChangeRef.current = onChange;
          return (
            <>
              <IconButton ref={buttonRef} onClick={handleClick}>
                <StyledButton tabIndex="0" ref={iconRef}>
                  {selectedIcon ? selectedIcon.iconSVG() : defaultlogo.iconSVG()}
                </StyledButton>
              </IconButton>
              {isModalOpen && (
                <ModalWrapper offsetTop={offsetTop} ref={modalRef}>
                  <IconListModal onClose={handleClose} onSelectIcon={(icon) => handleSelectIcon(icon, onChange)} />
                </ModalWrapper>
              )}
            </>
          );
        }}
      </Autosave>
    </>
  );
};

const IconButton = styled.div`
  position: absolute;
  left: 12px;
  top: 26px;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 12px;
    `}
`;

const StyledButton = styled.button`
  border: 1px solid #c4c4c7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  font-size: 18px;
  color: #14141f;
  background-color: #f7f7fc;
  &:hover {
    color: #1688fe;
  }
  &:focus {
    border-color: #1688fe;
    color: #1688fe;
  }
`;

const ModalWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0px;
  top: 55px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 460px;
  width: 100%;
  max-height: 460px;
  overflow: hidden;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0px;
    `}
`;

export default IconPickerButton;
