import React from 'react';
import { ICON_LOGOS_MAP } from 'imports/checkout/ui/assets/IconData';

const RenderIcon = (props) => {
  const { isShowLinkIcon, linkIcon, isIconSocialActiveVar } = props;
  const getIconByTitle = (title) => ICON_LOGOS_MAP.find((icon) => icon.title === title);
  const defaultlogo = ICON_LOGOS_MAP.find((icon) => icon.default);
  const specificIcon = getIconByTitle(linkIcon);
  if (!isIconSocialActiveVar) return defaultlogo.iconSVG();
  if (isShowLinkIcon) return defaultlogo.iconSVG();
  return specificIcon ? specificIcon.iconSVG() : defaultlogo.iconSVG();
};

export default RenderIcon;
