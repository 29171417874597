import React from 'react';
import styled from 'styled-components';
import useIntl from '/imports/core/api/useIntl';
import { ICON_LOGOS_MAP } from 'imports/checkout/ui/assets/IconData';

const IconListModal = (props) => {
  const { onClose, onSelectIcon, onChange } = props;
  const { t } = useIntl();
  const HeaderTitle = t('generator.emojiModal.title');
  return (
    <ModalContainer>
      <Header>
        <Title>{HeaderTitle}</Title>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Header>
      <IconGrid>
        {ICON_LOGOS_MAP.map((icon, index) => (
          <IconButton key={index} onClick={() => onSelectIcon(icon, onChange)}>
            {icon.iconSVG()}
          </IconButton>
        ))}
      </IconGrid>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
  gap: 18px;
  padding: 0px 10px 24px 16px;
  overflow-y: auto;
  max-height: 500px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const Title = styled.h3`
  font-size: 24px;
  color: #4c4c55;
  font-family: Gilroy SemiBold;
  margin: 0;
`;

const CloseButton = styled.button`
  font-size: 40px;
  background: none;
  border: none;
  cursor: pointer;
  line-height: 30px;
  color: #4c4c55;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: #595964;
  &:hover {
    background-color: #f0f0f0;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

export default IconListModal;
