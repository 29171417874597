import { useEffect, useState } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled from 'styled-components';
import useTracking from 'imports/core/hooks/useTracking';
import WizardLayout from '../layouts/WizardLayout';

import AddBlocks from '/imports/generator/ui/components/AddBlocks';
import BlockTitleAtom from '/imports/generator/ui/atoms/BlockTitle';
import GenericBlock from '/imports/generator/ui/components/GenericBlock';
import useIntl from '/imports/core/api/useIntl';

const OthersWizardForm = ({ designV2, resume, updateImmue }) => {
  const isCustom = [
    'CUSTOM_SKILLS_CATEGORY',
    'CUSTOM',
    'COURSES',
    'LANGUAGES',
    'INTERNSHIPS',
    'REFERENCES',
    'EXTRA_CURRICULAR',
    'HOBBIES',
    'DRIVING_LICENSE',
    'PERSONAL_DETAILS',
    'PUBLICATIONS',
  ];
  const [blocks, setBlocks] = useState([]);
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('other_view');
  }, []);

  useEffect(() => {
    setBlocks(resume.blocks.filter((block) => isCustom.includes(block.type)));
  }, [resume]);

  return (
    <>
      {blocks.map((block) => (
        <GenericBlock key={block.id} block={block} resumeId={resume.id} source={resume} updateImmue={updateImmue} />
      ))}
    </>
  );
};

export default function WizardOther(props) {
  const { t } = useIntl();
  const { resume, updateImmue } = props;

  return (
    <WizardLayout resume={resume} updateImmue={updateImmue}>
      <OthersWizardForm {...props} />
      <AddBlocksCont>
        <BlockTitle>{t('generator.final.add_blocks')}</BlockTitle>
        <AddBlocks resume={resume} updateImmue={updateImmue} />
      </AddBlocksCont>
    </WizardLayout>
  );
}

const AddBlocksCont = styled.div`
  margin-top: 40px;
  ${({ theme }) =>
    theme.max('xs')`
    padding: 0 5px;
  `}
`;

const BlockTitle = styled(BlockTitleAtom)`
  display: flex;
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
`;
