import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { isWithCareerABTest } from 'imports/carrer/api/helper';
import { getCountry } from 'imports/checkout/api/utils';
import intlHook from 'imports/core/api/useIntl';

export default function ResumeCareerLead(props) {
  const { locale } = intlHook();
  const country = getCountry();
  if (!isWithCareerABTest(locale, country)) return <>{props.children}</>;
  marvelEmitter.defineVariants('exp_lead_generation_v2', ['control', 'with_career_lead'], [70, 30]);
  return (
    <MarvelExperiment name="exp_lead_generation_v2">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_career_lead">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
}
