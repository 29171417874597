import { Loader } from 'imports/generator/ui/atoms/Loader';
import { LoaderWrapper, Subtitle } from '/imports/generator/ui/components/translateResume/Styles';
import intlHook from 'imports/core/api/useIntl';

const ProcessTranslationStep = ({ processRefCount }) => {
  const { t } = intlHook();
  return (
    <LoaderWrapper direction="column" justifyContent="center" alignItems="center" height="100%">
      <Loader />
      <Subtitle style={{ fontSize: 18 }}>
        {processRefCount < 7
          ? t('translate_cv.translate.processing')
          : processRefCount < 35
            ? t('translate_cv.cv_content_bigger')
            : t('translate_cv.translate_might_failed')}
      </Subtitle>
    </LoaderWrapper>
  );
};
export default ProcessTranslationStep;
