import React from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import { RESUME_ONBOARDING_STEPS } from '/imports/generator/api/onboarding';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import useIntl from '/imports/core/api/useIntl';
import { isWithCareerLead } from '/imports/carrer/api/helper';
import { getCountry } from 'imports/checkout/api/utils';

const WizardHeaderSteps = ({
  isMobile,
  currentStep,
  currentStepIndex,
  currentProgressIndex,
  onBack,
  goTo: jump,
  finalize,
  isLinkedin = false,
  handleBack,
  popshown,
  show,
  isLastTab,
}) => {
  const { t, locale } = useIntl();
  const country = getCountry();
  const goTo = (step) => {
    if (finalize) return null;
    jump(step);
  };
  const withNewFlow =
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
    isWithCareerLead(locale, country) ||
    ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));

  const isNewFlow = withNewFlow && !popshown && show;

  return (
    <Header>
      <Stripe
        length={RESUME_ONBOARDING_STEPS.length}
        current={currentProgressIndex}
        finalize={finalize}
        isLinkedin={isLinkedin}
      />
      {isMobile ? (
        <HeadWrapper isLinkedin={isLinkedin}>
          <HeaderMobileNav>
            <MobileBack onClick={isNewFlow ? handleBack : onBack} hide={!currentStepIndex}>
              <SvgIcon.BackMobile height="16px" viewBox="0 0 24 16" />
            </MobileBack>
            <WizardLabel>
              {t(RESUME_ONBOARDING_STEPS[currentStepIndex]?.translationSlug)}
              <Dotted>
                {RESUME_ONBOARDING_STEPS.map((el, i) => (
                  <BumperRadio key={i} isPassed={i <= currentProgressIndex} />
                ))}
              </Dotted>
            </WizardLabel>
          </HeaderMobileNav>
        </HeadWrapper>
      ) : (
        RESUME_ONBOARDING_STEPS.map((step, i) => (
          <HeaderItem
            key={`step-${i}`}
            isPassed={i <= currentProgressIndex}
            current={step.status === currentStep}
            length={RESUME_ONBOARDING_STEPS.length}
            finalize={finalize}
            isLast={i === RESUME_ONBOARDING_STEPS.length - 1}
            isFirst={i === 0}
            isLinkedin={isLinkedin}
          >
            <HeaderItemTitle
              isPassed={i <= currentProgressIndex}
              onClick={() => goTo(step.status)}
              current={step.status === currentStep}
              isDone={currentStepIndex > i}
              finalize={finalize}
              isLast={i === RESUME_ONBOARDING_STEPS.length - 1}
            >
              {locale === 'ka' ? t(step.translationSlug) : t(step.translationSlug).toLocaleUpperCase(locale)}
            </HeaderItemTitle>
            <Bullet
              isDone={currentStepIndex > i}
              isPassed={i <= currentProgressIndex}
              onClick={() => goTo(step.status)}
            />
          </HeaderItem>
        ))
      )}
    </Header>
  );
};

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  text-align: center;
  border-radius: 50%;
  background: var(--light-values-white);
  border: solid 2px ${({ isPassed }) => (isPassed ? '#1688fe' : '#e6e6ff')};
`;

const Header = styled.header`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
  margin-top: 20px;
  z-index: 10;
  align-items: flex-end;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const HeaderItem = styled.div`
  position: relative;
  width: calc(100% / ${(p) => p.length});
  font-size: 12px;
  > div {
    cursor: ${({ isPassed, finalize }) => (isPassed && !finalize ? 'pointer' : 'default')};
  }
  ${({ isLinkedin }) =>
    isLinkedin &&
    css`
      display: none !important;
    `}
`;

const HeaderItemTitle = styled.div`
  margin-bottom: 9px;
  word-break: auto-phrase;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  text-align: center;
  color: ${({ isPassed }) => (isPassed ? '#1688fe' : '#7171a6')};
  cursor: ${(p) => (p.isPassed ? 'pointer' : 'default')};
  ${({ finalize, isLast, theme }) =>
    finalize &&
    !isLast &&
    css`
      opacity: 0.5;
    `}
`;

const Stripe = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background-color: #e6e6ff;
  position: absolute;
  left: 0;
  bottom: 3.5px;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    width: calc(100% / (${(p) => p.length} * 2) * (${(p) => p.current} * 2 + 1));
    border-radius: 100px;
    background-color: #1688fe;

    ${({ finalize }) =>
      finalize &&
      css`
        width: 100%;
      `}

    ${({ theme }) =>
      theme.isRTL &&
      css`
        right: 0;
        left: auto;
      `}
  }
  ${({ isLinkedin }) =>
    isLinkedin &&
    css`
      display: none !important;
    `}
  ${({
    theme: {
      max,
      colors: { secondary },
    },
  }) => max('xs')`
    display: none;
  `}
`;

const HeaderMobileNav = styled((p) => <Flex alignItems="center" {...p} />)`
  width: 100%;
  color: #3d4047;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  padding-bottom: 10px;
  > div {
    flex-grow: 1;
    text-align: center;
  }
  ${({ theme }) => theme.max('md')`
    margin: 0 32px;
  `}
  ${(p) => p.theme.max('sm')`
    margin: 0 20px;
  `}
  ${({ theme }) =>
    theme.max('xs')`
    margin: 0 11px;
  `};
`;

const MobileBack = styled.button`
  font-weight: normal;
  text-transform: none;
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 0;
  border: none;
  padding: 0;
  background: transparent;
  transform: rotateZ(180deg);
  color: ${(p) => p.theme.colors.primary};
  cursor: pointer;
  ${({ theme: { isRTL, max } }) =>
    isRTL &&
    css`
      left: auto;
      right: 0;
      svg {
        transform: rotate(180deg);
      }
    `}
  ${(p) =>
    p.hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  ${({ theme: { max } }) =>
    max('xs')`
    color: #1688fe;
  `}
`;

const WizardLabel = styled.div`
  ${({ theme: { max } }) =>
    max('xs')`
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      font-size: 13px;
      color: #1688fe;
      width: 100%;
    `}
`;

const Dotted = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-top: 3px;
  ${({ theme: { min } }) => min('xs')`
    display: none;
  `}

  > div {
    margin: 0 2px;
    &:nth-child(1) {
      margin-left: auto;
    }
    &:nth-last-child(1) {
      margin-right: auto;
    }
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      justify-content: center;
      > div {
        &:nth-child(1) {
          margin-left: unset;
        }
        &:nth-last-child(1) {
          margin-right: unset;
        }
      }
    `}

  ${({ theme: { isRTL } }) => !isRTL && css``}
`;

const BumperRadio = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1.6px #e6e6ff;
  ${({ isPassed }) =>
    isPassed &&
    css`
      border-color: #1688fe;
    `}
`;

const HeadWrapper = styled.div`
  width: 100%;
  ${({ theme: { max } }) =>
    max('xs')`
    display: flex;
    flex-wrap: wrap;
  `}
  ${({ isLinkedin }) =>
    isLinkedin &&
    css`
      display: none !important;
    `}
`;

export default WizardHeaderSteps;
