import React, { PureComponent, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import nookies from 'nookies';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import shortid from 'shortid';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import { ValidatorForm } from 'react-form-validator-core';
import { withRouter } from 'next/router';

import { ADD_BLOCK_ITEM } from '/imports/generator/api/apollo/client/mutations';
import FormSummary from '/imports/core/ui/atoms/FormSummary';
import Tab2 from '/imports/carrer/components/Tab2';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { useGlobalState, withGlobalContext } from 'imports/core/api/global.context';
import GenericBlock from '/imports/generator/ui/components/GenericBlock';
import { updateBlocksAfterItemAdd } from '/imports/generator/api/apollo/client/helpers';
import useTracking from '/imports/core/hooks/useTracking';
import { updateUser } from '/imports/carrer/api/api';
import { withGeneralContext } from '/imports/core/api/generalContext';
import intlHook, { withIntl } from '/imports/core/api/useIntl';
import { useResponsive, withResponsiveContext } from '/imports/core/api/responsiveContext';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';
import WizardSummaryIntro from '/imports/generator/ui/components/WizardSummaryIntro';
import SummerySuggestion from 'imports/generator/ui/components/SummerySuggestion';
import { withBlock } from 'imports/generator/context/blockresposition.context';
import { isWithCareerLead } from 'imports/carrer/api/helper';
import { getCountry } from 'imports/checkout/api/utils';
import { removeExperiment } from 'lib/helpers';

const SummaryWizardForm = ({ t, getLastJob, block, refernceBlock, show, handleClose, ...rest }) => {
  const { resume, updateImmue, designV2, referenceResume } = rest;
  const { fromScan } = resume;
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const { locale } = intlHook();
  const country = getCountry();
  const withNewFlow =
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
    isWithCareerLead(locale, country) ||
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
    ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
  const expBlock = resume?.blocks.find((b) => b.type === 'EMPLOYMENT');
  const isExperienceAvailable = expBlock?.items?.length > 0 && expBlock?.items?.length !== undefined;
  const storedResumeIds = JSON.parse(localStorage.getItem('resumeIDs') || '[]');
  const showSummary = !storedResumeIds.includes(resume.id);
  const {
    state: { isDataSelected },
  } = withBlock();

  useEffect(() => {
    let obj = {};

    if (marvelEmitter.getActiveVariant('exp_resumes_writing')) {
      obj.exp_resumes_writing = marvelEmitter.getActiveVariant('exp_resumes_writing');
    }
    if (marvelEmitter.getActiveVariant('exp_writing_feature_pricing')) {
      obj.exp_writing_feature_pricing = marvelEmitter.getActiveVariant('exp_writing_feature_pricing');
    }
    trackEvent('summary_view', obj);
    removeExperiment('exp_undo_redo_textarea_editor');
  }, []);

  const hideModal = () => handleClose();

  if (withNewFlow && show) return <RenderCareerAbtest hideModal={hideModal} />;
  return (
    <Fragment>
      <WizardHeader designV2={designV2}>
        <div dangerouslySetInnerHTML={{ __html: t('generator.summary.form_title') }} />
      </WizardHeader>
      <WizardSubHeader marginLess fromScan={fromScan}>
        {t('generator.summary.form_subtitle')}
      </WizardSubHeader>
      <FormSummary>
        <ValidatorForm onSubmit={() => {}}>
          <Wrap>
            <GenericBlock
              block={block}
              resumeId={resume.id}
              source={resume}
              refernceBlock={refernceBlock}
              referenceResume={referenceResume}
              lastJob={getLastJob()}
              fullWidth
              noTitle
              updateImmue={updateImmue}
            />
            {isMobile && !isDataSelected && isExperienceAvailable && showSummary && (
              <SummerySuggestion
                block={block}
                resume={resume}
                source={resume}
                refernceBlock={refernceBlock}
                referenceResume={referenceResume}
                updateImmue={updateImmue}
              />
            )}
          </Wrap>
        </ValidatorForm>
      </FormSummary>
    </Fragment>
  );
};

@withIntl
@withRouter
@withAccount
@withGlobalContext
@withResponsiveContext
@withGeneralContext
@graphql(ADD_BLOCK_ITEM, { name: 'addBlockItem' })
class WizardSummary extends PureComponent {
  static propTypes = {
    resume: PropTypes.object,
    intro: PropTypes.string,
  };

  state = {
    show: false,
    popshown: false,
  };

  get block() {
    const {
      resume: { blocks },
    } = this.props;

    return blocks.find((block) => block.type === 'PROFESSIONAL_SUMMARY');
  }

  componentDidMount() {
    const {
      currentUser,
      context: {
        state: { userDisqualify },
      },
    } = this.props;
    const stateFlag = localStorage.getItem('flag');
    const sixStep = stateFlag && JSON.parse(stateFlag);
    if (!sixStep?.sixStep && (userDisqualify ? !userDisqualify : !currentUser.disableCarrerQuiz)) {
      this.setState({
        show: true,
      });
    }
    if (this.block.items && this.block.items.length > 0) return;
    this.addBlockItem();
  }

  handleClose = () => {
    this.setState({
      show: false,
      popshown: true,
    });
    localStorage.setItem(
      'flag',
      JSON.stringify({ firstStep: 'true', secondStep: 'true', fourthStep: 'true', fifthStep: 'true', sixStep: 'true' }),
    );
  };
  addBlockItem = async () => {
    const {
      addBlockItem,
      resume: { id: resumeId },
      updateImmue,
    } = this.props;
    const animationKey = shortid.generate();

    addBlockItem({
      variables: { resumeId, blockId: this.block.id, animationKey },
      update: (cache, res) => updateBlocksAfterItemAdd(updateImmue)(res),
    });
  };

  getLastJob = () => {
    const {
      resume: {
        blocks,
        details: { title },
      },
    } = this.props;

    if (title) return title;

    const employment = blocks.find((block) => block.type === 'EMPLOYMENT');

    if (!employment) return null;

    return (
      (employment.items &&
        employment.items.length > 0 &&
        employment.items[0].fields &&
        employment.items[0].fields.title) ||
      null
    );
  };

  render() {
    const { resume, intro, t, designV2, referenceResume, updateImmue } = this.props;
    const refernceBlock = referenceResume && referenceResume.blocks.find((block) => block.type === 'EDUCATION');
    if (intro && intro === 'intro') {
      return (
        <WizardLayout resume={resume}>
          <WizardSummaryIntro />
        </WizardLayout>
      );
    }

    return (
      <WizardLayout
        resume={resume}
        updateImmue={updateImmue}
        handleClose={this.handleClose}
        show={this.state.show}
        popshown={this.state.popshown}
      >
        <SummaryWizardForm
          getLastJob={this.getLastJob}
          block={this.block}
          {...this.props}
          designV2={designV2}
          refernceBlock={refernceBlock}
          handleClose={this.handleClose}
          show={this.state.show}
        />
      </WizardLayout>
    );
  }
}

const Wrap = styled.div`
  position: relative;
  ${({ theme }) =>
    theme.max('xs')`
      margin-top:30px;
    `}
`;

WizardSummary.displayName = 'WizardSummary';

const RenderCareerAbtest = ({ hideModal }) => {
  const { trackEvent } = useTracking();
  const { token } = nookies.get({});
  const { t } = intlHook();
  const { handleUpdateState } = useGlobalState();
  const [field, setField] = useState({ currentCareerStatus: '' });

  const handleData = async (key, value) => {
    const resp = await updateUser({ [key]: value }, token);
    handleUpdateState('userData', resp);
  };
  const handleClick = (key, value) => () => {
    setField({ ...field, [key]: value });
    trackEvent(key, {
      [key]: value,
    });
    handleData(key, value);
    if (key === 'currentCareerStatus' && value === 'Finished (or finishing) education') {
      trackEvent('user_disqualify', { quiz: 'currentCareerStatus' });
      handleUpdateState('userDisqualify', true);
      handleData('disableCarrerQuiz', true);
    }
    hideModal();
  };

  return (
    <Wrapper>
      <StyledWizardHeader>
        <div>{t('career_question_4')}</div>
      </StyledWizardHeader>
      <Tab2 field={field} handleClick={handleClick} />
    </Wrapper>
  );
};

const StyledWizardHeader = styled(WizardHeader)`
  margin-bottom: 36px;
`;

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

export default WizardSummary;
