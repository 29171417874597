import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownAutoSaveForFinalize from 'imports/core/ui/atoms/DropdownAutoSaveForFinalize';
import { useSelectStore } from 'zustand/SelectStore';
import useIntl from '/imports/core/api/useIntl';
import { blockDetailImmutableUpdate } from '/imports/generator/api/apollo/client/helpers';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { SA_RESUME_SETTINGS } from '/imports/pdf/core/api/constants';

const ResumeSettingsDropdown = ({ path, source, updateImmue }) => {
  const { t } = useIntl();
  const {
    id,
    saDetails,
    details: { userPic },
  } = source;
  const { label_slug, options, defaultOption } = SA_RESUME_SETTINGS[path];
  let filteredOptions = options;
  const label = t(label_slug);
  const selected = saDetails?.[path] || defaultOption;
  if (path === 'mainColumnSize') {
    const showNoneOption = !userPic;
    if (!showNoneOption) filteredOptions = filteredOptions.filter((option) => option.value !== 'none');
  }
  const { expanded } = useSelectStore();
  return (
    <Wrap>
      <FieldLabel summary label={label} />
      <DropdownAutoSaveForFinalize
        source={source}
        mutation={UPDATE_RESUME_DETAIL}
        variables={{
          docId: id,
          path: `saDetails.${path}`,
          needUpdate: true,
        }}
        docType="resume"
        isExpanded={expanded === path}
        selected={selected}
        isControlled={true}
        options={filteredOptions}
        name={path}
        optimisticResponse={blockDetailImmutableUpdate(updateImmue)(id, `saDetails.${path}`)}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
`;

ResumeSettingsDropdown.propTypes = {
  source: PropTypes.object,
  font: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ResumeSettingsDropdown;
