import Button from './Button';
import Flex from './Flex';
import HeadingAtom from './Heading';
import TextAtom from './Text';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { CloseIcon } from '/imports/generator/ui/assets';
import intlHook from '/imports/core/api/useIntl';
import Spinner from 'imports/generator/ui/atoms/Spinner';

const Confirm = (props) => {
  const { t } = intlHook();
  const [element, setElement] = useState();
  const {
    className,
    confirmText,
    cancelText,
    onCancel,
    onConfirm,
    title,
    text,
    onClose,
    isCanceling,
    cancellingText,
    isConfirming,
    confirmingText,
    showClose = false,
    invertedButtonStyle = false,
  } = props;
  useEffect(() => {
    const els = document.getElementById('modal');
    setElement(els);
  }, []);

  const handleModalBackdropClick = () => {
    if (isConfirming) return;
    onClose();
  };

  if (!element) return <p>Error creating modal</p>;
  return ReactDOM.createPortal(
    <Fragment>
      <ModalBackdrop onClick={handleModalBackdropClick}></ModalBackdrop>
      <CSSTransition appear in classNames="modal" timeout={600}>
        <ModalContainer className={className}>
          {showClose && (
            <HeaderButton onClick={onClose}>
              {t('cancel')}
              <CloseIcon color="#0199db" />
            </HeaderButton>
          )}
          {title && <Heading>{title}</Heading>}
          {text && <Text>{text}</Text>}
          <ButtonsFlex>
            {invertedButtonStyle ? (
              <>
                <ModalButton onClick={onCancel} outline disabled={isCanceling || isConfirming}>
                  {isCanceling ? cancellingText || t('cancelling') : cancelText || t('cancel')}
                </ModalButton>
                <ModalButton onClick={onConfirm} disabled={isConfirming} cancel>
                  {isConfirming ? (
                    <Flex $direction={'row'} style={{ gap: 8 }}>
                      <Spinner style={{ width: 18, height: 18, borderColor: '#FFF', borderTopColor: '#7171a6' }} />
                      {confirmingText || t('confirming')}
                    </Flex>
                  ) : (
                    confirmText || t('confirm')
                  )}
                </ModalButton>
              </>
            ) : (
              <>
                <ModalButton outline onClick={onConfirm} disabled={isConfirming}>
                  {isConfirming ? confirmingText || t('confirming') : confirmText || t('confirm')}
                </ModalButton>
                <ModalButton onClick={onCancel} cancel disabled={isCanceling || isConfirming}>
                  {isCanceling ? cancellingText || t('cancelling') : cancelText || t('cancel')}
                </ModalButton>
              </>
            )}
          </ButtonsFlex>
        </ModalContainer>
      </CSSTransition>
    </Fragment>,
    document.getElementById('modal'),
  );
};

Confirm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  isCanceling: PropTypes.bool,
  cancellingText: PropTypes.string,
  isConfirming: PropTypes.bool,
  confirmingText: PropTypes.string,
  showClose: PropTypes.bool,
  t: PropTypes.func,
};

const ModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndex.confirmBg};
  background: ${rgba('#343c49', 0.8)};
  ${({ theme }) =>
    theme.max('xs')`
    background: rgba(52, 60, 73, 0.5);
  `}
`;
const Heading = styled((p) => <HeadingAtom as="h2" {...p} />)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 24px;
  font-weight: normal;
  color: #33334f;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1.2;
  ${({ theme }) =>
    theme.max('xs')`
    font-size: 20px;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Text = styled(TextAtom)`
  margin-top: 0;
  margin-bottom: 50px;
  line-height: 1.5;
  font-size: 18px;
  color: #7171a6;
  ${({ theme }) =>
    theme.max('xs')`
      font-size: 14px;
      line-height: normal;
      margin-bottom: 25px;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ButtonsFlex = styled(Flex)`
  justify-content: flex-end;
  align-items: center;

  ${(p) => p.theme.max('md')`
    justify-content: center;
    > button:first-of-type {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.max('xs')`
    flex-direction: column;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ModalContainer = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 36px;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(p) => p.theme.zIndex.confirm};

  ${(p) => p.theme.min('md')`
    width: 540px;
  `}
  ${(p) => p.theme.max('md')`
    width: 60vw;
  `}
  ${(p) => p.theme.max('sm')`
    width: 94vw;
  `}
  
  &.modal-appear {
    transform: translate(-50%, -150%);
  }

  &.modal-appear-active {
    transform: translate(-50%, -50%);
  }

  &.modal-exit {
    transform: translate(-50%, -50%);
  }

  &.modal-exit-active {
    transform: translate(-50%, -150%);
  }
`;

const ModalButton = styled(Button)`
  padding: 0.7em 1.5em;
  margin-left: 10px;
  color: #7171a6;
  border: solid 2px #e6e6ff;

  ${({ theme }) =>
    theme.max('xs')`
      padding: 0.7em 2.5em;
      width: 100%;
      margin: 0;
      &:first-child {
        margin-bottom: 10px;
      }
    `}

  ${({ cancel }) =>
    cancel &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteBold};
      font-weight: normal;
      border: 1px solid #0087c1;
      background: #0087c1;
      color: #fff;
      transition: all 0.2s;
      &:hover {
        border-color: #1a5885;
        background: #1a5885;
        color: #fff;
      }
      &:disabled {
        border-color: ${(p) => p.theme.colors.gray.light} !important;
        background-color: ${(p) => p.theme.colors.gray.light} !important;
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
      }
    `};

  &:last-child {
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        margin-left: 0;
      `}
  }
`;

const HeaderButton = styled.a`
  font-family: TTCommons;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #0199db;
  cursor: pointer;
  margin-left: auto;
  letter-spacing: -0.5px;
  position: fixed;
  top: 12px;
  right: 16px;

  svg {
    transform: scale(1);
    margin-left: 10px;
    top: 2px;
    position: relative;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      left: 16px;
      right: auto;

      svg {
        margin-left: 0;
        margin-right: 10px;
      }
    `}
`;

export default Confirm;
