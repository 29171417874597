import styled from 'styled-components';

const AddressIcon = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.97765 7.1669C7.33684 7.1669 6.63195 6.87053 6.27951 6.0535C6.23145 5.92534 6.23145 5.661 6.23145 5.63697V5.26851C6.23145 4.22719 7.19266 3.7546 8.02571 3.7546C9.05902 3.7546 9.85202 4.50755 9.85202 5.46075C9.85202 6.41395 9.00295 7.15889 7.96964 7.15889M12.7517 4.30729C12.6956 4.13107 12.5995 3.93883 12.5194 3.77863C11.5982 1.74406 9.57167 1.01514 7.9376 1.01514C5.74283 1.01514 3.33179 2.36084 3.01138 5.14035V5.70906C3.01138 5.70906 3.01939 5.94136 3.03541 6.0535C3.21965 7.37517 4.35708 8.78495 5.20615 10.1066C6.11931 11.5244 7.07251 12.9182 8.00969 14.3119C8.59443 13.3988 9.17116 12.4776 9.73187 11.5885C9.88406 11.3322 10.0683 11.0678 10.2205 10.8195C10.3246 10.6513 10.5249 10.4911 10.613 10.3309C11.5261 8.79296 13 7.24701 13 5.72508V5.10029C13 4.93208 12.7757 4.35535 12.7677 4.32331"
      fill="#A7905D"
    />
  </SvgIcon>
);

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(24)};
  height: ${({ theme }) => theme.convertPx(24)};
`;

export default AddressIcon;
