import styled, { css } from 'styled-components';

import AddressIcon from '/imports/pdf/budapest/ui/assets/AddressIcon';
import EmailIcon from '/imports/pdf/budapest/ui/assets/EmailIcon';
import PhoneIcon from '/imports/pdf/budapest/ui/assets/PhoneIcon';
import SocialLinkIcon from '/imports/pdf/budapest/ui/assets/SocialLinkIcon';
import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import { formatUrl, getClickToEditProps } from '/imports/pdf/core/api/helpers';
import { DETAILS_PROPTYPES_SCHEMA } from '/imports/pdf/core/api/schema';
import View from '/imports/pdf/core/ui/atoms/View';
import EditRedirectionWrapper from '/imports/pdf/core/ui/components/EditRedirectionWrapper';
import LinkAtom from '/imports/pdf/budapest/ui/atoms/Link';
import StyledLinkIcon from 'imports/pdf/budapest/ui/assets/StyledLinkIcon';

const ORDERED_DETAILS_FIELDS = ['address', 'city', 'postalCode', 'country'];

const Details = ({ details, details: { phone, email }, blocks, isSocialActiveVar, isIconSocialActiveVar }) => {
  const isSocialBlock = blocks?.find((j) => j?.type === 'SOCIAL_LINKS');
  const blockWithHeader = isSocialBlock && isSocialBlock?.items?.find((item) => item?.fields?.showOnHeader === true);
  const URLLink = blockWithHeader?.fields?.url;
  const isShowHeaderLink = blockWithHeader && !!isSocialActiveVar;
  const linkIcon = blockWithHeader?.fields?.linkIcon;
  const isShowLinkIcon = blockWithHeader?.fields?.showLinkIcon;
  const fields = ORDERED_DETAILS_FIELDS.filter((f) => details[f]);
  if (fields.length === 0 && !phone && !email && !isShowHeaderLink) return null;
  return (
    <DetailsCont>
      {fields.length > 0 && (
        <EditRedirectionWrapper
          {...getClickToEditProps(
            null,
            fields.reduce((acc, field) => {
              acc[field] = details[field];
              return acc;
            }, {}),
          )}
        >
          <DetailsItem lastChild={!phone && !email}>
            <AddressIcon />
            <Text>{fields.map((f) => details[f]).join(', ')}</Text>
          </DetailsItem>
        </EditRedirectionWrapper>
      )}
      {phone && (
        <EditRedirectionWrapper {...getClickToEditProps(null, { phone })}>
          <DetailsItem lastChild={!email}>
            <PhoneIcon />
            <Text>{phone}</Text>
          </DetailsItem>
        </EditRedirectionWrapper>
      )}
      {email && (
        <EditRedirectionWrapper {...getClickToEditProps(null, { email })}>
          <DetailsItem lastChild={!isShowHeaderLink}>
            <EmailIcon />
            <Text>{email}</Text>
          </DetailsItem>
        </EditRedirectionWrapper>
      )}
      {isShowHeaderLink && (
        <EditRedirectionWrapper
          {...getClickToEditProps(blockWithHeader?.id, { URLLink }, { title: 'label', URLLink: 'url' })}
          isDragDisabled
        >
          <DetailsItem lastChild>
            <StyledLinkIcon
              isShowLinkIcon={isShowLinkIcon}
              linkIcon={linkIcon}
              isIconSocialActiveVar={isIconSocialActiveVar}
            />
            <TextURL
              href={formatUrl(URLLink)}
              target="_blank"
              rel="noopener noreferrer"
              $noRedirection={typeof window !== 'undefined'}
            >
              {URLLink}
            </TextURL>
          </DetailsItem>
        </EditRedirectionWrapper>
      )}
    </DetailsCont>
  );
};

Details.propTypes = DETAILS_PROPTYPES_SCHEMA;

const DetailsCont = styled(View)`
  width: 42%;
`;

const IconContainer = styled(View)`
  display: flex;
  ${({ theme: { convertPx, isRTL, colors, setGenderStyle, color }, isLocation, isPhone }) => css`
    margin-right: ${convertPx(isRTL ? 0 : 5)};
    margin-left: ${convertPx(isRTL ? 5 : 0)};
    ${
      isRTL &&
      css`
        margin-left: ${convertPx(5)};
        margin-right: 0;
      `
    }
    height: ${convertPx(24)};
    width: ${convertPx(24)};
    background-color: #414142;
    justify-content:center
    align-items: center;
    svg {
      margin: auto;
    }
    path {
      fill: var(--light-values-white);
    }
    ${
      color &&
      color === 'black' &&
      setGenderStyle('female') &&
      `
      background-color: transparent;
      path {
        fill: #a28848;
      }
    `
    }
    ${
      color &&
      color !== 'black' &&
      css`
        background-color: ${colors[color]};
      `
    }
    border-radius: 50%;
  `}
`;

const Text = styled(TextAtom)`
  font-size: ${({ theme }) => theme.convertPx(10)};
  color: #76777a;
  position: relative;
  width: 85%;
  line-height: normal;
`;

const TextURL = styled(LinkAtom)`
  display: flex;
  font-size: ${({ theme }) => theme.convertPx(10)};
  color: #76777a;
  position: relative;
  width: 85%;
  line-height: normal;
  word-break: break-word;

  ${({ $noRedirection }) =>
    $noRedirection &&
    css`
      pointer-events: none;
    `}
`;

const DetailsItem = styled(View)`
  display: flex;
  flex-direction: row;
  ${({ theme: { convertPx }, lastChild }) => css`
    margin-bottom: ${convertPx(5)};
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      bottom: ${convertPx(-2.5)};
      right: ${convertPx(0)};
      height: ${convertPx(1)};
      width: 85%;
      background-color: #e6e7e8;
    }
    ${lastChild &&
    css`
      margin-bottom: 0;
      &:after {
        background-color: transparent;
      }
    `}
  `}
`;

export default Details;
