import styled, { keyframes } from 'styled-components';

// Define the animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Spinner component with dynamic width and height
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #1688fe;
  border-radius: 50%;
  width: ${({ $width }) => $width || '40px'};
  height: ${({ $width }) => $width || '40px'}; // height is based on width
  animation: ${spin} 1s linear infinite;
`;

export default Spinner;
