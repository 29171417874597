import IconPickerButton from '/imports/generator/ui/components/IconPickerButton';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import nookies from 'nookies';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import useIntl from 'imports/core/api/useIntl';
import InputCheckboxAutoSave from 'imports/core/ui/atoms/InputCheckBoxAutoSave';
import SocialLinkCheckboxAutoSave from 'imports/core/ui/atoms/SocialLinkCheckboxAutoSave';
import { blockItemImmutableUpdate, blockItemImmutableUpdateSwitch } from 'imports/generator/api/apollo/client/helpers';
import {
  UPDATE_BLOCK_FIELD,
  UPDATE_BLOCK_ITEM_FIELD,
  UPDATE_COVER_LETTER_DETAIL,
} from 'imports/generator/api/apollo/client/mutations';
import { getGender } from 'lib/helpers';
import { useMutation } from 'react-apollo';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import GeneratorValidatedInput from '/imports/core/ui/atoms/GeneratorValidatedInput';
import { getInputName } from '/imports/generator/api/helpers';
import MailCheck from '/imports/generator/ui/components/MailCheck';

const GeneratorInputWrap = (props) => {
  const {
    value,
    validators,
    placeholderSlug,
    placeholder,
    suggestion = false,
    variables,
    showCheckbox,
    resume,
    type,
    updateImmue,
    label,
    name,
    isCoverLetter,
    coverLetter,
    lang,
    showOnHeaderCheckbox,
    translationSlugAdditionalFlags,
    translationSlugShowLinkIcon,
    hasIcon,
    showLinkIconCheckbox,
  } = props;

  const {
    trigger,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const { currentUser } = useAccount();
  const { agent_client_id } = nookies.get({});
  const { t } = useIntl();
  const [showSuggestion, setShowSuggestion] = useState(suggestion);
  const fieldName = variables?.field || props?.name;
  const inputName = getInputName(fieldName, variables);
  const oldValueRef = useRef(null);
  const activeBlockCheckbox = resume && resume?.blocks?.find((blocks) => blocks?.type === type);
  const isSocialBlock = resume && resume?.blocks?.find((j) => j?.type === 'SOCIAL_LINKS');
  const itemID = variables?.itemId;
  const matchedItem = isSocialBlock?.items?.find((item) => item?.id === itemID);
  const showOnHeaderFieldValue = matchedItem?.fields?.showOnHeader;
  const linkIconValue = matchedItem?.fields?.linkIcon;
  const showLinkIcon = matchedItem?.fields?.showLinkIcon;
  const [updateCoverLetter] = useMutation(UPDATE_COVER_LETTER_DETAIL);
  const isAgentOrAdmin =
    (currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin')) ||
    (agent_client_id !== undefined && agent_client_id !== null);
  useEffect(() => {
    const updateValue = async () => {
      if (value?.trim() !== '' && value !== oldValueRef.current) {
        setValue(inputName, value);
        oldValueRef.current = value;
        await trigger(inputName);
      }
    };
    updateValue();
  }, [value, inputName, setValue, trigger]);

  const handleBlur = (e) => {
    if (!errors[inputName]) {
      setShowSuggestion(true);
    }
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  useEffect(() => {
    if (isCoverLetter && fieldName === 'firstName' && coverLetter.details.userGender === null) {
      debouncedUpdateGenderCoverLetter(value);
    }
  }, []);

  const debouncedUpdateGenderCoverLetter = useCallback(
    debounce(async (value) => {
      const resp = await getGender(value);
      if (resp) {
        const options = {
          variables: {
            docId: variables.docId,
            path: 'details.userGender',
            value: resp,
          },
          context: {
            client: 'coverLetter',
          },
        };
        updateCoverLetter(options);
      }
    }, 500),
    [updateCoverLetter, variables.docId],
  );

  const handleChange = async (e) => {
    const isValid = await trigger(inputName);
    setValue(inputName, e.target.value);
    if (isValid || (!isValid && props.alwaysSave === true)) {
      props.onSave(e);
    }
    if (isCoverLetter && name === 'firstName') {
      debouncedUpdateGenderCoverLetter(e.target.value);
    }
  };

  const handleFocus = () => {
    setShowSuggestion(false);
    if (props.onFocus && typeof props.onFocus === 'function') {
      props.onFocus();
    }
  };

  const changeEmail = (data) => () => {
    setValue(inputName, data.full);
    props.onSave({ target: { value: data.full } });
  };

  const handleError = () => {
    setShowSuggestion(false);
  };

  return (
    <Controller
      control={control}
      rules={validators || {}}
      name={inputName}
      render={({ field }) => (
        <GeneratorValidatedInput
          {...props}
          field={field}
          errors={errors}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
          suggested={suggestion}
          errorCallback={handleError}
          showLinkIcon={showLinkIcon}
        >
          {hasIcon && !showLinkIcon && (
            <IconPickerButton
              key={`linkIcon-${name[0]}`}
              mutation={UPDATE_BLOCK_ITEM_FIELD}
              variables={{
                ...variables,
                docId: resume?.id,
                blockId: isSocialBlock.id,
                itemId: variables?.itemId,
                field: name[0],
              }}
              name={name[0]}
              value={linkIconValue}
              resume={resume}
              optimisticResponse={blockItemImmutableUpdate(updateImmue)(
                resume?.id,
                isSocialBlock.id,
                variables.itemId,
                name[0],
              )}
            />
          )}
          {suggestion && (!currentUser || !currentUser.email) && showSuggestion && (
            <MailCheck email={value} onChange={changeEmail} />
          )}
          {hasIcon && showLinkIconCheckbox && (
            <InputCheckboxAutoSave
              key={`showlinkIcon-${name[1]}`}
              mutation={UPDATE_BLOCK_ITEM_FIELD}
              variables={{
                ...variables,
                docId: resume?.id,
                blockId: isSocialBlock.id,
                itemId: variables?.itemId,
                field: name[1],
                needUpdate: true,
              }}
              name={name[1]}
              label={t(translationSlugShowLinkIcon)}
              value={showLinkIcon}
              optimisticResponse={blockItemImmutableUpdate(updateImmue)(
                resume?.id,
                isSocialBlock.id,
                variables.itemId,
                name[1],
              )}
            />
          )}
          {showCheckbox && (
            <InputCheckboxAutoSave
              key={`checkbox-${name}`}
              mutation={UPDATE_BLOCK_FIELD}
              variables={{
                docId: resume?.id,
                blockId: variables.blockId,
                field: name,
                needUpdate: true,
              }}
              label={t(translationSlugAdditionalFlags)}
              value={activeBlockCheckbox[name]}
              name={name}
              optimisticResponse={blockItemImmutableUpdateSwitch(updateImmue)(resume?.id, variables.blockId, name)}
            />
          )}
          {showOnHeaderCheckbox && !isCoverLetter && isAgentOrAdmin && (
            <SocialLinkCheckboxAutoSave
              key={`showonheader-${name}`}
              mutation={UPDATE_BLOCK_ITEM_FIELD}
              variables={{
                ...variables,
                docId: resume?.id,
                blockId: isSocialBlock.id,
                itemId: variables?.itemId,
                field: name,
              }}
              label={t(translationSlugAdditionalFlags)}
              value={showOnHeaderFieldValue}
              name={name}
              resume={resume}
              optimisticResponse={blockItemImmutableUpdate(updateImmue)(
                resume?.id,
                isSocialBlock.id,
                variables.itemId,
                name,
              )}
            />
          )}
        </GeneratorValidatedInput>
      )}
    />
  );
};

GeneratorInputWrap.propTypes = {
  value: PropTypes.string,
  validators: PropTypes.object,
  onSave: PropTypes.func,
  placeholderSlug: PropTypes.string,
  placeholder: PropTypes.string,
  suggestion: PropTypes.bool,
  icon: PropTypes.bool,
  onIconClick: PropTypes.func,
};

export default GeneratorInputWrap;
