import ChooseLanguageStep from '/imports/generator/ui/components/translateResume/ChooseLanguageStep';
import { Body, CTA, CrossIconWrapper, Wrapper } from '/imports/generator/ui/components/translateResume/Styles';
import ProcessTranslationStep from '/imports/generator/ui/components/translateResume/ProcessTranslationStep';
import TranslationFinished from '/imports/generator/ui/components/translateResume/TranslationFinishedStep';
import React, { useState, useEffect, useRef } from 'react';
import Modal from '/imports/core/ui/atoms/Modal';
import CloseModalIcon from '/imports/coaching/ui/assets/CloseModalIcon';
import { pullTranslatedResume, startTranslatingResume } from 'imports/generator/api/api';
import { cookieParser } from 'lib/helpers';
import PropTypes from 'prop-types';
import useTracking from 'imports/core/hooks/useTracking';
import intlHook from 'imports/core/api/useIntl';
import Spinner from '/imports/generator/ui/atoms/Spinner';
import nookies from 'nookies';

const storageTaskId = 'translate-resume:task-id';
const storageNewResumeID = 'translate-resume:new-resume-id';
const storageResumeID = 'translate-resume:resume-id';
export default function TranslateResumeCTA({ resume }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [retry, setRetry] = useState(0);
  const [translationState, setTranslationState] = useState({
    loading: false,
    finish: false,
  });
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  const translatedResumeRef = useRef(null);
  const intervalRef = useRef(null);
  const { token } = cookieParser();
  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.30)',
      zIndex: 500,
    },
    modalBody: {
      width: '600px',
      height: translationState.loading == true || translationState.finish == true ? '268px' : '350px',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '8px',
      flexGrow: 0,
      overflow: 'auto',
    },
  };
  const { agent_client_id } = nookies.get({});

  const handleClose = (returnWithCurrent = false) => {
    setError(null);
    if (returnWithCurrent === true) {
      trackEvent('translate_finish_retun_with_current_click');
      window.localStorage.removeItem(storageNewResumeID);
      setTranslationState({ loading: false, finish: false });
    }
    trackEvent('translate_modal_close');
    setModalOpen(false);
  };
  const handleChange = (e) => {
    setSelectedLanguage(e.value);
  };

  const handleProcessing = (taskId) => {
    window?.localStorage.setItem(storageResumeID, resume.id);
    intervalRef.current = setInterval(() => {
      //stop translation if it take too long 5 x 12 seconds
      setRetry((prevRetry) => {
        if (prevRetry > 60) {
          clearInterval(intervalRef.current);
          window.localStorage.removeItem(storageTaskId);
          return 0; // Reset retry to 0
        }

        return prevRetry + 1; // Increment retry
      });

      pullTranslatedResume({ token, taskId: taskId, agent_client_id }).then((data) => {
        if (data?.status == 500 || data?.status == 400 || data?.status == 401) {
          setRetry(0);
          clearInterval(intervalRef.current);
          window.localStorage.removeItem(storageTaskId);
          window.localStorage.removeItem(storageNewResumeID);
          setTranslationState({
            loading: false,
            finish: false,
          });
          setError('An error occurred. Please try again.');
          console.error(data?.message);
        }
        if (data?.resumeId) {
          translatedResumeRef.current = data.resumeId;
          setTranslationState({ loading: false, finish: true });
          window.localStorage.removeItem(storageTaskId);
          window.localStorage.setItem(storageNewResumeID, data.resumeId);
          setTimeout(() => {
            openModal();
          }, 0);
          setRetry(0);
          clearInterval(intervalRef.current);
        }
      });
    }, 5000);
  };

  useEffect(() => {
    const init = () => {
      if (intervalRef.current) {
        setRetry(0);
        clearInterval(intervalRef.current);
      }
      const oldResumeID = window?.localStorage.getItem(storageResumeID);
      if (oldResumeID !== resume.id) {
        window.localStorage.removeItem(storageTaskId);
        window.localStorage.removeItem(storageNewResumeID);
        return;
      }
      if (window) {
        const newResumeID = window.localStorage.getItem(storageNewResumeID);
        if (newResumeID) {
          translatedResumeRef.current = newResumeID;
          setTranslationState({ loading: false, finish: true });
        } else {
          const taskId = window.localStorage.getItem(storageTaskId);
          if (taskId) {
            setTranslationState({ ...translationState, loading: true });
            handleProcessing(taskId);
          }
        }
      }
    };
    init();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const openModal = () => {
    setError(null);
    setModalOpen(true);
  };

  const handleViewTranslatedResume = () => {
    trackEvent('translate_finish_view_click');
    const data = {
      resumeId: translatedResumeRef.current,
      step: 'finish',
    };
    setTranslationState({ loading: false, finish: false });
    setModalOpen(false);
    window.localStorage.removeItem(storageNewResumeID);
    window.open(`/resume/${translatedResumeRef.current}/${data.step}`, '_blank').focus();
  };

  const handleTranslateCV = async () => {
    if (translationState.loading === true) return;
    setRetry(0);
    setTranslationState({ loading: true, finish: false });
    const taskID = await startTranslatingResume({
      resumeId: resume.id,
      sourceLanguage: resume.settings.language,
      destinationLanguage: selectedLanguage,
      agent_client_id,
      token,
    });
    if (!taskID.taskId) return false;
    trackEvent('translate_resume_processing');
    window.localStorage.setItem(storageTaskId, taskID.taskId);
    handleProcessing(taskID.taskId);
  };
  return (
    <>
      <CTA
        onClick={() => {
          trackEvent('translate_modal_open');
          openModal();
        }}
      >
        {translationState.finish ? (
          t('translate_cv.button_done')
        ) : translationState.loading ? (
          <>
            {t('translate_cv.progress_button')}
            <Spinner $width={'25px'} />
          </>
        ) : (
          t('translate_cv.translate_my_cv')
        )}
      </CTA>
      <Modal
        onClose={() => handleClose()}
        styles={modalStyles}
        animation="empty"
        openStateBase
        open={isModalOpen}
        timeout={0}
      >
        <Wrapper>
          <CrossIconWrapper onClick={handleClose}>
            <CloseModalIcon />
          </CrossIconWrapper>
          <Body>
            {error ? (
              error
            ) : (
              <>
                {translationState.finish ? (
                  <TranslationFinished
                    handleViewTranslatedResume={handleViewTranslatedResume}
                    handleClose={handleClose}
                  />
                ) : translationState.loading ? (
                  <ProcessTranslationStep processRefCount={retry} />
                ) : (
                  <ChooseLanguageStep
                    handleClose={handleClose}
                    handleTranslateCV={handleTranslateCV}
                    handleChange={handleChange}
                    resume={resume}
                    selectedLanguage={selectedLanguage}
                  />
                )}
              </>
            )}
          </Body>
        </Wrapper>
      </Modal>
    </>
  );
}
TranslateResumeCTA.propTypes = {
  resume: PropTypes.object,
};
