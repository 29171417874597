import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import WizardEducation from '/imports/generator/ui/pages/WizardEducation';
import WizardExperience from '/imports/generator/ui/pages/WizardExperience';
import WizardFinalize from '/imports/generator/ui/pages/WizardFinalize';
import WizardOther from '/imports/generator/ui/pages/WizardOthers';
import WizardSkills from '/imports/generator/ui/pages/WizardSkills';
import WizardStart from '/imports/generator/ui/pages/WizardStart';
import WizardSummary from '/imports/generator/ui/pages/WizardSummary';

const withAddittionalBlocks = marvelEmitter.getActiveVariant('exp_add_block_step') === 'with_additional_add_block_step';
export const RESUME_ONBOARDING_STEPS_PAGES = [
  { status: 'start', title: 'Heading', component: WizardStart, translationSlug: 'generator.wizard.heading' },
  {
    status: 'experience',
    title: 'Experience',
    component: WizardExperience,
    // intro: true,
    translationSlug: 'generator.wizard.experience',
  },
  {
    status: 'education',
    title: 'Education',
    component: WizardEducation,
    // intro: true,
    translationSlug: 'generator.wizard.education',
  },
  {
    status: 'skills',
    title: 'Skills',
    component: WizardSkills,
    // intro: true,
    translationSlug: 'generator.wizard.skills',
  },
  {
    status: 'summary',
    title: 'Summary',
    component: WizardSummary,
    // intro: true,
    translationSlug: 'generator.about',
  },
  { status: 'finish', title: 'Finalize', component: WizardFinalize, translationSlug: 'generator.wizard.finish' },
];

export const RESUME_ONBOARDING_STEPS = [
  { status: 'start', title: 'Heading', component: WizardStart, translationSlug: 'generator.wizard.heading' },
  {
    status: 'experience',
    title: 'Experience',
    component: WizardExperience,
    // intro: true,
    translationSlug: 'generator.wizard.experience',
  },
  {
    status: 'education',
    title: 'Education',
    component: WizardEducation,
    // intro: true,
    translationSlug: 'generator.wizard.education',
  },
  {
    status: 'skills',
    title: 'Skills',
    component: WizardSkills,
    // intro: true,
    translationSlug: 'generator.wizard.skills',
  },
  {
    status: 'summary',
    title: 'Summary',
    component: WizardSummary,
    // intro: true,
    translationSlug: 'generator.about',
  },
  { status: 'finish', title: 'Finalize', component: WizardFinalize, translationSlug: 'generator.wizard.finish' },
];

/** add other object if exp is enable */
if (withAddittionalBlocks) {
  const index = RESUME_ONBOARDING_STEPS.length - 1;
  const otherObjects = {
    status: 'other',
    title: 'Other',
    component: WizardOther,
    // intro: true,
    translationSlug: 'generator.wizard.other',
  };
  // Insert the object at the second-to-last position using splice
  RESUME_ONBOARDING_STEPS.splice(index, 0, otherObjects);
  RESUME_ONBOARDING_STEPS_PAGES.splice(index, 0, otherObjects);
}
