import styled, { css } from 'styled-components';

import { isJobTrackEnable } from '/lib/helpers';
import Label from '/imports/core/ui/atoms/Label';
import { TipIcon } from '/imports/generator/ui/assets';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const FieldLabel = (props) => {
  const {
    label = '',
    tip,
    zIndex,
    exposed,
    summary,
    longTitle,
    background = '#f7f7fc',
    isStartForm = false,
    htmlFor,
    smallFont = false,
    accountPage = false,
    customLongTitle = false,
    isCoverLetter,
    language,
  } = props;
  const { locale } = useIntl();
  const { host } = useResponsive();

  return (
    <StyledLabel
      language={language}
      zIndex={Number(!!zIndex)}
      exposed={Number(!!exposed)}
      summary={Number(!!summary)}
      longTitle={Number(!!longTitle)}
      background={background}
      smallFont={smallFont}
      isStartForm={isStartForm}
      htmlFor={htmlFor}
      accountPage={accountPage}
      customLongTitle={customLongTitle}
      isCoverLetter={isCoverLetter}
      locale={locale}
    >
      {isJobTrackEnable() ? <span>{label}</span> : <span>{label.toLocaleUpperCase(locale)}</span>}
      {tip && <TipIcon tip={tip} />}
      <OverlayBackground />
    </StyledLabel>
  );
};
//70px

const StyledLabel = styled(Label)`
  padding: 0 5px;
  position: absolute;
  top: ${({ theme }) => (theme.designV2 ? '-9px' : '-8px')};
  left: ${({ exposed }) => (exposed ? '27px' : '27.5px')};
  z-index: ${({ zIndex }) => (zIndex ? 153 : 10)};
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 11px;
  letter-spacing: 0.2px;
  text-align: center;
  background: ${({ background }) => background};
  color: #46466b;
  text-transform: uppercase;
  ${({ isCoverLetter }) =>
    isCoverLetter &&
    css`
      z-index: 9;
    `}
  @media (min-width: 1025px) and (max-width: 1300px) {
    ${({ customLongTitle }) =>
      customLongTitle &&
      css`
        font-size: 6px !important;
        left: 25px !important;
      `}
  }

  @media (min-width: 1301px) {
    ${({ customLongTitle }) =>
      customLongTitle &&
      css`
        font-size: 8px !important;
        left: 25px !important;
      `}
  }

  @media (min-width: 1025px) and (max-width: 1455px) {
    ${({ theme: { isRTL }, customLongTitle }) =>
      customLongTitle &&
      css`
        text-align: ${isRTL ? 'right' : 'left'};
      `}
  }

  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      color: var(--dark-values-dark-regular) !important;
      line-height: 14px !important;
      top: -6px !important;
      right: '30px' !important;
      left: 'auto' !important;
      background-color: #fff;
      text-transform: capitalize !important;
      font-size: 11px !important;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold} !important;
    `}
  ${({ accountPage }) =>
    accountPage &&
    css`
      top: -2px !important;
    `}
  ${({ theme }) =>
    theme.designV2 &&
    css`
      padding: 0 4.6px;
    `}
  ${({ theme: { isRTL }, exposed }) =>
    isRTL &&
    css`
      left: auto;
      right: ${exposed ? '30px' : '31px'};
    `}
  span {
    position: relative;
    z-index: 10;
  }

  ${(p) =>
    p.summary &&
    css`
      left: ${!p.theme.isRTL ? '15px' : 'auto'};
      right: ${p.theme.isRTL ? '15px' : 'auto'};
    `}

  ${({ longTitle }) =>
    longTitle &&
    css`
      min-height: 20px;
      ${({ theme }) => theme.max('xs')`
      width: calc(100% - 61px);
      bottom: calc(100% - 23px);
      line-height: 15px;
      background-color: transparent;

      div {
        top: auto;
        bottom: 3px;
        height: 12px;
      }
    `}
      ${({ theme, isCoverLetter }) => theme.max('sm')`
        font-size: 9px;
        text-align: start;
        ${!isCoverLetter ? 'width: auto;' : ''}
      `}
    `}

    ${({ smallFont }) =>
    smallFont &&
    css`
      @media (min-width: 1025px) and (max-width: 1420px) {
        font-size: 6px !important;
      }
      @media (min-width: 801px) and (max-width: 900px) {
        font-size: 9px !important;
      }
      @media (max-width: 425px) {
        font-size: 9px !important;
      }
      @media (min-width: 325px) and (max-width: 360px) {
        font-size: 8px !important;
      }
      @media (max-width: 324px) {
        font-size: 6px !important;
      }
    `}
`;

const OverlayBackground = styled.div`
  background: white;
  width: 100%;
  position: absolute;
  top: 10px;
  height: 10px;
  left: 0;
`;

export default FieldLabel;
