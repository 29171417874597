import React, { useEffect, useState } from 'react';
import { lighten } from 'polished';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

import PropTypes from 'prop-types';
import nookies from 'nookies';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { useRouter } from 'next/router';
import { updateUser } from '/imports/carrer/api/api';
import useTracking from '/imports/core/hooks/useTracking';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import { Push } from 'components/Link';
import qs from 'qs';
import intlHook from 'imports/core/api/useIntl';

const ResumeScorePage = ({ handleClose, resume }) => {
  const { token } = nookies.get({});
  const { asPath } = useRouter();
  const { locale, t } = intlHook();
  const { trackEvent } = useTracking();
  const textVar = marvelEmitter.getActiveVariant('exp_review_text');

  const [loading, setLoading] = useState(false);
  const handleData = (consent) => async () => {
    setLoading(true);
    if (consent === 'no') {
      trackEvent('resume_writing_cta_clicked', { isInterested: 'no' });
      await updateUser({ disableResumeReview: true }, token);
      handleClose();
    } else {
      trackEvent('resume_writing_cta_clicked', { isInterested: 'yes' });
      const payload = {
        isInterestedInResumeReview: true,
        disableResumeReview: true,
      };
      await updateUser(payload, token);
      const queryString = {
        page: 'checkout',
        resumeId: resume.id,
        asPath,
        isSkip: true,
      };
      window.localStorage.setItem('resume:success-download-id', resume.id);
      Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
      handleClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    trackEvent('resume_writing_page_view', {
      exp_review_text: textVar,
    });
  }, []);

  const renderPricingLine = () => {
    switch (textVar) {
      case 'var_1':
        return { title: 'writing_title_text_1', subTitle: 'writing_subtitle_text_1' };
      case 'var_2':
        return { title: 'writing_title_text_2', subTitle: 'writing_subtitle_text_2' };
    }
  };

  return (
    <Wrapper>
      <StyledWizardHeader dangerouslySetInnerHTML={{ __html: t(renderPricingLine().title) }} />
      <ContentWrap fullWidth direction="column" alignItems="center">
        <Text dangerouslySetInnerHTML={{ __html: t(renderPricingLine().subTitle) }} />
      </ContentWrap>
      <StyledFlex fullWidth alignItems="center" justifyContent="center">
        <CTA
          onClick={handleData('no')}
          $loading={loading}
          dangerouslySetInnerHTML={{ __html: `❌ ${t('writing_no_cta_text')}` }}
        />
        <CTA
          onClick={handleData('yes')}
          $loading={loading}
          dangerouslySetInnerHTML={{ __html: `✅ ${t('writing_yes_cta_text')}` }}
        />
      </StyledFlex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Text = styled.div`
  color: #262b33;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 18px;
  span {
    color: #1688fe;
    span {
      text-decoration: line-through;
    }
  }
`;

const StyledWizardHeader = styled(WizardHeader)`
  margin-bottom: 36px;
  span {
    color: #1688fe;
  }
`;

const ContentWrap = styled(Flex)`
  gap: 24px;
`;

const CTA = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 16px 9px;
  border-radius: 4px;
  border: 1px solid #1688fe;
  background: var(--light-values-white);
  min-height: 80px;
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  min-height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #1688fe;
  }
  &:hover {
    background-color: ${lighten(0.42, '#1688fe')};
  }
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      opacity: 0.7;
      &:hover {
        background: var(--light-values-white);
      }
    `}
`;

const StyledFlex = styled(Flex)`
  gap: 24px;
  margin-top: 24px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

ResumeScorePage.propTypes = {
  resume: PropTypes.object,
  hideModal: PropTypes.func,
};
const RenderTextAbTest = (props) => {
  return (
    <MarvelExperiment name="exp_review_text">
      <MarvelVariant name="var_1">
        <ResumeScorePage {...props} />
      </MarvelVariant>
      <MarvelVariant name="var_2">
        <ResumeScorePage {...props} />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

export default RenderTextAbTest;
