import Modal from '/imports/core/ui/atoms/Modal';
import CloseModalIcon from '/imports/coaching/ui/assets/CloseModalIcon';
import { CrossIconWrapper, Wrapper } from '/imports/generator/ui/components/translateResume/Styles';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useResponsive } from 'imports/core/api/responsiveContext';
import useTracking from 'imports/core/hooks/useTracking';
import Flex from 'imports/core/ui/atoms/Flex';

const WizardFinalizeSuccessModal = React.memo(({ popupWhatsapp, withNewFlow }) => {
  const [isModalOpen, handleModaleState] = useState(true);
  const handleClose = () => handleModaleState(false);
  const { isMobile } = useResponsive();
  const { trackEvent } = useTracking();
  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.30)',
      zIndex: 500,
    },
    modalBody: {
      width: (isMobile && 'calc(100% - 20px)') || '650px',
      height: isMobile ? 'auto' : '450px',
      margin: isMobile ? 'auto 10px' : 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '8px',
      flexGrow: 0,
      overflow: 'auto',
    },
  };

  const handleUrlClick = () => {
    trackEvent('popup_whatsapp_success_link_click');
  };

  return (
    <Modal
      onClose={() => handleClose()}
      styles={modalStyles}
      animation="empty"
      openStateBase
      open={isModalOpen}
      timeout={0}
    >
      <Wrapper>
        <CrossIconWrapper onClick={handleClose}>
          <CloseModalIcon />
        </CrossIconWrapper>
        <Body $justifyContent={'center'} $alignItems={'start'} $direction="column">
          <Title>
            {`Bienvenue chez l'Agence Réussite ! 🌟`}
            <br />{' '}
            <Link onClick={handleUrlClick} href="https://www.agencereussite.com/" target="_blank">
              www.agencereussite.com
            </Link>
          </Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: `<span>Vous venez de faire un grand pas vers votre futur job !</span> 🎯 À l’<span>Agence Réussite</span>, on s’occupe de tout pour vous, de <span>A à Z</span>. Vous n’avez plus qu’une seule chose à faire : vous présenter aux <span>entretiens</span> que nous aurons soigneusement sélectionnés pour vous.`,
            }}
          />
          <Text
            dangerouslySetInnerHTML={{
              __html: `Et le meilleur dans tout ça ? <span>C’est 100% gratuit</span> tant que nous ne vous avons pas trouvé <span>l’emploi de vos rêves !</span> ✨`,
            }}
          />
          <Text
            dangerouslySetInnerHTML={{
              __html: `D’ailleurs, nous venons de vous envoyer un <span>message sur WhatsApp</span> avec un <span>cadeau de bienvenue</span> 🎁, allez vite le découvrir pendant que <span>votre coach</span> prépare son appel pour tout vous expliquer en détail.`,
            }}
          />
        </Body>
      </Wrapper>
    </Modal>
  );
});

export default WizardFinalizeSuccessModal;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
  width: 100%;
`;
const Link = styled.a`
  color: #0099da;
  text-decoration: underline;
`;

const Text = styled.p`
  margin: 10px 0px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  span {
    font-family: ${({ theme }) => theme.font.family.websiteBold};
  }
`;
const Body = styled(Flex)`
  padding: 10px 30px;
  width: 100%;
  height: 100%;
`;
