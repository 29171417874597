import React from 'react';

const RightTick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
      <g clipPath="url(#clip0_12390_40235)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7823 3.94826L8.74325 14.1603C8.71725 14.1893 8.68125 14.2013 8.65325 14.2273L7.88025 15.1013L1.30625 9.31826C1.04809 9.09008 0.89107 8.76874 0.869694 8.42486C0.848318 8.08097 0.964335 7.74266 1.19225 7.48426L1.45325 7.18926C1.68288 6.93172 2.00491 6.77526 2.3493 6.75391C2.69369 6.73256 3.03257 6.84805 3.29225 7.07526L7.63225 10.8933L15.5323 1.96926C15.7519 1.7233 16.0596 1.57382 16.3887 1.55323C16.7178 1.53264 17.0417 1.6426 17.2903 1.85926L17.6723 2.19626C18.1863 2.64826 18.2353 3.43626 17.7823 3.94826Z"
          fill="#4EBB87"
        />
      </g>
      <defs>
        <clipPath id="clip0_12390_40235">
          <rect width="19" height="15" fill="white" transform="translate(0 0.957031)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightTick;
