import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Option, OptionWrapper } from '/imports/carrer/atmos/CarreeModalAtmos';
import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';

const Tab2 = ({ handleChange, field, handleClick }) => {
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('current_career_status_view');
  }, []);

  return (
    <Fragment>
      <OptionWrapper fullWidth direction="column" smallGap>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            name="currentCareerStatus"
            value="Recently laid off / unemployed"
            onChange={handleChange}
            onClick={handleClick('currentCareerStatus', 'Recently laid off / unemployed')}
          />{' '}
          <Option
            onClick={handleClick('currentCareerStatus', 'Recently laid off / unemployed')}
            checked={field.currentCareerStatus === 'Recently laid off / unemployed'}
          >
            {t('career_option_4_1')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            name="currentCareerStatus"
            value="Employed but looking for a change"
            onClick={handleClick('currentCareerStatus', 'Employed but looking for a change')}
            onChange={handleChange}
          />{' '}
          <Option
            onClick={handleClick('currentCareerStatus', 'Employed but looking for a change')}
            checked={field.currentCareerStatus === 'Employed but looking for a change'}
          >
            {t('career_option_4_2')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            name="currentCareerStatus"
            value="Finished (or finishing) education"
            onClick={handleClick('currentCareerStatus', 'Finished (or finishing) education')}
            onChange={handleChange}
          />{' '}
          <Option
            onClick={handleClick('currentCareerStatus', 'Finished (or finishing) education')}
            checked={field.currentCareerStatus === 'Finished (or finishing) education'}
          >
            {t('career_option_4_3')}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            name="currentCareerStatus"
            value="Re-entering the workforce"
            onChange={handleChange}
            onClick={handleClick('currentCareerStatus', 'Re-entering the workforce')}
          />
          <Option
            onClick={handleClick('currentCareerStatus', 'Re-entering the workforce')}
            checked={field.currentCareerStatus === 'Re-entering the workforce'}
          >
            {t('career_option_4_4')}
          </Option>
        </StyledFlex>
      </OptionWrapper>
    </Fragment>
  );
};

const StyledFlex = styled(Flex)`
  width: 100%;
  position: relative;
`;

const RadioInput = styled.input`
  position: absolute;
  right: 16px;
  z-index: 1;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: unset;
      left: 16px;
    `}
`;

Tab2.propTypes = {
  handleChange: PropTypes.func,
  field: PropTypes.object,
  handleClick: PropTypes.func,
};

export default Tab2;
