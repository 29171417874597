import React, { PureComponent } from 'react';

import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardIntro from '/imports/generator/ui/atoms/WizardIntro';
import WizardListItem from '/imports/generator/ui/atoms/WizardListItem';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';

@withTracking
@withIntl
@withResponsiveContext
class WizardExperienceIntro extends PureComponent {
  componentDidMount() {
    this.props.trackEvent('experience_intro_view');
  }
  render() {
    const { t } = this.props;
    return (
      <WizardIntro>
        <WizardHeader red>
          <div dangerouslySetInnerHTML={{ __html: t('generator.experience.info_title') }} />
        </WizardHeader>
        <WizardSubHeader intro>{t('generator.experience.info_sub_title')}</WizardSubHeader>
        <WizardListItem>{t('generator.experience.info_line1')}</WizardListItem>
        <WizardListItem>{t('generator.experience.info_line2')}</WizardListItem>
      </WizardIntro>
    );
  }
}

export default WizardExperienceIntro;
