import intlHook from 'imports/core/api/useIntl';
import { CancelCta, Footer, Subtitle, TranslateCTA } from '/imports/generator/ui/components/translateResume/Styles';
import Flex from 'imports/core/ui/atoms/Flex';
import PropTypes from 'prop-types';
const TranslationFinished = ({ handleClose, handleViewTranslatedResume }) => {
  const { t } = intlHook();
  return (
    <Flex direction="column" style={{ gap: 24 }}>
      <Subtitle>{t('translate_cv.translated')}</Subtitle>
      <Footer $justifyContent="center">
        <CancelCta onClick={() => handleClose(true)}>{t('translate_cv.return')}</CancelCta>
        <TranslateCTA onClick={handleViewTranslatedResume}>{t('translate_cv.view')}</TranslateCTA>
      </Footer>
    </Flex>
  );
};

TranslationFinished.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleViewTranslatedResume: PropTypes.func.isRequired,
};

export default TranslationFinished;
