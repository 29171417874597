import React, { createContext, useContext, useEffect, useState } from 'react';
import ct from 'countries-and-timezones';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import randomWeight from 'random-weight';
import { useRouter } from 'next/router';

import AccountSkeleton from '/imports/dashboard/ui/skeleton/AccountSkeleton';
import CheckoutSkeleton from '/imports/checkout/ui/skeleton/CheckoutSkeleton';
import DashboardSkeleton from '/imports/dashboard/ui/skeleton/DashboardSkeleton';
import env from '/env';
import { getCountry } from '/imports/checkout/api/utils';
import { getCurrency, getRecordsByBase, EXPLICITY_SET_ZAR_CURRENCY, getLocation } from '/lib/helpers';
import { getLBRules, getPlans, getTrustPilotRules, getTrustPilotRulesException } from '/imports/checkout/api/helpers';
import intlHook from '/imports/core/api/useIntl';
import LayoutSkeleton from '/imports/job-tracking/ui/skeleton/layoutSkeleton';
import Loading from '/imports/core/ui/components/Loading';
import { useResponsive } from '/imports/core/api/responsiveContext';
import publicIp from 'public-ip';
import { EXPERIMENT_3 } from '/imports/checkout/api/constants';
import { Push } from 'components/Link';
import qs from 'qs';

const isProd = env.NODE_ENV === 'production';

const Loaders = {
  dashboard: <DashboardSkeleton />,
  account: <AccountSkeleton />,
  checkout: <CheckoutSkeleton />,
  layout: <LayoutSkeleton />,
  null: null,
};

const hideModal = 'hideModal';
const cardsPaddle = 'Weight Paddle';
const cardsFastSpring = 'Weight Fast Spring';
const cardsSolid = 'Weight Solid';
const cardsStripe = 'Weight Stripe';
const cardsMollie = 'Weight Mollie';
const amexPaddle = 'Weight Amex Paddle';
const amexStripe = 'Weight Amex Stripe';
const amexFastSpring = 'Weight Amex Fast Spring';
const amexMollie = 'Weight Amex Mollie';
const amexSolid = 'Weight Amex Solid';
const mcPaddle = 'Weight MC Paddle';
const mcStripe = 'Weight MC Stripe';
const mcFastSpring = 'Weight MC Fast Spring';
const mcMollie = 'Weight MC Mollie';
const mcSolid = 'Weight MC Solid';
const visaPaddle = 'Weight Visa Paddle';
const visaStripe = 'Weight Visa Stripe';
const visaFastSpring = 'Weight Visa Fast Spring';
const visaMollie = 'Weight Visa Mollie';
const visaSolid = 'Weight Visa Solid';
const providerIfHidden = 'providerIfModalHidden';
const showPaymentDisclaimer = 'Show Payment Disclaimer';
const showTermsCheckbox = 'Terms';
const hideOneYearCheckbox = 'hide1year';
const showTrustPilotCheckbox = 'Show Trust Pilot';

const getRandomWeight = (weightStripe, weightPaddle, weightFastSpring, weightMollie, weightSolid) => {
  if (!weightPaddle && !weightStripe && !weightFastSpring && !weightMollie && !weightSolid) return 6;
  return randomWeight(
    [
      {
        version: 1, // Stripe
        weight: weightStripe,
      },
      {
        version: 4, // Paddle
        weight: weightPaddle,
      },
      {
        version: 5, // Solid
        weight: weightSolid,
      },
      {
        version: 6, //fastSpring
        weight: weightFastSpring,
      },
      {
        version: 7, //Mollie
        weight: weightMollie,
      },
    ],
    (i) => i.weight,
  ).version;
};

const getBillingVersionByProviderName = (providerName) => {
  switch (providerName) {
    case 'stripe':
      return 1;
    case 'paddle':
      return 4;
    case 'solid':
      return 5;
    case 'mollie':
      return 7;
    case 'fastspring':
    default:
      return 6;
  }
};

const billingVersionByCard = (data, cardType) => {
  const cardsStripeWeight = data.weightStripe || 0;
  const cardsPaddleWeight = data[cardsPaddle] || 0;
  const cardsFSWeight = data.weightFastSpring || 0;
  const cardsMollieWeight = data.weightMollie || 0;
  const cardsSolidWeight = data.weightSolid || 0;
  switch (cardType) {
    case 'americanexpress':
      return getRandomWeight(
        data.weightAmexStripe,
        data.weightAmexPaddle,
        data.weightAmexFastSpring,
        data.weightAmexMollie,
        data.weightAmexSolid,
      );
    case 'mastercard':
      return getRandomWeight(
        data.weightMCStripe,
        data.weightMCPaddle,
        data.weightMCFastSpring,
        data.weightMCMollie,
        data.weightMCSolid,
      );
    case 'visa':
      return getRandomWeight(
        data.weightVisaStripe,
        data.weightVisaPaddle,
        data.weightVisaFastSpring,
        data.weightVisaMollie,
        data.weightVisaSolid,
      );
    default:
      return getRandomWeight(cardsStripeWeight, cardsPaddleWeight, cardsFSWeight, cardsMollieWeight, cardsSolidWeight);
  }
};

const getBillingVersion = (cardType, localRule) => {
  // setup stripe for staging
  if (env.NODE_ENV !== 'production') {
    return 1;
  }
  const rule = localRule;
  if (rule && rule?.hideModal) {
    const cardsStripeWeight = rule.weightStripe || 0;
    const cardsPaddleWeight = rule[cardsPaddle] || 0;
    const cardsFSWeight = rule.weightFastSpring || 0;
    const cardsMollieWeight = rule.weightMollie || 0;
    const cardsSolidWeight = rule.weightSolid || 0;
    if (
      cardsStripeWeight > 0 ||
      cardsPaddleWeight > 0 ||
      cardsFSWeight > 0 ||
      cardsMollieWeight > 0 ||
      cardsSolidWeight > 0
    ) {
      const version = billingVersionByCard(rule, '');
      if (version) return version;
    }
    return getBillingVersionByProviderName(rule?.providerIfModalHidden);
  }

  if (rule && !rule?.hideModal) {
    const version = billingVersionByCard(rule, cardType);
    if (version) return version;
  }

  return 6;
};
const BillingContext = createContext();

// Provider component that wraps your app and makes the apollo client
// available to any child component that calls useBilling().
export function BillingProvider({
  children,
  value,
  specialPlanId,
  withLoading = true,
  onlyForSpecial = false,
  loadingComponent = null,
  rule,
  resumeWriting = false,
}) {
  const billing = useBillingProvider(value, specialPlanId, onlyForSpecial, rule, resumeWriting);

  if (billing.isLoading) {
    if (withLoading) {
      return loadingComponent ? Loaders[loadingComponent] : <Loading />;
    }
    return null;
  }

  return <BillingContext.Provider value={billing}>{children}</BillingContext.Provider>;
}

function selectUrlBasedOnPercentage(urlsWithPercentages, trustPilotRulesException, host) {
  const country = getCountry();
  if (!urlsWithPercentages) return;
  if (trustPilotRulesException && Array.isArray(trustPilotRulesException)) {
    for (let exception of trustPilotRulesException) {
      if (exception.domain === host && exception.country.map((c) => c.toUpperCase()).includes(country.toUpperCase())) {
        return exception.URL;
      }
    }
  }
  let totalPercentage = urlsWithPercentages.reduce((acc, item) => acc + item.Percentage, 0);
  if (totalPercentage !== 1) {
    return 'https://www.trustpilot.com/review/cv-lite.com';
  }
  let random = Math.random();
  let cumulative = 0;
  for (let i = 0; i < urlsWithPercentages.length; i++) {
    cumulative += urlsWithPercentages[i].Percentage;
    if (random <= cumulative) {
      return urlsWithPercentages[i].URL;
    }
  }
  return urlsWithPercentages[urlsWithPercentages.length - 1].URL;
}

BillingProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
  specialPlanId: PropTypes.string,
  withLoading: PropTypes.bool,
  onlyForSpecial: PropTypes.bool,
  loadingComponent: PropTypes.string,
  rule: PropTypes.object,
};

// Provider hook that creates store object and handles state
function useBillingProvider(client, specialPlanId, onlyForSpecial, intialRule = null, resumeWriting) {
  const { host } = useResponsive();
  const { locale } = intlHook();
  const [currency, setCurrency] = useState(getCurrency(host));
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trustPilotUrl, setTrustPilotUrl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(specialPlanId ? specialPlanId : null);
  const [card, setCard] = useState(null);
  const [rule, setRule] = useState(intialRule);
  const [hideModalPreview, setHideModalPreview] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hideOneYear, setHideOneYear] = useState(false);
  const [showTrustPilot, setShowTrustPilot] = useState(false);
  const [ipCountryCode, setIpCountryCode] = useState(null);
  const [solidTerms, setSolidTerms] = useState(false);
  const country = getCountry();
  const { query, asPath } = useRouter();

  const fetchData = async (countryCode, billingVersion) => {
    let currency = getCurrency(host, countryCode);
    if (billingVersion === 5 && country === 'BR') currency = 'BRL';
    setCurrency(currency);
    return currency;
  };

  const fetchCountryCodeByIp = async () => {
    try {
      const IP = await publicIp.v4();
      const countryCode = await getLocation(IP || '127.0.0.1');
      return countryCode;
    } catch (e) {
      console.error('Error while fetching ip country code:', e);
      return null;
    }
  };

  useEffect(() => {
    const fetchCountryCodeData = async () => {
      const countryCode = await fetchCountryCodeByIp();
      setIpCountryCode(countryCode);
    };
    fetchCountryCodeData();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchPlansAndRules = async () => {
      let LBCountry = country;

      if (onlyForSpecial) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        let updatedCurrency = getCurrency(host);
        const countryCode = await fetchCountryCodeByIp();
        if (countryCode === 'BR') {
          LBCountry = countryCode;
        } else if (countryCode === 'CO') {
          LBCountry = countryCode;
          updatedCurrency = 'COP';
        }
        if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
          const currencyData = await fetchData(countryCode, billingVersion);
          updatedCurrency = currencyData || updatedCurrency;
        }
        if (!updatedCurrency && !query?.isSkip) {
          setIsLoading(false);
          return;
        }
        if (isMounted) {
          if (!rule) {
            const fetchedRule = await getLBRules(host, LBCountry);
            if (fetchedRule?.providerIfModalHidden === 'solid') {
              switch (country) {
                case 'BR':
                  updatedCurrency = 'BRL';
                  break;
                case 'MX':
                  updatedCurrency = 'MXN';
                  break;
                case 'CO':
                  updatedCurrency = 'COP';
                  break;
              }
              setCurrency(updatedCurrency);
            }
            const plans = await getPlans(updatedCurrency);
            setPlans(plans);
            setRule(fetchedRule);

            const trustPilotRules = await getTrustPilotRules();
            const trustPilotRulesException = await getTrustPilotRulesException();
            const trustPilotUrl = selectUrlBasedOnPercentage(trustPilotRules, trustPilotRulesException, host);
            if (trustPilotUrl) {
              setTrustPilotUrl(trustPilotUrl);
              if (localStorage) {
                localStorage.setItem('trust_pilot_url', trustPilotUrl);
              }
            }
          }

          if ((query?.isSkip || resumeWriting) && !currentPlan) {
            const filteredPlans = plans.filter((p) => p.experiment === EXPERIMENT_3);
            const activePlan = filteredPlans.find((data) => data?.trialAmount === 0.95);
            if (activePlan) {
              setCurrentPlan(activePlan);
              const queryString = {
                page: 'payment',
                plan: activePlan.id,
                resumeId: query?.resumeId,
                asPath,
                isSkip: true,
              };
              if (!query?.success && !asPath.includes('resume-review-payment'))
                Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout/payment');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching plans and rules:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchPlansAndRules();

    return () => {
      isMounted = false;
    };
  }, [host, specialPlanId, onlyForSpecial]);

  useEffect(() => {
    if (!!rule) {
      setHideModalPreview(rule ? !!rule?.hideModal : true);
      setShowDisclaimer(rule ? !!rule?.showPaymentDisclaimer : false);
      setShowTerms(rule ? !!rule?.terms : false);
      setHideOneYear(rule ? !!rule?.hide1year : false);
      setSolidTerms(rule ? !!rule?.solidTerms : false);
      setShowTrustPilot(rule && isProd ? !!rule?.showTrustPilot : false);
      setIsLoading(false);
    }
  }, [rule]);

  const billingVersion = getBillingVersion(card, rule, query?.isSkip);
  // Return store objects and store methods
  return {
    isLoading,
    client,
    currency,
    setCurrency,
    plans,
    currentPlan,
    setCurrentPlan,
    billingVersion,
    setSelectedCard: setCard,
    card: hideModalPreview || card,
    showDisclaimer,
    showTerms,
    hideOneYear,
    showTrustPilot,
    trustPilotUrl,
    ipCountryCode,
    solidTerms,
    rule,
  };
}
// Decorator HOC for legacy class components
// that adds billing context as props.
export const withBilling = (Component) =>
  class Wrapper extends React.Component {
    render() {
      return <BillingContext.Consumer>{(state) => <Component {...this.props} {...state} />}</BillingContext.Consumer>;
    }
  };

// Hook for child components to get the context object
// and re-render when it changes.
const BillingContextComponent = () => useContext(BillingContext);
export default BillingContextComponent;
