export const array_move = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }

  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing purposes
};

export function processJobDataForPortal(data, portals) {
  if (portals?.length === 0) return data;
  // Define the priority for providers
  const portalPriority = ['Indeed', 'Other', 'LinkedIn'];
  let selectedPortal = null;

  // Loop through the portals to find the one based on priority
  for (const portal of portals) {
    if (portal.jobProvider?.toLowerCase() === 'indeed') {
      selectedPortal = portal;
      break;
    } else if (!selectedPortal && portal.jobProvider !== 'linkedin') {
      selectedPortal = portal;
    }
  }
  // If no portal was selected yet, fallback to LinkedIn
  if (!selectedPortal) {
    selectedPortal = portals.find((portal) => portal.jobProvider?.toLowerCase() === 'linkedin');
  }
  // Update the data object with the selected portal and URL
  return {
    ...data,
    jobProvider: selectedPortal?.jobProvider || null,
    jobLink: selectedPortal?.url || null,
  };
}

export const getTotalBoardJobs = (board) => {
  let data = [];
  board?.columns.map((k) => {
    if (k?.jobs?.length > 0) {
      data = [...data, ...k.jobs];
    }
  });
  return data;
};

export const getAllBoardJob = (boards) => {
  let jobData = [];
  boards && boards.map((e) => (jobData = [...jobData, ...getTotalBoardJobs(e)]));
  return jobData;
};

export const showPendingTask = (jobId, tasks) => {
  let data = null;
  data = tasks.find((k) => k.linkedJobs.includes(jobId) && !k.isCompleted);
  return !!data;
};

export const savedData = (boards, card) => {
  const boardData = getAllBoardJob(boards);
  const data = boardData.find((k) => k.esId === ((card && card.esId) || (card && card.id)));
  return !!data ? data : null;
};

export const isSavedPodioJob = (podioJobs, job) => {
  if (!job || !podioJobs) return null;
  const jobsData = podioJobs?.jobs || podioJobs;
  const data = jobsData.find((k) => k.jobId == job.id);
  return data ? data : null;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeAllFirstLetter = (string) => {
  if (!string || typeof string !== 'string') return string;
  return string.replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
};

export const removeKeys = (keys, object) => keys.forEach((e) => delete object[e]);

export const getCityCountry = (city, country) => {
  if (city === country) return city;
  const joined = [];
  city && joined.push(city);
  country && joined.push(country);
  return joined.join(', ');
};

export const replaceJobObject = (jobsArray, newJob) => {
  return jobsArray.map((job) => (job.jobId === newJob.jobId && job.jobId === newJob.jobId ? newJob : job));
};

export const alreadyApplied = (podioJobs, currentJob) => {
  const jobsData = podioJobs?.jobs || podioJobs;
  const isExist = jobsData.find((g) => g.jobId === currentJob.id);
  if (isExist) {
    const isSent = isExist?.status === 'Application Sent';
    return isSent;
  }
};

export const isFoundJob = (candidateSearch, searchText, cityText) => {
  if (candidateSearch?.length === 0) return null;
  const foundJob = candidateSearch?.find(
    (job) => (job.title && job.title.includes(searchText)) || (job.location && job.location.includes(cityText)),
  );
  return foundJob || null;
};

export const getFilterLanguage = (code) => {
  switch (code) {
    case 'French':
      return 'fr';
    case 'English':
      return 'en';
    case 'Spanish':
      return 'es';
  }
};

export const getJobTemplateIds = (data) => {
  if (data.jobTemplate && Array.isArray(data.jobTemplate)) {
    return data.jobTemplate.map((template) => template.id);
  }
  return [];
};
