import styled, { css } from 'styled-components';

export const FormSummary = styled.div`
  > label {
    margin-bottom: 12px;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default FormSummary;
