import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import { createNewOptimiseResume, getBucketData, optimiseResume, parseJobDescription } from 'imports/generator/api/api';
import { Push } from 'components/Link';
const aiResumeStorage = 'storage:resume:ai-resume';
import useTracking from '/imports/core/hooks/useTracking';
import { cdnFile, cookieParser } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';
import styled, { css, keyframes } from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';
import Modal from 'imports/core/ui/atoms/Modal';
import useWindowSize from 'imports/core/api/useWindowSize';
import Header from 'imports/extensionOnboarding/components/Header';
import { useGeneralDesign } from 'imports/core/api/generalContext';
import PropTypes from 'prop-types';
import { useGlobalState } from '/imports/core/api/global.context';
import { Progress } from '/imports/resume-options/ui/component/UploadResume';

import env from '/env';

const isProd = env.NODE_ENV === 'production';
const useOptimiseResume = (resume) => {
  const { handleUpdateState } = useGlobalState();
  const [optimiseLoadingText, setOptimiseLoadingText] = useState(null);
  const [aiParsedResume, setAiParsedResume] = useState();
  const { token } = cookieParser();
  const { locale } = useIntl();
  const isRetry = useRef(0);
  const [prompt, setPrompt] = useState('');
  const [aiParseResumeLoading, setAiParsedResumeLoading] = useState(false);
  const [bucketStore, setBucketStore] = useState(null);
  const { trackEvent } = useTracking();
  const handlePrompt = (prompt) => {
    setPrompt(prompt);
  };
  useEffect(() => {
    fetchBucketData();
  }, []);
  const fetchBucketData = async () => {
    let bucketId = getBucketId();
    if (!bucketId) {
      return;
    }
    const response = await getBucketData(bucketId, token);
    setBucketStore(response);
    await fetchAiParsedResume(response, resume);
  };

  const getBucketId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let bucketId = urlParams.get('bucketId');
    if (bucketId) {
      window.localStorage.removeItem('storage:scoring:selectedResume');
      window.localStorage.setItem('bucketId', bucketId);
    }
    if (window && !bucketId) {
      return window.localStorage.getItem('bucketId');
    }
    return bucketId;
  };

  /**
   * get ai resume stored in localStorage
   */
  const getAiParsedResume = () => {
    if (Window) {
      try {
        return JSON.parse(window.localStorage.getItem(aiResumeStorage));
      } catch (e) {
        return null;
      }
    }
  };
  /**
   *
   * Fetch ai resume based on JD, and store the data in localStorage to avoid call every reload
   *
   */
  const fetchAiParsedResume = async (bucket, resume) => {
    setAiParsedResumeLoading(true);
    const localParsedResume = getAiParsedResume();
    let parsedResume = localParsedResume?.parsedJobDescription;
    if (!localParsedResume || localParsedResume?.bucketId != bucket?.id) {
      parsedResume = await parseJobDescription(
        {
          jobDescription: bucket?.jobDescription,
          companyName: bucket?.companyName,
          employer: bucket?.employer,
          resumeId: resume.id,
        },
        token,
      );
    }
    setAiParsedResume(parsedResume);
    if (window)
      window.localStorage.setItem(
        aiResumeStorage,
        JSON.stringify({ parsedJobDescription: parsedResume, bucketId: bucket?.id }),
      );

    setAiParsedResumeLoading(false);
    setTimeout(() => {
      handleUpdateState('scoreAnalysis', true);
    }, 1000);
  };

  const createOptimisedResume = async (resume, data, currentUser) => {
    try {
      setOptimiseLoadingText('Optimising your resume...');
      let jobData = aiParsedResume?.jobData || {};
      if (data && data?.skills) {
        jobData.Hard_Skills = data?.skills?.hardSkills;
        jobData.Soft_Skills = data?.skills?.softSkills;
        jobData.companyName = bucketStore?.companyName;
        jobData.employer = bucketStore?.employer;
      }
      if (!isProd) window.localStorage.setItem('cv-optimise-prompt', prompt);
      const optimisedResume = await optimiseResume(
        {
          jobData: jobData,
          excluded_skills: data?.excludedSkills || [],
          resumeId: resume?.id,
          promptBody: !isProd && prompt?.trim() != '' ? prompt : null,
        },
        token,
      );

      setOptimiseLoadingText('Creating new resume...');
      const newResume = await createNewOptimiseResume({
        payload: {
          ...optimisedResume?.responseData,
          companyName: bucketStore?.companyName,
          employer: bucketStore?.employer,
        },
        token,
        resumeId: resume.id,
      });
      if (newResume?.resumeId) {
        const query = qs.stringify({
          page: 'resume',
          resumeId: newResume?.resumeId,
          step: resume.currentStep,
          optimizedResume: true,
          userId: currentUser?.id,
        });
        Push(
          `/resume/?${query}`,
          locale,
          `/resume/${newResume?.resumeId}/${newResume?.currentStep}?optimizedResume=true&userId=${currentUser?.id}`,
        );
      }

      setOptimiseLoadingText(null);
    } catch (e) {
      trackEvent('ai_optimise_resume_scoring_error');
      if (isRetry.current > 0) {
        setOptimiseLoadingText(null);
        isRetry.current = 0;
        return { error: true, message: e.message };
      } else {
        isRetry.current = 1;
        await createOptimisedResume(resume, data, currentUser);
      }
    }
  };

  return {
    optimiseLoadingText,
    bucketStore,
    createOptimisedResume,
    aiParsedResume,
    fetchAiParsedResume,
    aiParseResumeLoading,
    handlePrompt,
    prompt,
  };
};

export const ApplyinLoader = ({ template }) => {
  const { t } = useIntl();
  const { width } = useWindowSize();
  const [open, setOpen] = useState(true);
  const {
    optimiseResume: { optimiseLoadingText },
  } = useGeneralDesign();
  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.8)',
    },
    modalBody: {
      width: width <= 655 ? '90%' : '610px',
      height: '100%',
      maxHeight: '600px',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '12px',
      boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
      flex: 'unset',
      overflow: 'visible',
    },
  };
  if (!optimiseLoadingText) return null;
  return (
    <Modal
      onClose={() => setOpen(false)}
      fullScreen
      animation="empty"
      openStateBase
      styles={modalStyles}
      open={open}
      timeout={0}
      disableOutsideClick={true}
    >
      <HeaderContainer direction="column">
        <Header />
      </HeaderContainer>
      <Wrapper $justifyContent="center" $alignItems="center">
        <ModalBody $direction="column" $justifyContent="center" $alignItems="center">
          <Flex justifyContent="center" alignItems="center" fullWidth>
            <Resume template={template} />
            <Temp>
              <Content direction="column" justifyContent="center" alignItems="center">
                <LoadingText>{optimiseLoadingText}</LoadingText>
                <Bar>
                  <Loader />
                </Bar>
              </Content>
            </Temp>
          </Flex>
        </ModalBody>
      </Wrapper>
    </Modal>
  );
};

ApplyinLoader.propTypes = {
  template: PropTypes.string,
};
const ModalBody = styled(Flex)`
  gap: 24px;
  position: relative;
  padding-bottom: 24px;
`;

const HeaderContainer = styled(Flex)`
  gap: 12px;
  height: 79px;
  border-bottom: 1px solid var(--grayscale-n200);
`;
const Wrapper = styled(Flex)`
  padding: 32px;
  height: 90%;
`;
const Loader = styled.div`
  background-color: #1688fe;
  height: 4px;
  animation: ${Progress} 20s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`;
const Bar = styled.div`
  height: 4px;
  background-color: #e7f3fe;
  width: 90%;
  margin-top: 16px;
  opacity: 1;
`;

const LoadingText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 19px;
  color: #1688fe;
  text-align: center;
`;
const Resume = styled(Flex)`
  ${({ template }) =>
    template &&
    css`
      background-image: url(${cdnFile(`resumes/${template}.jpg`)});
    `}
  width: 200px;
  height: 293px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  opacity: 0.95;
  border-radius: 16px;
`;
const Content = styled(Flex)`
  height: 100%;
`;
const Temp = styled.div`
  width: 200px;
  height: 293px;
  background-color: var(--light-values-white);
  position: absolute;
  opacity: 0.8;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(20, 20, 31, 0.08);
`;

export default useOptimiseResume;
