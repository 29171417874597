import { Fragment, PureComponent, useEffect } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import GenericBlock from '/imports/generator/ui/components/GenericBlock';
import { removeExperiment } from '/lib/helpers';
import useTracking from '/imports/core/hooks/useTracking';
import { withGeneralContext } from '/imports/core/api/generalContext';
import { withIntl } from '/imports/core/api/useIntl';
import { useResponsive, withResponsiveContext } from '/imports/core/api/responsiveContext';
import WizardEducationIntro from '/imports/generator/ui/components/WizardEducationIntro';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';

const EducationWizardForm = ({ t, renderBlock, designV2, resume }) => {
  const { fromScan } = resume;
  const { isMobile } = useResponsive();
  const { trackEvent } = useTracking();

  useEffect(() => {
    let obj = {};
    removeExperiment('exp_custom_degree');
    removeExperiment('exp_education_input_degree');
    removeExperiment('exp_undo_redo_textarea_editor');

    trackEvent('education_view', obj);
  }, []);
  return (
    <Fragment>
      <WizardHeader designV2={designV2}>
        <div dangerouslySetInnerHTML={{ __html: t('generator.education.form_title') }} />
      </WizardHeader>
      <WizardSubHeader marginLess fromScan={fromScan}>
        {t('generator.education.form_subtitle')}
      </WizardSubHeader>
      {renderBlock()}
    </Fragment>
  );
};

@withIntl
@withRouter
@withResponsiveContext
@withGeneralContext
class WizardEducation extends PureComponent {
  static propTypes = {
    resume: PropTypes.object,
    intro: PropTypes.string,
  };

  _form = null;

  state = {
    show: false,
    popshown: false,
  };
  componentDidMount() {
    removeExperiment('education_view_google_suggest_experiement');
  }

  getForm = (node) => {
    this._form = node;
  };

  isFormValid = () => (this._form && this._form.isFormValid(false)) || Promise.resolve(true);

  renderBlock = () => {
    const { resume, updateImmue, referenceResume } = this.props;
    const block = resume.blocks.find((block) => block.type === 'EDUCATION');
    const refernceBlock = referenceResume && referenceResume.blocks.find((block) => block.type === 'EDUCATION');
    return (
      <GenericBlock
        block={block}
        resumeId={resume.id}
        source={resume}
        noTitle
        refernceBlock={refernceBlock}
        referenceResume={referenceResume}
        getForm={this.getForm}
        isFormValid={this.isFormValid}
        updateImmue={updateImmue}
      />
    );
  };

  render() {
    const { resume, intro, updateImmue } = this.props;
    return (
      <WizardLayout isFormValid={this.isFormValid} resume={resume} updateImmue={updateImmue}>
        {intro && intro === 'intro' ? (
          <WizardEducationIntro />
        ) : (
          <EducationWizardForm renderBlock={this.renderBlock} {...this.props} designV2={this.props.designV2} />
        )}
      </WizardLayout>
    );
  }
}

WizardEducation.displayName = 'WizardEducation';

export default WizardEducation;
