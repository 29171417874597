import React from 'react';
import styled, { css } from 'styled-components';
import RenderIcon from 'imports/generator/ui/components/RenderIcon';

const StyledLinkIcon = (props) => {
  return <StyledIcon>{RenderIcon(props)}</StyledIcon>;
};

const StyledIcon = styled.div`
  ${({ theme, theme: { convertPx, isRTL, colors, color } }) => css`
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${convertPx(24)};
    height: ${convertPx(24)};
    margin-right: ${convertPx(isRTL ? 0 : 5)};
    margin-left: ${convertPx(isRTL ? 5 : 0)};

    ${color !== 'budapestGirlBlue' &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${convertPx(24)};
        height: ${convertPx(24)};
        border-radius: 50%;
        background-color: ${colors[color] || '#e0e0e0'};
        z-index: -1;
      }
    `}
    svg {
      width: ${convertPx(12)};
      height: ${convertPx(12)};
      path {
        fill: ${theme.accentTitleColors[color] || 'currentColor'};
      }
      rect {
        fill: ${colors[color] || 'currentColor'};
      }
      ${color === 'budapestGirlBlue' &&
      css`
        path {
          fill: ${theme.accentTitleColors['budapestGirlBlue'] || 'currentColor'};
        }
        rect {
          fill: transparent;
        }
      `}
    }
  `}
`;

export default StyledLinkIcon;
