import { useResponsive } from 'imports/core/api/responsiveContext';
import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

export default function RenderTranslateCV(props) {
  const { isMobile } = useResponsive();
  if (isMobile) return props.children;
  return (
    <MarvelExperiment name="exp_translate_cv">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_translate_button">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
}
