import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { getCountry } from 'imports/checkout/api/utils';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getCurrencyFormat, isWithCareerLead } from '/imports/carrer/api/helper';
import { Option, OptionWrapper } from '/imports/carrer/atmos/CarreeModalAtmos';
import intlHook from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';

const Tab3 = ({ handleChange, field, handleClick }) => {
  const { t, locale } = intlHook();
  const { trackEvent } = useTracking();
  const country = getCountry();
  const isCareerLeadActivated =
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
    isWithCareerLead(locale, country) ||
    ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
  useEffect(() => {
    if (isCareerLeadActivated) {
      trackEvent('contact_to_expert_career_view');
    } else {
      trackEvent('amount_spend_for_career_view');
    }
  }, []);
  if (isCareerLeadActivated) {
    return (
      <Fragment>
        <OptionWrapper fullWidth direction="column" smallGap>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="contactToExpert"
              value="yes"
              checked={field.contactToExpert === 'yes'}
              onClick={handleClick('contactToExpert', 'yes')}
            />{' '}
            <Option checked={field.contactToExpert === 'yes'} onClick={handleClick('contactToExpert', 'yes')}>
              {t('career_option_1')}
            </Option>
          </StyledFlex>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="contactToExpert"
              value="no"
              checked={field.contactToExpert === 'no'}
              onClick={handleClick('contactToExpert', 'no')}
            />{' '}
            <Option checked={field.contactToExpert === 'no'} onClick={handleClick('contactToExpert', 'no')}>
              {t('career_option_2')}
            </Option>
          </StyledFlex>
          <StyledFlex alignItems="center">
            <RadioInput
              type="radio"
              name="contactToExpert"
              value="later"
              checked={field.contactToExpert === 'later'}
              onClick={handleClick('contactToExpert', 'later')}
            />{' '}
            <Option checked={field.contactToExpert === 'later'} onClick={handleClick('contactToExpert', 'later')}>
              {t('career_option_3')}
            </Option>
          </StyledFlex>
        </OptionWrapper>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <OptionWrapper fullWidth direction="column" smallGap>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(799)}+`)}
            name="amountSpendForCareer"
            value={`${getCurrencyFormat(799)}+`}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(799)}+`}
          />{' '}
          <Option
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(799)}+`)}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(799)}+`}
          >
            {`${getCurrencyFormat(799)}+`}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`)}
            name="amountSpendForCareer"
            value={`${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`}
          />{' '}
          <Option
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`)}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`}
          >
            {`${getCurrencyFormat(299)} - ${getCurrencyFormat(799)}`}
          </Option>
        </StyledFlex>
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`)}
            name="amountSpendForCareer"
            value={`${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`}
          />{' '}
          <Option
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`)}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`}
          >
            {`${getCurrencyFormat(50)} - ${getCurrencyFormat(299)}`}
          </Option>
        </StyledFlex>{' '}
        <StyledFlex alignItems="center">
          <RadioInput
            type="radio"
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`)}
            name="amountSpendForCareer"
            value={`${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`}
          />{' '}
          <Option
            onClick={handleClick('amountSpendForCareer', `${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`)}
            checked={field.amountSpendForCareer === `${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`}
          >
            {`${getCurrencyFormat(0)} - ${getCurrencyFormat(50)}`}
          </Option>
        </StyledFlex>{' '}
      </OptionWrapper>
    </Fragment>
  );
};

const StyledFlex = styled(Flex)`
  width: 100%;
  position: relative;
`;

const RadioInput = styled.input`
  position: absolute;
  right: 16px;
  z-index: 1;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: unset;
      left: 16px;
    `}
`;

Tab3.propTypes = {
  handleChange: PropTypes.func,
  field: PropTypes.object,
  handleClick: PropTypes.func,
};

export default Tab3;
