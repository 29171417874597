import { Fragment, PureComponent, useEffect, useState } from 'react';
import nookies from 'nookies';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'next/router';

import GenericBlock from '/imports/generator/ui/components/GenericBlock';
import { removeExperiment } from '/lib/helpers';
import useTracking, { withTracking } from '/imports/core/hooks/useTracking';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withGeneralContext } from '/imports/core/api/generalContext';
import intlHook, { withIntl } from '/imports/core/api/useIntl';
import { useResponsive, withResponsiveContext } from '/imports/core/api/responsiveContext';
import WizardHeader from '/imports/generator/ui/atoms/WizardHeader';
import WizardLayout from '/imports/generator/ui/layouts/WizardLayout';
import WizardSkillsIntro from '/imports/generator/ui/components/WizardSkillsIntro';
import WizardSubHeader from '/imports/generator/ui/atoms/WizardSubHeader';
import Tab2 from '/imports/carrer/components/Tab2';
import { updateUser } from '/imports/carrer/api/api';
import { useGlobalState, withGlobalContext } from 'imports/core/api/global.context';
import Tab1Sub from '/imports/carrer/components/Tab1Sub';
import useFetchAISuggestion from 'imports/generator/ui/atoms/useFetchAISuggestion';
import { isWithCareerLead } from '/imports/carrer/api/helper';
import { getCountry } from 'imports/checkout/api/utils';

const SkillsWizardForm = ({ t, renderBlock, designV2, resume, show, handleClose, step }) => {
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const { locale } = intlHook();
  const country = getCountry();
  const { fromScan } = resume;
  const withNewFlow =
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v2')) ||
    isWithCareerLead(locale, country) ||
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
    ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));

  if (isMobile) {
    const block = resume?.blocks.find((b) => b.type === 'PROFESSIONAL_SUMMARY');
    const item = block?.items && block?.items[0];
    const type = block?.type;
    useFetchAISuggestion(resume, item, type, step);
  }
  useEffect(() => {
    removeExperiment('exp_expand_skill');
    removeExperiment('exp_autofocus_skill');
    const obj = {};

    const activeVarAutoFetch = marvelEmitter.getActiveVariant('exp_auto_fetch_related_skills_new');

    if (activeVarAutoFetch) {
      obj.exp_auto_fetch_related_skills_new = activeVarAutoFetch;
    }

    if (marvelEmitter.getActiveVariant('exp_power_skill_tags_mobile')) {
      obj.exp_power_skill_tags_mobile = marvelEmitter.getActiveVariant('exp_power_skill_tags_mobile');
    }

    trackEvent('skills_view', obj);
  }, []);
  const hideModal = () => handleClose();
  if (withNewFlow && show) return <RenderCareerAbtest hideModal={hideModal} />;

  return (
    <Fragment>
      <WizardHeader designV2={designV2}>
        <div dangerouslySetInnerHTML={{ __html: t('generator.skills.form_title') }} />
      </WizardHeader>
      <WizardSubHeader marginLess fromScan={fromScan}>
        {t('generator.skills.form_subtitle')}
      </WizardSubHeader>
      {renderBlock()}
    </Fragment>
  );
};

@withTracking
@withIntl
@withAccount
@withGlobalContext
@withRouter
@withResponsiveContext
@withGeneralContext
class WizardSkills extends PureComponent {
  static propTypes = {
    resume: PropTypes.object,
    intro: PropTypes.string,
  };

  state = {
    show: false,
    popshown: false,
  };
  componentDidMount() {
    const {
      currentUser,
      context: {
        state: { userDisqualify },
      },
    } = this.props;
    const stateFlag = localStorage.getItem('flag');
    const fifthStep = stateFlag && JSON.parse(stateFlag);
    if (!fifthStep?.fifthStep && (userDisqualify ? !userDisqualify : !currentUser.disableCarrerQuiz)) {
      this.setState({
        show: true,
      });
    }
  }

  handleClose = () => {
    this.setState({
      show: false,
      popshown: true,
    });
    localStorage.setItem(
      'flag',
      JSON.stringify({ firstStep: 'true', secondStep: 'true', fourthStep: 'true', fifthStep: 'true' }),
    );
  };

  renderBlock = () => {
    const { resume, updateImmue, referenceResume } = this.props;
    const block = resume.blocks.find((block) => block.type === 'SKILLS');
    return (
      <GenericBlock
        block={block}
        resumeId={resume.id}
        source={resume}
        noTitle
        referenceResume={referenceResume}
        startExpanded
        updateImmue={updateImmue}
      />
    );
  };

  render() {
    const { resume, intro, designV2, updateImmue } = this.props;
    const block = resume.blocks.find((block) => block.type === 'SKILLS');

    return (
      <WizardLayout
        resume={resume}
        updateImmue={updateImmue}
        handleClose={this.handleClose}
        show={this.state.show}
        popshown={this.state.popshown}
      >
        {intro && intro === 'intro' ? (
          <WizardSkillsIntro />
        ) : (
          <SkillsWizardForm
            renderBlock={this.renderBlock}
            {...this.props}
            designV2={designV2}
            handleClose={this.handleClose}
            show={this.state.show}
            updateImmue={updateImmue}
          />
        )}
      </WizardLayout>
    );
  }
}

WizardSkills.displayName = 'WizardSkills';

const RenderCareerAbtest = ({ hideModal }) => {
  const { trackEvent } = useTracking();
  const { token } = nookies.get({});
  const { t, locale } = intlHook();
  const country = getCountry();
  const { handleUpdateState } = useGlobalState();
  const newVar =
    marvelEmitter.getActiveVariant('exp_career_status_v2') === 'without_first_step_flow' ||
    isWithCareerLead(locale, country) ||
    ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
    ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
  const [field, setField] = useState({ currentCareerStatus: '', howQuicklyLookingForNewJob: '' });

  const handleData = async (key, value) => {
    const resp = await updateUser({ [key]: value }, token);
    handleUpdateState('userData', resp);
  };
  const handleClick = (key, value) => () => {
    setField({ ...field, [key]: value });
    trackEvent(key, {
      [key]: value,
    });
    handleData(key, value);
    if (key === 'currentCareerStatus' && value === 'Finished (or finishing) education') {
      trackEvent('user_disqualify', { quiz: 'currentCareerStatus' });
      handleUpdateState('userDisqualify', true);
      handleData('disableCarrerQuiz', true);
    }
    if (key === 'howQuicklyLookingForNewJob' && ["I'm not looking for a job", '6+ months'].includes(value)) {
      trackEvent('user_disqualify', { quiz: 'howQuicklyLookingForNewJob' });
      handleUpdateState('userDisqualify', true);
      handleData('disableCarrerQuiz', true);
    }
    hideModal();
  };

  return (
    <Wrapper>
      <StyledWizardHeader>
        <div>{newVar ? t('new_var_question') : t('career_question_4')}</div>
      </StyledWizardHeader>
      {newVar ? (
        <Tab1Sub field={field} handleClick={handleClick} newVariant />
      ) : (
        <Tab2 field={field} handleClick={handleClick} />
      )}
    </Wrapper>
  );
};

const StyledWizardHeader = styled(WizardHeader)`
  margin-bottom: 36px;
`;

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

export default WizardSkills;
