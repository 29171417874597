import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { ACCPTED_CARDS_LOGO } from '/imports/core/api/constants';
import Address from '/imports/core/ui/components/Address';
import Container from '/imports/homepageV2/ui/atmos/Container';
import {
  EXPLICITY_SET_ZAR_CURRENCY,
  FEF_HOST,
  getCompanyNameByHost,
  getCurrency,
  getLocation,
  staticFile,
} from '/lib/helpers';
import Flex from '/imports/core/ui/atoms/Flex';
import { footerLinks } from '/imports/homepage/api/constants';
import { Push } from '/components/Link';
import { replaceString } from '/imports/core/ui/helpers';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import publicIp from 'public-ip';
import { formatCurrency } from 'imports/checkout/api/utils';

const legalNoticeDomain = ['lebenslaufschreiben', 'modeles-cv', 'atelierdecv', 'cvdeboss'];

const Footer = ({ isNewMobileDesign = false }) => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const links = footerLinks(host, locale);
  const hideOneYear = [
    'cv-lite',
    'xn--mxabdxcg5aeo5c',
    'skriva-cv',
    'cv-pohja',
    'cv-pavyzdys',
    'cv-voorbeeld',
    'creare-cv',
    'cvvzor',
    'cv-skabelon',
    'lebenslaufschreiben',
    'mysmartcv',
    'ejemplos-curriculum',
    'cvkoostamine',
  ];

  const fetchData = async (IP) => {
    const countryCode = await getLocation(IP || '127.0.0.1');
    const respCurrency = getCurrency(host, countryCode);
    return respCurrency;
  };

  const [slug, setSlug] = useState([]);
  const timeZone = moment.tz.guess(true);
  const [cont] = timeZone.split('/');
  const [showCookiesSetting, setShowCookiesSetting] = useState(false);
  const handleRedirection = (path) => () => Push(path, locale);

  useEffect(() => {
    let isMounted = true;
    //sometimes axeptioSDK doesn't load so we have to wait for it
    setTimeout(() => {
      if (window?.axeptioSDK) {
        setShowCookiesSetting(true);
      }
    }, 3000);

    const loadData = async () => {
      let IP;
      let currency = getCurrency(host);
      try {
        if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
          IP = await publicIp.v4();
          const data = await fetchData(IP);
          if (isMounted) {
            currency = data || currency;
          }
        }
      } catch (error) {
        console.error('Error fetching IP:', error);
        IP = '127.0.0.1';
      }

      let startPlan, monthlyPlan, yearlyPlan;

      if (cont === 'Europe' && currency === 'EUR') {
        const eurMapping = {
          1.45: '€1,45',
          '1,45': '€1,45',
          1.95: '€1,95',
          '1,95': '€1,95',
          9.99: '€10,45',
          '9,99': '€10,45',
          29.99: '€29,99',
          '29,99': '€29,99',
          89.99: '€125.40',
          '89,99': '€125.40',
        };
        startPlan = replaceString(t('resume_nation_landing_footer_p2').replace(/\$/g, ''), eurMapping);
        monthlyPlan = replaceString(t('resume_nation_landing_footer_p4').replace(/\$/g, ''), eurMapping);
        yearlyPlan = replaceString(t('resume_nation_landing_footer_p6').replace(/\$/g, ''), eurMapping);
      } else if (currency === 'CHF') {
        const cadMapping = {
          1.45: formatCurrency(0.99, currency, 'fr'),
          '1,45': formatCurrency(0.99, currency, 'fr'),
          1.95: formatCurrency(1.99, currency, 'fr'),
          '1,95': formatCurrency(1.99, currency, 'fr'),
          9.99: formatCurrency(6.99, currency, 'fr'),
          '9,99': formatCurrency(6.99, currency, 'fr'),
          29.99: formatCurrency(39.99, currency, 'fr'),
          '29,99': formatCurrency(39.99, currency, 'fr'),
          89.99: formatCurrency(80.0, currency, 'fr'),
          '89,99': formatCurrency(80.0, currency, 'fr'),
        };
        startPlan = replaceString(t('resume_nation_landing_footer_p2').replace(/\$/g, ''), cadMapping);
        monthlyPlan = replaceString(t('resume_nation_landing_footer_p4').replace(/\$/g, ''), cadMapping);
        yearlyPlan = replaceString(t('resume_nation_landing_footer_p6').replace(/\$/g, ''), cadMapping);
      } else if (currency === 'CAD') {
        const cadMapping = {
          1.45: formatCurrency(1.45, currency, 'fr'),
          '1,45': formatCurrency(1.45, currency, 'fr'),
          1.95: formatCurrency(1.95, currency, 'fr'),
          '1,95': formatCurrency(1.95, currency, 'fr'),
          9.99: formatCurrency(14.95, currency, 'fr'),
          '9,99': formatCurrency(14.95, currency, 'fr'),
          29.99: formatCurrency(44.95, currency, 'fr'),
          '29,99': formatCurrency(44.95, currency, 'fr'),
          89.99: formatCurrency(149.95, currency, 'fr'),
          '89,99': formatCurrency(149.95, currency, 'fr'),
        };
        startPlan = replaceString(t('resume_nation_landing_footer_p2').replace(/\$/g, ''), cadMapping);
        monthlyPlan = replaceString(t('resume_nation_landing_footer_p4').replace(/\$/g, ''), cadMapping);
        yearlyPlan = replaceString(t('resume_nation_landing_footer_p6').replace(/\$/g, ''), cadMapping);
      } else {
        startPlan = t('resume_nation_landing_footer_p2');
        monthlyPlan = t('resume_nation_landing_footer_p4');
        yearlyPlan = t('resume_nation_landing_footer_p6');
      }

      if (isMounted) {
        setSlug([startPlan, monthlyPlan, yearlyPlan]);
      } else {
        setSlug([
          t('resume_nation_landing_footer_p2'),
          t('resume_nation_landing_footer_p4'),
          t('resume_nation_landing_footer_p6'),
        ]);
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [locale]);

  const showLegalNotice = useMemo(() => {
    if (legalNoticeDomain.includes(host)) {
      switch (host) {
        case 'lebenslaufschreiben' && locale === 'de':
          return true;
        default:
          return true;
      }
    }
  }, []);

  return (
    <Main $direction="column" $alignItens="center" $justifyContent="center">
      <Wrapper $justifyContent="center" $alignItems="center">
        <StyledContainer $isNewMobileDesign={isNewMobileDesign}>
          <Title $isNewMobileDesign={isNewMobileDesign}>{t('resume_nation_landing_footer_p1')}</Title>
          <StyledFlex $hideOneYear={hideOneYear.includes(host)} $isNewMobileDesign={isNewMobileDesign}>
            <Options>
              <Plans $direction="column">
                <PlanTitle>{t('resume_nation_landing_footer_p7')}</PlanTitle>
                <PlanDescription $isNewMobileDesign={isNewMobileDesign}>{slug[0]}</PlanDescription>
              </Plans>
            </Options>
            <Options>
              <Plans $direction="column">
                <PlanTitle>{t('resume_nation_landing_footer_p3')}</PlanTitle>
                <PlanDescription $isNewMobileDesign={isNewMobileDesign}>{slug[1]}</PlanDescription>
              </Plans>
            </Options>
            {!hideOneYear.includes(host) && (
              <Options>
                <Plans $direction="column">
                  <PlanTitle>{t('resume_nation_landing_footer_p5')}</PlanTitle>
                  <PlanDescription $isNewMobileDesign={isNewMobileDesign}>{slug[2]}</PlanDescription>
                </Plans>
              </Options>
            )}
          </StyledFlex>
          <AccpetedPayment $alignItems="center" $isNewMobileDesign={isNewMobileDesign}>
            <Text $isNewMobileDesign={isNewMobileDesign}>{t('footer_payment_options')}</Text>
            <CardWrapper $isNewMobileDesign={isNewMobileDesign}>
              {ACCPTED_CARDS_LOGO.map((k, i) => (
                <PaymentLogo $image={k} key={i} />
              ))}
            </CardWrapper>
          </AccpetedPayment>
        </StyledContainer>
      </Wrapper>
      <FottorWrapper $justifyContent="center" $alignItems="center" $direction="column">
        <LinkFlex $isNewMobileDesign={isNewMobileDesign}>
          {links.map((item, index) => (
            <Flex key={index} $direction="row">
              <LinkWrapper key={index} $direction="row" onClick={handleRedirection(item.href)}>
                {item.text ? item.text : t(item.slug)}
              </LinkWrapper>
            </Flex>
          ))}

          {showCookiesSetting && (
            <Flex $direction="row">
              <LinkWrapper $direction="row" onClick={handleRedirection('javascript:openAxeptioCookies()')}>
                {t('footer.cookies_settings')}
              </LinkWrapper>
            </Flex>
          )}

          <Flex $direction="row">
            <LinkWrapper $direction="row" onClick={handleRedirection('/login')}>
              {t('landing.login')}
            </LinkWrapper>
          </Flex>
          {showLegalNotice && (
            <Flex $direction="row">
              <LinkWrapper $direction="row" onClick={handleRedirection('/legal-notice')}>
                {t('footer.legal_notice')}
              </LinkWrapper>
            </Flex>
          )}
        </LinkFlex>
        {FEF_HOST.includes(host) && <CompanyName $newDesign>{getCompanyNameByHost(host)}</CompanyName>}
        <Address $newDesign $isNewMobileDesign={isNewMobileDesign} />
      </FottorWrapper>
    </Main>
  );
};

export default Footer;

const PaymentLogo = styled.div`
  height: 28px;
  width: 52px;
  ${({ $image }) =>
    $image &&
    css`
      background-image: url(${staticFile(`img/${$image}`)});
    `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CardWrapper = styled(Flex)`
  gap: 10px;
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      gap: 16px;
    `}
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  color: var(--light-values-white);
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    `}
`;

const AccpetedPayment = styled(Flex)`
  gap: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      gap: 16px;
      padding-bottom: 32px;
    `}
`;

const CompanyName = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 12px;
  color: #8c8cb7;
  width: 100%;
  padding: 0 35px;
  ${({ $isNew }) =>
    $isNew &&
    css`
      padding: 0;
      margin: 12px 0 50px;
    `}
  ${({ $newDesign }) =>
    $newDesign &&
    css`
      display: flex;
      justify-content: center;
      color: var(--neutral-values-gray-light);
      text-align: center;
    `}
`;
const LinkWrapper = styled(Flex)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 11px;
  font-weight: 600;
  line-height: 1.45;
  color: var(--light-values-white);
  text-decoration: none;
  ${({ theme }) => theme.max('md')`
      gap: 12px;
  `}
`;

const LinkFlex = styled(Flex)`
  > * + * {
    margin-left: 24px;
    margin-bottom: 8px;
  }
  a {
    text-decoration: none;
  }
  ${({ theme }) => theme.max('md')`
    flex-wrap: wrap;
    > * + * {
      margin-left: 8px;
    }
    justify-content: center;
  `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      > * + * {
        margin-left: 24px !important;
      }
    `}
`;

const FottorWrapper = styled(Flex)`
  background-color: var(--dark-values-black);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 40px 80px;
  ${({ theme }) => theme.max('md')`
    padding: 20px;
    align-items: center;
    justify-content: center;
  `}
`;
const Wrapper = styled(Flex)`
  background-color: var(--dark-values-black);
  width: 100%;
`;
const StyledFlex = styled(Flex)`
  gap: 40px;
  padding: 40px 0 20px 0;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
  ${({ theme }) => theme.max('md')`
    flex-direction: column;
    text-align: center;
    width: 100%;
  `}
  ${({ $hideOneYear }) =>
    $hideOneYear &&
    css`
      justify-content: center;
      text-align: center;
    `}
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 24px;
    `}
`;
const PlanDescription = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  color: var(--neutral-values-gray-light);
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #c4c4c7;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    `}
`;

const PlanTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  color: var(--light-values-white);
  width: 100%;
`;
const Plans = styled(Flex)`
  gap: 16px;
`;

const Options = styled(Flex)`
  gap: 40px;
  width: 33.33%;
  ${({ theme }) => theme.max('md')`
    width:100%;
  `}
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  text-align: center;
  color: var(--light-values-white);
  padding-top: 80px;
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      color: #fff;
      text-align: center;
      font-family: Gilroy SemiBold;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      padding-top: 32px;
    `}
`;

const StyledContainer = styled(Container)`
  align-items: center;
  width: 100%;
  ${({ $isNewMobileDesign }) =>
    $isNewMobileDesign &&
    css`
      padding: 0 16px !important;
    `}
`;

const Main = styled(Flex)`
  background-color: #3a3a43;
  margin-top: 80px;
  gap: 1px;
  width: 100%;
  ${({ theme }) => theme.max('md')`
    margin: 0;
  `}
`;
