import React, { Fragment } from 'react';

import Confirm from '/imports/core/ui/atoms/Confirm';

export const withConfirm = (Component) =>
  class Wrapper extends React.Component {
    state = {
      confirm: null,
      isConfirming: false,
      isCanceling: false,
    };

    static async getInitialProps(ctx) {
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      return { ...pageProps };
    }

    confirm = (confirm) => this.setState({ confirm });

    onClose = () => {
      this.setState({ confirm: null, isConfirming: false, isCanceling: false });
    };

    onCancel = () => {
      const { onCancel, enableLoaders } = this.state.confirm;
      if (onCancel) {
        if (enableLoaders) {
          this.setState({
            isCanceling: true,
          });
        }
        return onCancel();
      }

      this.onClose();
    };

    onConfirm = () => {
      const { onConfirm, enableLoaders } = this.state.confirm;
      if (onConfirm) {
        if (enableLoaders) {
          this.setState({
            isConfirming: true,
          });
        }
        onConfirm();
      }
    };

    render() {
      const { confirm, ...rest } = this.state;

      return (
        <Fragment>
          <Component {...this.props} confirm={this.confirm} onClose={this.onClose} />
          {confirm && (
            <Confirm
              {...confirm}
              {...rest}
              isConfirming={this.state.isConfirming}
              onConfirm={this.onConfirm}
              onCancel={this.onCancel}
              onClose={this.onClose}
            />
          )}
        </Fragment>
      );
    }
  };
