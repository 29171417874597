import Flex from 'imports/core/ui/atoms/Flex';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { LoaderInfinite } from 'imports/generator/ui/atoms/LoaderInfinite';
import { useRouter } from 'next/router';
import { withBlock } from '/imports/generator/context/blockresposition.context';
import useFetchAISuggestion from '/imports/generator/ui/atoms/useFetchAISuggestion.js';
import useIntl from '/imports/core/api/useIntl';
import SummaryPlusIcon from 'imports/generator/ui/assets/SummaryPlusIcon';

const SummerySuggestion = (props) => {
  const { block, source } = props;
  const { t } = useIntl();
  const {
    query: { step },
  } = useRouter();
  const item = block?.items && block?.items[0];
  const type = props?.block?.type;

  const {
    state: { isSummaryData, isSummaryLoading },
  } = withBlock();

  useFetchAISuggestion(source, item, type, step);

  const formatSuggestion = (text) => {
    const bulletCharacters = '•‣⁃⁍⁕*⁘⁜⦾⦿◦-';
    return text.replace(new RegExp(`^\\d*\\s*[${bulletCharacters}]`), '');
  };

  return (
    <Container fullWidth direction="column">
      <SummeryTitle>{t('generator_summary_inspiration_suggestion')}</SummeryTitle>
      <Databox fullWidth isSummaryLoading={isSummaryLoading}>
        {!isSummaryLoading ? (
          <Fragment>
            {isSummaryData?.map((suggestion, i) => {
              const formattedSuggestion = formatSuggestion(suggestion);
              return (
                <StyledFlex fullWidth alignItems="center" key={i} id="summary-suggestion-trigger">
                  <IconWrap alignItems="center" justifyContent="center">
                    <SummaryPlusIcon />
                  </IconWrap>
                  <Flex alignItems="flex-start" fullWidth>
                    <Description id="suggestion-text-wrapper">{formattedSuggestion}</Description>
                  </Flex>
                </StyledFlex>
              );
            })}
          </Fragment>
        ) : (
          <LoadingWraper direction="column" $fullwidth alignItems="center" justifyContent="center" fullWidth>
            <StyledLoader />
            <LoadingTxt>{t('generator_summary_newdesign_loading_text')}</LoadingTxt>
          </LoadingWraper>
        )}
      </Databox>
    </Container>
  );
};

export default SummerySuggestion;

const StyledFlex = styled(Flex)`
  gap: 16px;
`;

const StyledLoader = styled(LoaderInfinite)`
  margin-top: 20px;
`;

const LoadingWraper = styled(Flex)`
  gap: 20px;
`;

const LoadingTxt = styled.div`
  color: #14141f;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 16px;
  line-height: 32px;
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  color: #14141f;
  font-size: 14px;
  line-height: 22px;
`;

const SummeryTitle = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 16px;
  line-height: 22px;
`;

const IconWrap = styled(Flex)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 100px;
  background: #edf4ff;
`;

const Databox = styled(Flex)`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  &:hover {
    background: #edf4ff;
    cursor: pointer;
    ${IconWrap} {
      background: #389aff;
      > svg > path {
        fill: #fff;
      }
    }
  }
  ${({ isSummaryLoading }) =>
    isSummaryLoading &&
    css`
      &:hover {
        cursor: unset;
        background: #fff;
      }
    `}
`;

const Container = styled(Flex)`
  gap: 4px;
  padding-bottom: 32px;
`;
