import { useState, useEffect } from 'react';

import sortBy from 'lodash/sortBy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';

import { REORDER_BLOCK_ITEM_WITH_DRAG } from 'imports/generator/api/apollo/client/mutations';
import BlockItem from '/imports/generator/ui/components/BlockItem';

const DragDrop = (props) => {
  const {
    block = {},
    source,
    isCoverLetter,
    isFormValid,
    getForm,
    startExpanded,
    updateImmue,
    referenceResume,
    refernceBlock,
    language,
    afterRemove,
  } = props;

  const [isDragDisable, setIsDragDisable] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [reorder] = useMutation(REORDER_BLOCK_ITEM_WITH_DRAG);
  useEffect(() => {
    const el = document.getElementById('scroll-cont');
    if (el) {
      el.addEventListener('scroll', handleScroll);
      return () => {
        el.removeEventListener('scroll', handleScroll);
        setIsScrolled(false);
      };
    }
  }, [isScrolled]);

  const handleScroll = () => {
    setIsScrolled(true);
    isScrolled && setShowTooltip(null);
  };

  const handleOnDragEnd = async (result) => {
    const sourceId = source.id;
    const blockId = block.id;
    if (!result.destination) return;
    if (window) window.localStorage.setItem('disable-tags-repositionning', '1');
    setIsDragDisable(true);
    setShowTooltip(null);
    const { destination, draggableId } = result;

    const direction = destination.index < result.source.index ? 'UP' : 'DOWN';
    const { items } = source.blocks.find((block) => block.id === blockId);
    const currentItem = items.find((item) => item.id === draggableId);
    const nextItem = items.find((item) => item.order === destination.index);
    currentItem.order = destination.index;
    if (nextItem) nextItem.order = direction === 'UP' ? destination.index + 1 : destination.index - 1;

    const itemId = draggableId;
    const order = destination.index;
    updateImmue(source);
    const res = await reorder({
      variables: { resumeId: sourceId, blockId, itemId, order },
    });

    if (res && res.data) {
      updateImmue(res.data.reorderBlockItemsWithDrag);
      setIsDragDisable(false);
    }
  };

  const handleOnMouse = ($event, id) => {
    $event.stopPropagation();
    setShowTooltip(id);
  };

  const isSocialBlockCoverLetter = isCoverLetter && block && block?.type === 'SOCIAL_LINKS';
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="skills">
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sortBy(block.items, 'order').map((item, key) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={key}
                  {...provided.droppableProps}
                  isDragDisabled={isDragDisable || isSocialBlockCoverLetter}
                >
                  {(provided, snapshot) => (
                    <List
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      isDragging={snapshot.draggingOver}
                      style={{
                        ...provided.draggableProps.style,
                        background: snapshot.draggingOver ? '#FFF' : '',
                      }}
                      onMouseEnter={(e) => handleOnMouse(e, item.id)}
                      onMouseLeave={(e) => handleOnMouse(e, null)}
                      onTouchStart={(e) => handleOnMouse(e, item.id)}
                      onTouchEnd={(e) => handleOnMouse(e, null)}
                    >
                      <BlockItem
                        language={language}
                        key={item.animationKey}
                        index={key}
                        type={block.type}
                        block={block}
                        items={block.items}
                        docType={block.type}
                        source={source}
                        afterRemove={afterRemove}
                        isCoverLetter={isCoverLetter}
                        item={item}
                        refernceBlockItem={
                          refernceBlock && refernceBlock.items !== null ? refernceBlock.items[key] : null
                        }
                        isDragging={snapshot.draggingOver}
                        referenceResume={referenceResume}
                        getForm={getForm}
                        isFormValid={isFormValid}
                        startExpanded={startExpanded}
                        updateImmue={updateImmue}
                        showTooltip={showTooltip}
                      />
                    </List>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDrop;

const List = styled.div`
  width: 100%;
  position: relative;
  &:hover .tooltip {
    display: inline-block;
  }
`;
