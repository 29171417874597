import BlockRepositionModal from '../components/BlockRepositionModal';
import React, { PureComponent, Fragment } from 'react';
import animateScrollTo from 'animated-scroll-to';
import compose from 'lodash.flowright';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';
import { withBlockResposition } from 'imports/generator/context/blockresposition.context';
import { withRouter } from 'next/router';

import { Arrow } from '/imports/generator/ui/assets';
import Button from '/imports/core/ui/atoms/Button';
import { extractBlockInfo, handleBlockRepositioning, needPopup, replacedBlocks } from '/imports/generator/api/helpers';
import { getActiveRepositingVar, getPreviewBlocksHeight, getRandomEmoji, isJobTrackEnable } from '/lib/helpers';
import MobileItemContent from '/imports/generator/ui/components/MobileItemContent';
import { Push } from '/components/Link';
import { replaceString } from '/imports/core/ui/helpers';
import { ResponsiveConsumer, withResponsiveContext } from '/imports/core/api/responsiveContext';
import { RESUME_ONBOARDING_STEPS } from '/imports/generator/api/onboarding';
import {
  UPDATE_WIZARD_STEPS,
  UPDATE_RESUME_DETAIL,
  UPDATE_BLOCK_POSITION,
} from '/imports/generator/api/apollo/client/mutations';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import withGeneralContext from '/imports/core/api/generalContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import WizardHeaderSteps from '/imports/generator/ui/components/WizardHeaderSteps';
import WizardModal from '/imports/generator/ui/components/WizardModal';
import Flex from '/imports/core/ui/atoms/Flex';
import { isWithCareerLead } from '/imports/carrer/api/helper';
import { getCountry } from 'imports/checkout/api/utils';

const getMandatoryFieldName = (pathname) => {
  if (pathname.match('start')) return 'mandatory_field_heading';
  else if (pathname.match('experience')) return 'mandatory_field_xp';
  else if (pathname.match('education')) return 'mandatory_field_education';
  return '';
};

const getPopupEventName = (pathname, num) => {
  if (pathname.match('experience') && !num) return 'popup_no_xp';
  if (pathname.match('experience') && num === 1) return 'popup_one_xp';
  else if (pathname.match('experience')) return 'popup_one_xp';
  else if (pathname.match('education')) return 'popup_no_educ';
  else if (pathname.match('skills')) return 'popup_no_skills';
  else if (pathname.match('summary')) return 'popup_no_summary';
  return '';
};
@withBlockResposition
@withTracking
@withIntl
@withRouter
@withAccount
@withResponsiveContext
@compose(
  graphql(UPDATE_WIZARD_STEPS, { name: 'updateSteps' }),
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
  graphql(UPDATE_BLOCK_POSITION, { name: 'updateBlockPosition' }),
)
@withGeneralContext
class WizardLayout extends PureComponent {
  static propTypes = {
    isFormValid: PropTypes.func,
    updateSteps: PropTypes.func,
    updateResumeDetail: PropTypes.func,
    router: PropTypes.object,
    resume: PropTypes.object,
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    hasError: PropTypes.bool,
    view: PropTypes.string,
    padded: PropTypes.bool,
  };

  state = {
    modalOpen: false,
    num: 0,
    hasError: this.props.hasError,
    loading: false,
  };

  toggleModal = (num) => {
    this.setState({ modalOpen: !this.state.modalOpen, num });
  };

  checkSteps = () => {
    const {
      updateResumeDetail,
      router: {
        query: { resumeId, step },
      },
      resume: { steps, currentStep },
    } = this.props;
    if (
      marvelEmitter.getActiveVariant('exp_add_block_step') !== 'with_additional_add_block_step' &&
      (steps?.includes('other') || currentStep === 'other')
    ) {
      updateResumeDetail({
        variables: {
          docId: resumeId,
          path: 'steps',
          value: steps.filter((st) => st !== 'other'),
        },
      });
      updateResumeDetail({
        variables: {
          docId: resumeId,
          path: 'currentStep',
          value: step,
        },
      });
    }
  };

  checkStep = () => {
    const {
      router: {
        query: { step, resumeId },
      },
      updateSteps,
      resume: { currentStep, steps, blocks },
      context: { state },
    } = this.props;
    let obj = {
      docId: resumeId,
      value: step,
    };
    const customBlocks =
      blocks && blocks.length > 0 ? blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b) : [];
    const customSkillsBlocks =
      blocks && blocks.length > 0
        ? blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const isMobile = ['xs', 'sm'].includes(this.props.breakpoint);
    const activeRepositionVariant = getActiveRepositingVar();
    if (
      (activeRepositionVariant === 'with_complete_repositioning' ||
        activeRepositionVariant === 'with_basic_repositioning') &&
      !isMobile
    ) {
      obj = {
        ...obj,
        variant: 'with_complete_repositioning',
        heights: getPreviewBlocksHeight(customBlocks, customSkillsBlocks),
      };

      if (state.toggle.deActive) {
        delete obj.heights;
        delete obj.variant;
      }
    }
    if (!steps.some((st) => st === step) || step !== currentStep) {
      updateSteps({
        variables: obj,
      });
    }
  };
  componentDidMount() {
    this.performAction();
    this.checkSteps();
    this.checkStep();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser?.email !== prevProps.currentUser?.email) {
      this.performAction();
    }
    const { router, hasError } = this.props;
    const { router: newRouter, hasError: prevHasError } = prevProps;
    if (prevHasError !== hasError) {
      this.setState({ hasError });
    }

    if (router.query.step !== newRouter.query.step) {
      window.scrollTo(0, 0);
      this.checkStep();
    }
  }

  performAction = () => {
    const { currentUser, trackEvent } = this.props;
    const lastActionDate = localStorage.getItem('DATE_LAST_ACTION');
    if (!lastActionDate) {
      localStorage.setItem('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
      if (typeof window !== 'undefined' && window.sendinblue?.identify && currentUser?.email) {
        window.sendinblue.identify(currentUser?.email, { DATE_LAST_ACTION: moment().format('DD-MM-YYYY') });
      }
      trackEvent('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
    } else {
      if (moment().diff(lastActionDate, 'days') >= 1) {
        if (typeof window !== 'undefined' && window.sendinblue?.identify && currentUser?.email) {
          window.sendinblue.identify(currentUser?.email, { DATE_LAST_ACTION: lastActionDate });
        }
        trackEvent('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
      } else {
        localStorage.setItem('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
      }
    }
  };

  back = () => {
    const {
      router: {
        asPath,
        query: { step, resumeId },
      },
      locale,
      currentUser,
    } = this.props;

    const currentStepIndex = RESUME_ONBOARDING_STEPS.findIndex((i) => i.status === step);

    localStorage.removeItem('resumedone:from-finalize');

    if (currentStepIndex) {
      const queryString = {
        resumeId,
        step: RESUME_ONBOARDING_STEPS[currentStepIndex - 1].status,
        from: asPath,
        userId: currentUser?.id,
      };
      Push(
        `/resume?${qs.stringify(queryString)}`,
        locale,
        `/resume/${resumeId}/${RESUME_ONBOARDING_STEPS[currentStepIndex - 1].status}?userId=${currentUser?.id}`,
      );
    }
  };

  next = async () => {
    const {
      router: {
        asPath,
        query: { step, resumeId, intro },
      },
      onSubmit,
      locale,
      currentUser,
    } = this.props;

    const { modalOpen } = this.state;
    if (modalOpen) {
      this.toggleModal();
    }

    const currentStepIndex = RESUME_ONBOARDING_STEPS.findIndex((i) => i.status === step);

    const nextStepIndex = RESUME_ONBOARDING_STEPS[currentStepIndex + 1];
    const nextStep = nextStepIndex ? nextStepIndex.status : 'preview';
    if (onSubmit) {
      const isSubmitSuccessful = await onSubmit();
      if (isSubmitSuccessful === false) return;
    } else if (this.state.hasError) return; // we could have destructed `hasError` in #135 but,
    // do not destructure: if destructured doesn't update its value on
    // on component update ( we have the old value when destructing before await statement)
    // the value of `hasError` is updated when `onSubmit` is resolved

    localStorage.removeItem('resumedone:from-finalize');

    let urlAdd = nextStepIndex && nextStepIndex.intro && !intro ? 'intro' : '';

    if (intro && intro === 'intro') {
      const queryString = {
        resumeId,
        step,
        from: asPath,
        language: locale,
        userId: currentUser?.id,
      };
      Push(`/resume?${qs.stringify(queryString)}`, locale, `/resume/${resumeId}/${step}?userId=${currentUser?.id}`, {
        shallow: true,
      });
    } else {
      const queryString = {
        resumeId,
        step: nextStep,
        intro: urlAdd,
        from: asPath,
        language: locale,
        userId: currentUser?.id,
      };
      const stepPath = nextStepIndex.intro ? step : nextStep;
      Push(
        `/resume?${qs.stringify(queryString)}`,
        locale,
        `/resume/${resumeId}/${stepPath}/${urlAdd}?userId=${currentUser?.id}`,
        {
          shallow: true,
        },
      );
    }
  };

  popupNext = async () => {
    const {
      resume,
      router: { asPath, query },
      isFormValid,
      trackEvent,
      currentUser,
      handleClose,
      popshown,
      show,
      locale,
    } = this.props;
    const newFlowStep = ['experience'];
    const withoutFirstStep = ['skills', 'summary'];
    const country = getCountry();
    const activeVar = marvelEmitter.getActiveVariant('exp_career_status_v2');
    const withNewFlow =
      activeVar === 'without_first_step_flow' ||
      isWithCareerLead(locale, country) ||
      ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
      ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
    const arrayRoutes = withNewFlow ? withoutFirstStep : newFlowStep;

    if (show) {
      if (withNewFlow && arrayRoutes.includes(query.step)) return handleClose();
    }
    if (isFormValid === false) {
      const input = document.querySelector('[data-error]')?.parentNode?.firstChild?.firstChild;
      animateScrollTo(input, { offset: -200 });
      trackEvent(getMandatoryFieldName(asPath));
      return input?.focus();
    }
    const experienceBlock = resume.blocks.find((block) => block.type === 'EMPLOYMENT');
    const items = experienceBlock.items;
    const employment = items && items[0]?.fields?.title;
    if (typeof window !== 'undefined' && window.sendinblue?.identify && employment && currentUser?.email) {
      window.sendinblue.identify(currentUser?.email, { jobTitle: employment });
    }
    if (typeof window !== 'undefined' && window.sendinblue?.identify && currentUser?.email && currentUser?.address) {
      let address = currentUser?.address;
      if (currentUser?.city) address = address + ' , ' + currentUser?.city;
      window.sendinblue.identify(currentUser?.email, { address: address });
    }
    if (needPopup(resume, query)) {
      const { step } = query;
      let num = 0;

      if (step === 'experience') {
        const { blocks } = resume;
        const block = blocks.find((b) => b.type === 'EMPLOYMENT');
        num = block && block.items && block.items.length;
      }
      trackEvent(getPopupEventName(asPath, num));
      this.toggleModal(num);
    } else {
      this.next();
    }
  };

  goTo = (nextStep) => {
    const {
      router: {
        asPath,
        query: { step, resumeId },
      },
      resume: { steps },
      locale,
      currentUser,
    } = this.props;

    if (!steps.some((st) => st === nextStep)) return false;

    if (nextStep !== step) {
      const queryString = {
        resumeId,
        step: nextStep,
        from: asPath,
        userId: currentUser?.id,
      };
      Push(
        `/resume?${qs.stringify(queryString)}`,
        locale,
        `/resume/${resumeId}/${nextStep}?userId=${currentUser?.id}`,
        { shallow: true },
      );
    }
  };

  getCurrentIndex = (step) => {
    const {
      resume: { blocks, details },
    } = this.props;
    const currentIndex = RESUME_ONBOARDING_STEPS.findIndex((i) => i.status === step);
    let resultIndex = 0;
    if (details.professionalSummary !== null) {
      resultIndex = 4;
    } else if (blocks.find((block) => block.type === 'SKILLS').items) {
      resultIndex = 3;
    } else if (blocks.find((block) => block.type === 'EDUCATION').items) {
      resultIndex = 2;
    } else if (blocks.find((block) => block.type === 'EMPLOYMENT').items) {
      resultIndex = 1;
    }
    return resultIndex > currentIndex ? resultIndex : currentIndex;
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.popupNext();
    }
  };

  handleClose = ({ cancel = false, stateName }) => {
    const {
      context: {
        dispatch,
        state: { prevtoggleState, toggle },
      },
      resume,
      updateBlockPosition,
      updateImmue,
    } = this.props;
    dispatch({ type: 'UPDATE_STATE', name: 'isOpen', payload: false });
    dispatch({ type: 'UPDATE_STATE', name: 'isClosed', payload: true });

    /* if cancel */
    if (cancel === true && stateName === 'deActive') return true;
    /* if save and deactive == true*/
    if (cancel == false && toggle?.deActive == true) return true;

    const customBlocks =
      resume && resume.blocks && resume.blocks.length > 0
        ? resume.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
        : [];
    const customSkillsBlocks =
      resume && resume.blocks && resume.blocks.length > 0
        ? resume.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
    const updatedBlocks = handleBlockRepositioning(resume.blocks, heights, null, true, true);
    const newResume = replacedBlocks(resume, updatedBlocks.blocks);
    const blocks = extractBlockInfo(updatedBlocks.blocks);
    updateImmue(newResume);
    updateBlockPosition({
      variables: {
        resumeId: newResume.id,
        blocks: blocks,
      },
    });
  };

  render() {
    const {
      resume,
      resume: { currentStep, steps },
      router: {
        query: { step, intro },
      },
      view,
      finalize,
      t,
      padded,
      context: { state },
      popshown,
      show,
      handleClose,
      tab,
      handleBack,
      isLastTab,
      locale,
      currentUser,
      router: { query },
    } = this.props;
    const { modalOpen, variant, loading } = this.state;
    const country = getCountry();
    const currentStepIndex = RESUME_ONBOARDING_STEPS.findIndex((i) => i.status === step);
    const currentProgressIndex = RESUME_ONBOARDING_STEPS.findIndex((i) => i.status === steps[steps.length - 1]);
    const activeVar = marvelEmitter.getActiveVariant('exp_career_status_v2');
    const withNewFlow =
      activeVar === 'without_first_step_flow' ||
      isWithCareerLead(locale, country) ||
      ['without_first_step_flow'].includes(marvelEmitter.getActiveVariant('exp_career_status_v3')) ||
      ['with_career_lead'].includes(marvelEmitter.getActiveVariant('exp_lead_generation_v2'));
    const flag = localStorage && localStorage.getItem('flag');
    const dataFlag = flag && JSON.parse(flag);
    const withoutFirstStep = ['skills', 'summary'];
    const newFlowStep = ['experience'];
    const arrayRoutes = withNewFlow ? withoutFirstStep : newFlowStep;
    if (currentStepIndex == null) return null;
    let nextName = RESUME_ONBOARDING_STEPS[currentStepIndex + 1]
      ? t(RESUME_ONBOARDING_STEPS[currentStepIndex + 1].translationSlug)
      : t('preview');

    if (intro && intro === 'intro') {
      nextName = t(RESUME_ONBOARDING_STEPS[currentStepIndex].translationSlug);
    }

    if (
      withNewFlow &&
      !dataFlag?.sixStep &&
      arrayRoutes.includes(query.step) &&
      !popshown &&
      !currentUser?.disableCarrerQuiz
    ) {
      nextName = RESUME_ONBOARDING_STEPS[currentStepIndex]
        ? t(RESUME_ONBOARDING_STEPS[currentStepIndex].translationSlug)
        : t('preview');
    }
    const nextToCTA = replaceString(t('generator.final.next_to_cta'), { CTA_NAME: nextName });
    return (
      <ResponsiveConsumer>
        {({ isMobile }) => (
          <Fragment>
            <WizardHeaderSteps
              isMobile={isMobile}
              currentStepIndex={currentStepIndex}
              currentProgressIndex={currentProgressIndex}
              view={view}
              onBack={this.back}
              goTo={this.goTo}
              currentStep={currentStep}
              finalize={finalize}
              handleBack={handleBack}
              popshown={popshown}
              show={show}
              isLastTab={isLastTab}
            />
            <WizardCont
              onKeyPress={(e) => step === 'start' && this.handleKeyPress(e)}
              tabIndex="0"
              ref={(c) => {
                this.enterRef = c;
              }}
            >
              {this.props.children}

              {withNewFlow && !popshown && show && step === 'finish' && !isLastTab && (
                <Footer alignItems="center" justifyContent="space-between">
                  {!isMobile && (
                    <Back onClick={tab === 0 ? this.back : handleBack} hide={!currentStepIndex}>
                      <Arrow.Left />
                      {t('generator.wizard.back')}
                    </Back>
                  )}
                  <NextWrapper padded={padded} disabled={loading}>
                    <Next onClick={handleClose} view={view} disabled={loading}>
                      {replaceString(t('generator.final.next_to_cta'), { CTA_NAME: t('generator.wizard.finish') })}
                      <Arrow.Right />
                    </Next>
                  </NextWrapper>
                </Footer>
              )}
              {step !== 'finish' && (
                <Footer justifyContent="space-between" alignItems="center">
                  {!isMobile && (
                    <Back onClick={this.back} hide={!currentStepIndex}>
                      <Arrow.Left />
                      {t('generator.wizard.back')}
                    </Back>
                  )}
                  <NextWrapper padded={padded} disabled={loading}>
                    <Next onClick={this.popupNext} view={view} disabled={loading}>
                      {nextToCTA}
                      <Arrow.Right />
                    </Next>
                  </NextWrapper>
                </Footer>
              )}
            </WizardCont>
            {modalOpen && (
              <WizardModal onClose={this.toggleModal} onAction={this.next} step={step} num={this.state.num || 0} />
            )}
            {isMobile && <MobileItemContent resume={resume} />}
            {state.isOpen && <BlockRepositionModal isOpen={state.isOpen} onClose={this.handleClose} />}
          </Fragment>
        )}
      </ResponsiveConsumer>
    );
  }
}

const WizardCont = styled.div`
  position: relative;
  max-width: 860px;
  margin: auto;
  padding: 20px 35px 50px;
  ${({ theme }) => theme.min('lg')`
    padding: 20px 50px 50px;
  `}
  ${(p) => p.theme.max('sm')`
    padding: 20px;
  `}
    ${({ theme }) =>
    theme.max('xs')`
      padding: 11px;
      margin: 0 0 50px;
    `};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Footer = styled(Flex)`
  width: 100%;
  ${(p) => p.theme.max('xs')`
    justify-content: flex-end;
  `}
  ${({ theme }) =>
    theme.max('xs')`
    padding-top: 10px;
    justify-content: center;
  `}
`;

export const Back = styled((p) => <Button link {...p} />)`
  color: ${(p) => p.theme.colors.black};
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  color: #7171a6;
  display: flex;
  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      pointer-events: none;
      width: 0;
      padding: 0;
    `}

  &:hover {
    color: #1688fe;
    svg > path {
      fill: '#1688fe';
    }
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      span {
        transform: scale(-1, 1);
        display: flex;
        margin: auto 0;
      }
    `}
`;

const NextContainer = styled.div`
  min-width: 400px;
  max-width: 60%;
  padding-left: 18px;
  padding-right: 18px;
  margin: 0 auto;
`;

const NextWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    theme.max('md')`
    align-items: flex-start;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  ${({ theme }) =>
    theme.max('xs')`
      position: fixed;
      bottom: 0;
      z-index: 999;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding:${(props) => (props.padded ? '0 14px' : '0')};
    `}
`;

const nextCSS = css`
  padding: 16px 50px 14px 1.2em;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-weight: normal;
  position: relative;
  background-color: #1688fe;
  border: none;
  &:hover {
    background-color: #0b78e8;
  }

  &:focus,
  &:active {
    background-color: #1275db;
  }
`;

export const Next = styled((p) => <Button cta {...p} />)`
  ${nextCSS};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
      padding: 16px 1.2em 14px 50px;
      display: flex;
      justify-content: space-between;
      span {
        transform: scale(-1, 1);
        left: 16px;
        right: auto;
      }
    `}

  ${({ theme: { max } }) =>
    max('xs')`
      width: 100%;
      text-align: left;
      width: calc(100% - 32px);
      margin: 0 auto 16px;
      box-shadow: 0 20px 40px -8px rgba(15, 15, 15, 0.1), 0 14px 16px -10px rgba(15, 15, 15, 0.15);
  `}
  ${(p) =>
    p.disabled &&
    css`
      background: #c4c4c7 !important;
      color: #ececed;
      &:hover {
        background-color: #c4c4c7 !important;
      }

      &:focus,
      &:active {
        background-color: #c4c4c7 !important;
      }
    `}
`;

const LinkedinText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  line-height: 15px;
  color: #33334f;
  margin: 15px 0;
  ${({ theme: { max } }) =>
    max('xs')`
    margin: 10px auto 30px;
  `}
  span {
    color: #1688fe;
    cursor: pointer;
  }
`;

export default withBlockResposition(WizardLayout);
